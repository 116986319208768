
import React,{useEffect} from 'react';
import { Image,Form } from 'react-bootstrap';

import Dragula from 'react-dragula';
import "react-dragula/dist/dragula.css";

import Lang from '@adminv2/system/language'
import APIs from '@adminv2/apis'
import * as Components from '@adminv2/components/index'

function MultiNewsPicker(props) {
  const [loading, setLoading] = React.useState(false);
  const [full_news, setFullNews] = React.useState([]);

  const getNews = async() => {
    setLoading(true)
    let response = await APIs.news.getList({
      filter: {
        ids: props.value
      }
    })
    if (response.data!=null && Array.isArray(response.data.results)) {
      let cates = []
      for (var i = 0; i < response.data.results.length; i++) {
        if (Array.isArray(props.value) && props.value.includes(response.data.results[i].id)) {
          cates.push(response.data.results[i])
        }
      }
      setFullNews(cates)
    }
    setLoading(false)
  }

  const dragulaDecorator = (componentBackingInstance) => {
    if (componentBackingInstance) {
      Dragula([componentBackingInstance], {});
    }
  };

  useEffect(() => {
    getNews()
  },[]);

  return (
    <>
      <Components.NewsPicker
        onChange={news => {
          let newss = props.value || []
          let fc = full_news
          if (!newss.includes(news.id)) {
            newss.push(news.id)
            fc.push(news)
          }
          props.onChange(newss)
          setFullNews(fc)
        }}
      />
      <div className="well bg-light border mb-3" ref={dragulaDecorator}>
        {(Array.isArray(full_news) ? full_news : []).map((news, key) => {
          return (
            <div className="well-item">
              <span className="handle ps-2 pe-2"><i className="fa fa-arrows-alt"></i></span>
              <a onClick={() => {
                  let newss = props.value || []
                  let fc = full_news
                  if (newss.includes(news.id)) {
                    newss.splice(newss.indexOf(news.id), 1)
                    fc.splice(fc.indexOf(news), 1)
                  }
                  props.onChange(newss)
                  setFullNews(fc)
                }}><i className="fa fa-minus-circle"></i></a>
              {news.title}
            </div>
          )
        })}
      </div>
    </>
  );
};

export default MultiNewsPicker;
