import React, {useState} from 'react';
import { useNavigate,Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Breadcrumb, Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, ListGroup, InputGroup } from 'react-bootstrap';

// import NOTIFICATIONS_DATA from "../data/notifications";

import {routes, globalState,APIs, Components,Lang} from '@adminv2/App'

function Header() {
  const { pathname } = window.location;
  const current_route = routes.getCurrentRoute()
  // const parent_route = routes.getParentRoutePath(pathname)
  const parent_routes = routes.getParentRoutePaths(pathname)
  // --------------------------------------
  const navigate = useNavigate();
  // console.log(routes.getCurrentRoute())
  const NOTIFICATIONS_DATA = [];
  // --------------------------------------
  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);

  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setNotifications(notifications.map(n => ({ ...n, read: true })));
    }, 300);
  };

  const Notification = (props) => {
    const { link, sender, image, time, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="col-auto">
            <Image src={image} className="user-avatar lg-avatar rounded-circle" />
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-2">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
              {
                pathname != routes.dashboard.path &&
                <>
                  <Breadcrumb.Item linkAs={Link} linkProps={{ to: routes.dashboard.path }}><i className="fa fa-home"></i></Breadcrumb.Item>
                  <>
                    {/* {
                      parent_route.path != null && parent_route.path != routes.home.path &&
                      <Breadcrumb.Item linkAs={Link} linkProps={{ to: parent_route.path }}>{Lang.t(parent_route.name)}</Breadcrumb.Item>
                    } */}
                    {parent_routes?.map(item => {
                      console.log(item.name,parent_routes?.length)
                      return(
                        <Breadcrumb.Item linkAs={Link} linkProps={{ to: item.path }}>{Lang.t(item.name)}</Breadcrumb.Item>
                      )
                    })}
                    {
                      current_route.path != null &&
                      <Breadcrumb.Item active>{Lang.t(current_route.name)}</Breadcrumb.Item>
                    }
                  </>
                </>
              }
            </Breadcrumb>
          </div>
          <div className="d-none d-sm-block">
            <Nav className="align-items-center">
              

              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                  <div className="media d-flex align-items-center">
                    <Image src={APIs.info('image') || '/image/anonymous-icon.jpg'} className="user-avatar md-avatar rounded-circle" />

                    <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                      <span className="mb-0 font-small fw-bold">{ APIs.info('name') }</span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  <Dropdown.Item className="fw-bold" onClick={() => navigate(routes.profile.path)}>
                    <FontAwesomeIcon icon={faUserCircle} className="me-2"/> {Lang.t('My Profile')}
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold" onClick={() => navigate(routes.setting.path)}>
                    <FontAwesomeIcon icon={faCog} className="me-2" /> {Lang.t('Setting')}
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold" onClick={() => navigate(routes.messages.path)}>
                    <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> {Lang.t('Messages')}
                  </Dropdown.Item>

                  <Dropdown.Divider />

                  <Dropdown.Item className="fw-bold" onClick={() => navigate(routes.logout.path)}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> {Lang.t('Logout')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </div>
        </div>
      </Container>
    </Navbar>
  );
}

export default Header;
