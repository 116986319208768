import globalState from '@adminv2/system/globalState'
import GLOBAL from "@adminv2/app.json";
import axios from "axios";
const Todos = {
  renderCategoryStructureSelectOptions : (category, level = 0) => {
    return(
      <>
        <option value={category.id}>
          {Array(level).fill(level).map((key) => {
            return '\u00A0\u00A0\u00A0'
          })}
          {category.name}
        </option>
        {(Array.isArray(category.children) ? category.children : []).map((children, key) => {
          return Todos.renderCategoryStructureSelectOptions(children, level + 1)
        })}
      </>
    )
  },
  searchStatisticNews : (key = '') => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/news/search_statistic?filter[name]=' + key
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/news/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getKhoaHocNewsList : (data) => {
    data.filter = data.filter || {}
    data.filter.type = 'khoa-hoc'
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/news/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getTapTucNhangDenNewsList : (data) => {
    data.filter = data.filter || {}
    data.filter.type = 'tap-tuc-nhang-den'
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/news/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/news/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getKhoaHocNewsForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/news/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{
        filter: {
          type: 'khoa-hoc'
        }
      }).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getTapTucNhangDenNewsForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/news/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{
        filter: {
          type: 'tap-tuc-nhang-den'
        }
      }).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  create : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/news/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  edit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/news/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  delete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/news/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  deletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/news/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  categoryStructure : (data={}) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/news-category/structure'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getCategoryList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/news-category/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getKhoaHocCategoryList : (data) => {
    data.filter = data.filter || {}
    data.filter.type = 'khoa-hoc'
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/news-category/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getTapTucNhangDenCategoryList : (data) => {
    data.filter = data.filter || {}
    data.filter.type = 'tap-tuc-nhang-den'
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/news-category/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getKhoaHocCategoryForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/news-category/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{
        filter: {
          type: 'khoa-hoc'
        }
      }).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getTapTucNhangDenCategoryForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/news-category/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{
        filter: {
          type: 'tap-tuc-nhang-den'
        }
      }).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getCategoryForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/news-category/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  categoryCreate : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/news-category/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  categoryEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/news-category/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  categoryDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/news-category/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  categoryDeletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/news-category/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
}
export default Todos
