import React from 'react';
import {
  Row,Col,Dropdown,Form,Button
} from 'react-bootstrap'
import {Lang,routes,APIs,Notify} from '@adminv2/App'

import '@adminv2/scss/components/dashboard/others/ServerInfo.scss'

function ServerInfo( props ) {
  const info = props.info
  const [serverInfo, setServerInfo] = React.useState({});
  const [loading, setLoading] = React.useState('');

  const getServerInfo = async() => {
    let response = await APIs.getSystem('GetServerInfo')
    // console.log(response)
    if (response.data!=null && response.data.cpu!=null) {
      setServerInfo(response.data)
    }
  }

  const requestService = async(service) => {
    setLoading(service)
    let response = await APIs.getSystem(service)
    setLoading(false)
  }

  React.useEffect(() => {
    getServerInfo()
  },[]);

  const diskTag = serverInfo.disk < 80 ? (serverInfo.disk > 40 ? 'warning' : 'success') : 'danger'
  const memoryTag = serverInfo.memory < 80 ? (serverInfo.memory > 40 ? 'warning' : 'success') : 'danger'
  const cpuTag = serverInfo.cpu < 80 ? (serverInfo.cpu > 40 ? 'warning' : 'success') : 'danger'

  return (
      <div className="component_dashboard--serverInfo p-2 h-100 position-relative">
        <h2 className="text-center text-white fw-bold m-0">{Lang.t('Server Info')}</h2>
        <Dropdown autoClose="outside" align="end" className="position-absolute top-2 right-2">
          <Dropdown.Toggle variant="success" size="sm">
            <i className="fa fa-cogs"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-0 rounded-0" style={{width: 300}}>
            <Dropdown.Item className="d-flex justify-content-between">
              <div style={{whiteSpace: 'pre-wrap'}}>
                <div className="fw-bold">Bật chế độ bảo trì</div>
                <div className="small">Người dùng không thể truy cập được vào hệ thống của bạn</div>
              </div>
              <Form.Check
                type="switch"
                id="custom-switch-maintenance"
                checked={serverInfo.maintenance_status}
                disabled={['TurnOnMaintenance','TurnOffMaintenance'].includes(loading)}
                onClick={async() => {
                  if (!serverInfo.maintenance_status) {
                    await requestService('TurnOnMaintenance')
                  }else{
                    await requestService('TurnOffMaintenance')
                  }
                  getServerInfo()
                  Notify.notify({type: 'success', title: 'Success', message: 'Change Maintenance status success'})
                }}
              />
            </Dropdown.Item>
            <Dropdown.Item className="d-flex justify-content-between">
              <div style={{whiteSpace: 'pre-wrap'}}>
                <div className="fw-bold">Bật CDNs</div>
                <div className="small">Sử dụng CDN cho các file ảnh, css, js.</div>
              </div>
              <Form.Check
                type="switch"
                id="custom-switch-cdns"
                checked={serverInfo.cdn_status}
                disabled={['TurnOnCDN','TurnOffCDN'].includes(loading)}
                onClick={async() => {
                  if (!serverInfo.cdn_status) {
                    await requestService('TurnOnCDN')
                  }else{
                    await requestService('TurnOffCDN')
                  }
                  getServerInfo()
                  Notify.notify({type: 'success', title: 'Success', message: 'Change CDN status success'})
                }}
              />
            </Dropdown.Item>
            <Dropdown.Item className="d-flex justify-content-between">
              <div style={{whiteSpace: 'pre-wrap'}}>
                <div className="fw-bold">Làm mới server</div>
                <div className="small">Xoá cache dữ liệu, view, hình ảnh...</div>
              </div>
              <Button variant="danger" size="sm" disabled={loading == 'ClearCache'} onClick={async() => {
                  await requestService('ClearCache')
                  Notify.notify({type: 'success', title: 'Success', message: 'Refresh success'})
                }}>
                  {
                    loading == 'ClearCache'
                    ?<span><span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                      Loading...</span>
                    :<span>{Lang.t('Clear')}</span>
                  }
                </Button>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <hr className="mt-2"/>
        <div className="d-flex">
          <div className={"progress progress-circle " + diskTag} aria-valuenow={serverInfo.disk || 0}>
      			<span className="progress-left">
      				<span className="progress-bar"></span>
      			</span>
      			<span className="progress-right">
      				<span className="progress-bar"></span>
      			</span>
      			<div className="progress-value">
      				<div><span>{serverInfo.disk || 0}</span>%<br/><span>DISK</span></div>
      			</div>
      		</div>

          <div className={"progress progress-circle success memory-usage " + memoryTag} aria-valuenow={serverInfo.memory || 0}>
      			<span className="progress-left">
      				<span className="progress-bar"></span>
      			</span>
      			<span className="progress-right">
      				<span className="progress-bar"></span>
      			</span>
      			<div className="progress-value">
      				<div><span>{serverInfo.memory || 0}</span>%<br/><span>Memory</span></div>
      			</div>
      		</div>

          <div className={"progress progress-circle cpu-usage " + cpuTag} aria-valuenow={serverInfo.cpu ? Math.round(serverInfo.cpu) : 0}>
      			<span className="progress-left">
      				<span className="progress-bar"></span>
      			</span>
      			<span className="progress-right">
      				<span className="progress-bar"></span>
      			</span>
      			<div className="progress-value">
      				<div><span>{serverInfo.cpu ? Math.round(serverInfo.cpu) : 0}</span>%<br/><span>CPU</span></div>
      			</div>
      		</div>
        </div>
      </div>
  );
}

export default ServerInfo;
