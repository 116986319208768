import React from 'react';

const FengshuiMaster = React.lazy(() => import('@adminv2/views/fengshuimaster/FengshuiMaster'));
const FengshuiMasterEdit = React.lazy(() => import('@adminv2/views/fengshuimaster/FengshuiMasterEdit'));
const FengshuiMasterAdd = React.lazy(() => import('@adminv2/views/fengshuimaster/FengshuiMasterAdd'));

const FengshuiMasterGroup = React.lazy(() =>
  import('@adminv2/views/fengshuimaster/fengshuimaster_group/FengshuiMasterGroup'),
);
const FengshuiMasterGroupEdit = React.lazy(() =>
  import('@adminv2/views/fengshuimaster/fengshuimaster_group/FengshuiMasterGroupEdit'),
);
const FengshuiMasterGroupAdd = React.lazy(() =>
  import('@adminv2/views/fengshuimaster/fengshuimaster_group/FengshuiMasterGroupAdd'),
);

const FengshuiMasterStatus = React.lazy(() =>
  import('@adminv2/views/fengshuimaster/fengshuimaster_status/FengshuiMasterStatus'),
);
const FengshuiMasterStatusEdit = React.lazy(() =>
  import('@adminv2/views/fengshuimaster/fengshuimaster_status/FengshuiMasterStatusEdit'),
);
const FengshuiMasterStatusAdd = React.lazy(() =>
  import('@adminv2/views/fengshuimaster/fengshuimaster_status/FengshuiMasterStatusAdd'),
);

const FengshuiMasterOfKhoaHoc = React.lazy(() => import('@adminv2/views/catalog/khoa-hoc/FengshuiMaster'));

const routes = {
  fengshuimaster: {
    path: '/fengshuimaster',
    name: 'Fengshui Master',
    exact: true,
    main: <FengshuiMaster />,
  },
  fengshuimaster_edit: {
    path: '/fengshuimaster/:id/edit',
    name: 'Fengshui Master Edit',
    exact: false,
    main: <FengshuiMasterEdit />,
  },
  fengshuimaster_add: {
    path: '/fengshuimaster/add',
    name: 'Fengshui Master Add',
    exact: true,
    main: <FengshuiMasterAdd />,
  },
  fengshuimaster_of_khoa_hoc: {
    path: '/fengshuimaster-of-khoa-hoc',
    name: 'Fengshui Master',
    exact: true,
    main: <FengshuiMasterOfKhoaHoc />,
  },
  fengshuimaster_group: {
    path: '/fengshuimaster-group',
    name: 'Fengshuimaster Group',
    exact: true,
    main: <FengshuiMasterGroup />,
  },
  fengshuimaster_group_edit: {
    path: '/fengshuimaster-group/:id/edit',
    name: 'Fengshuimaster Group Edit',
    exact: false,
    main: <FengshuiMasterGroupEdit />,
  },
  fengshuimaster_group_add: {
    path: '/fengshuimaster-group/add',
    name: 'Fengshuimaster Group Add',
    exact: true,
    main: <FengshuiMasterGroupAdd />,
  },

  fengshuimaster_status: {
    path: '/fengshuimaster-status',
    name: 'Fengshuimaster Status',
    exact: true,
    main: <FengshuiMasterStatus />,
  },
  fengshuimaster_status_edit: {
    path: '/fengshuimaster-status/:id/edit',
    name: 'Fengshuimaster Status Edit',
    exact: false,
    main: <FengshuiMasterStatusEdit />,
  },
  fengshuimaster_status_add: {
    path: '/fengshuimaster-status/add',
    name: 'Fengshuimaster Status Add',
    exact: true,
    main: <FengshuiMasterStatusAdd />,
  },
};

export default routes;
