import GLOBAL from "@adminv2/app.json";
import routes from "@adminv2/routes";
import globalState from "@adminv2/system/globalState";
import Language from "@adminv2/system/language";
import axios from "axios";

import auth_apis from "@adminv2/apis/auth";
import catalog_apis from "@adminv2/apis/catalog";
import coinpackage_apis from "@adminv2/apis/coinpackage";
import common_apis from "@adminv2/apis/common";
import customer_apis from "@adminv2/apis/customer";
import familytree_apis from "@adminv2/apis/familytree";
import configorder_apis from "@adminv2/apis/configorder";
import faqs_apis from "@adminv2/apis/faqs";
import fengshuimaster_apis from "@adminv2/apis/fengshuimaster";
import file_manager_apis from "@adminv2/apis/file_manager";
import floorplan_apis from "@adminv2/apis/floorplan";
import information_apis from "@adminv2/apis/information";
import layout_apis from "@adminv2/apis/layout";
import localisation_apis from "@adminv2/apis/localisation";
import marketing_apis from "@adminv2/apis/marketing";
import news_apis from "@adminv2/apis/news";
import sale_apis from "@adminv2/apis/sale";
import salechannel_apis from "@adminv2/apis/salechannel";
import setting_apis from "@adminv2/apis/setting";
import stock_apis from "@adminv2/apis/stock";
import translation_apis from "@adminv2/apis/translation";
import user_apis from "@adminv2/apis/user";

const APIs = {
  SERVER: GLOBAL.SERVER,
  FLOORPLAN_SERVER: GLOBAL.FLOORPLAN_SERVER,
  ...auth_apis,
  FileManager: file_manager_apis,
  catalog: catalog_apis,
  coinpackage: coinpackage_apis,
  ...common_apis,
  customer: customer_apis,
  faqs: faqs_apis,
  information: information_apis,
  layout: layout_apis,
  localisation: localisation_apis,
  marketing: marketing_apis,
  news: news_apis,
  sale: sale_apis,
  salechannel: salechannel_apis,
  setting: setting_apis,
  stock: stock_apis,
  translation: translation_apis,
  user: user_apis,
  fengshuimaster: fengshuimaster_apis,
  familytree: familytree_apis,
  configorder: configorder_apis,
  floorplan: floorplan_apis,
  getSystem: (action) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/system?action=" + action;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
};

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    let params = "lang=" + Language.getLanguage();
    config.url = config.url + (!config.url.includes("?") ? "?" : "&") + params;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Read error of axios if return error code from server
// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response != null) {
      const Navigation = require("@adminv2/system/navigation").default;
      switch (error.response.status) {
        case 401:
          APIs.logout();
          window.location.reload();
          break;
        case 403:
          Navigation.navigate(routes.Page403.path);
          break;
        case 405:
          Navigation.navigate(routes.Page405.path);
          break;
        case 500:
          break;
        default:
      }
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

export default APIs;
