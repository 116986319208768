import React from 'react';
import {Form,Image} from 'react-bootstrap'

import ReactLogo from "@adminv2/assets/img/loading.gif";

function LoadingIndicator() {
  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100">
      <Image className="loader-element animate__animated animate__jackInTheBox" src={ReactLogo} height={40} />
    </div>
  );
}

export default LoadingIndicator;
