import React from "react";
const KyMon = React.lazy(() =>
  import("@adminv2/views/orderconfig/ky-mon/KyMon")
);
const KyMonEdit = React.lazy(() =>
  import("@adminv2/views/orderconfig/ky-mon/KyMonEdit")
);
const KyMonAdd = React.lazy(() =>
  import("@adminv2/views/orderconfig/ky-mon/KyMonAdd")
);

const routes = {
  config_ky_mon: {
    path: "/order-config-ky-mon",
    name: "Kỳ môn",
    exact: true,
    main: <KyMon />,
  },
  config_ky_mon_edit: {
    path: "/order-config-ky-mon/:id/edit",
    name: "Kỳ môn Edit",
    exact: false,
    main: <KyMonEdit />,
  },
  config_ky_mon_add: {
    path: "/order-config-ky-mon/add",
    name: "Kỳ môn Add",
    exact: true,
    main: <KyMonAdd />,
  },
};

export default routes;
