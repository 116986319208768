import { APIs, Lang } from "@adminv2/App";
import { useState } from "react";
import {
  Form,
  Image,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import "react-dragula/dist/dragula.css";

import { useDropzone } from "react-dropzone";

function FileInputPicker(props) {
  const value = props.value;
  const onChange = props.onPick;
  const [loading, setLoading] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    acceptedFiles: {
      "image/*": [],
    },
    multiple: false,
    onDrop: async (acceptedFiles) => {
      setLoading(true);
      const res = await APIs.FileManager.uploadFile(acceptedFiles[0]);
      onChange({ url: res.data.url });
      setLoading(false);
    },
  });
  return (
    <div className="d-flex">
      <div {...props} className="file-picker position-relative">
        {props.type == "image" && (
          <div
            {...getRootProps({ className: "dropzone" })}
            type="button"
            className="position-relative bg-white p-1 border d-flex justify-content-center align-items-center overflow-hidden preview-container"
            style={{ width: props.width ?? 120, height: props.height ?? 120 }}
          >
            <input {...getInputProps()} disabled={loading} />
            <Image
              src={
                loading
                  ? "/images/file-placeholder.jpg"
                  : value || "/image/others/add-image.png"
              }
              style={{ width: "100%" }}
            />
            {loading && (
              <div className="position-absolute left-0 right-0 top-5">
                <span>
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </span>
              </div>
            )}
          </div>
        )}
        {props.type == "file" && (
          <InputGroup>
            <Form.Control
              value={value}
              onChange={(event) => {
                onChange({
                  url: event.target.value,
                });
              }}
            />
            <div
              {...getRootProps({ className: "dropzone" })}
              className="btn btn-success d-flex align-items-center"
              type="button"
            >
              <i className="fas fa-file-video"></i>
              <input {...getInputProps()} disabled={loading}/>
            </div>
          </InputGroup>
        )}
        {!!value && (
          <div className="position-absolute top-1 right-1">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip {...props}>{Lang.t("Clear")}</Tooltip>}
            >
              <span
                className="text-danger"
                onClick={() => {
                  onChange({
                    url: "",
                  });
                }}
              >
                <i className="fa fa-trash-alt"></i>
              </span>
            </OverlayTrigger>
          </div>
        )}
      </div>
      {props.enableNameInput == true && (
        <Form.Group className="">
          <Form.Control
            type="text"
            size="sm"
            placeholder="Tên ảnh"
            value={value || ""}
            onChange={(e) => {
              value.name = e.target.value;
              onChange(value);
            }}
          />
        </Form.Group>
      )}
    </div>
  );
}

export default FileInputPicker;
