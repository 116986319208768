import React from "react";

const CoinPackage = React.lazy(() =>
  import("@adminv2/views/coinpackage/CoinPackage")
);
const CoinPackageEdit = React.lazy(() =>
  import("@adminv2/views/coinpackage/CoinPackageEdit")
);
const CoinPackageAdd = React.lazy(() =>
  import("@adminv2/views/coinpackage/CoinPackageAdd")
);

const routes = {
  coin_package: {
    path: "/coin-package",
    name: "CoinPackage",
    exact: true,
    main: <CoinPackage />,
  },
  coin_package_edit: {
    path: "/coin-package/:id/edit",
    name: "CoinPackage Edit",
    exact: false,
    main: <CoinPackageEdit />,
  },
  coin_package_add: {
    path: "/coin-package/add",
    name: "CoinPackage Add",
    exact: true,
    main: <CoinPackageAdd />,
  },
};

export default routes;
