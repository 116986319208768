
import React,{useEffect} from 'react';
import { Image,Form,Dropdown } from 'react-bootstrap';
import { AsyncTypeahead,Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Lang from '@adminv2/system/language'
import APIs from '@adminv2/apis'

import * as Components from '@adminv2/components/index'

function NewsPicker(props) {
  const [loading, setLoading] = React.useState(false);
  const [newss, setNewss] = React.useState(props.news || []);
  const errors = props.errors || {}
  const value = props.value || ''

  const getResults = async(key = '') => {
    setLoading(true)
    let response = await APIs.news.searchStatisticNews(key)
    if (response.data!=null && Array.isArray(response.data.results)) {
      setNewss(response.data.results)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (newss.length == 0) {
      getResults()
    }
  },[]);

  return (
    <AsyncTypeahead
      filterBy={() => true}
      id="search-news-input-name"
      isLoading={loading}
      labelKey="title"
      minLength={0}
      onSearch={getResults}
      options={newss}
      placeholder="Search some News"
      renderMenuItemChildren={(option, props) => (
        <div className="d-flex">
          <img src={option.image !=null && option.image != '' ? option.image : '/image/box-icon.jpg'} width="40" className="me-2"/>
          <div>
            <div>{option.title}</div>
          </div>
        </div>
      )}
      onChange={options => {
        if (options[0]!=null) {
          props.onChange(options[0])
        }
      }}
    />
  );
};

export default NewsPicker;
