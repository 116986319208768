
import React,{useEffect} from 'react';
import { Image,Form } from 'react-bootstrap';

import Lang from '@adminv2/system/language'
import APIs from '@adminv2/apis'

function CategoryPicker(props) {
  const [loading, setLoading] = React.useState(false);
  const [categories, setCategories] = React.useState(props.categories || []);
  const inputName = props.name || '';
  const errors = props.errors || {}
  const value = props.value || ''

  const getCategories = async() => {
    setLoading(true)
    let response = await APIs.faqs.categoryStructure()
    if (Array.isArray(response.data)) {
      setCategories(response.data)
    }
    setLoading(false)
  }

  const findCategory = (id, cates=[]) => {
    for (var i = 0; i < cates.length; i++) {
      if (cates[i].id == id) {
        return cates[i];
        break;
      }
      if (Array.isArray(cates[i].children) && cates[i].children.length > 0) {
        let resp = findCategory(id, cates[i].children)
        if (resp!=null && resp.id!=null) {
          return resp
          break;
        }
      }
    }
    return {}
  }

  useEffect(() => {
    if (categories.length == 0) {
      getCategories()
    }
  },[]);

  return (
    <Form.Group className={props.className || ""}>
      <Form.Label>{Lang.t('Category')}</Form.Label>
        <Form.Select
            name={inputName}
            disabled={loading}
            value={value}
            className={ props.selectClassName + ' ' + (errors!=null && errors[inputName]!=null ? 'is-invalid' : '')}
            onChange={event => {
                const category = findCategory(event.target.value, (Array.isArray(categories) ? categories : []))
                // console.log(category)
                props.onChange(category)
          }}>
            <option value=""> -- {Lang.t('Select Category')} -- </option>
            {(Array.isArray(categories) ? categories : []).map((category, key) => {
              return APIs.news.renderCategoryStructureSelectOptions(category, 0)
            })}
        </Form.Select>
        {
          errors[inputName] &&
          <div className="invalid-feedback">
            {errors[inputName]!=null ? errors[inputName][0] : ''}
          </div>
        }
    </Form.Group>
  );
};

export default CategoryPicker;
