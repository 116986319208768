import React from 'react';

const Information = React.lazy(() => import('@adminv2/views/information/Information'))
const InformationEdit = React.lazy(() => import('@adminv2/views/information/InformationEdit'))
const InformationAdd = React.lazy(() => import('@adminv2/views/information/InformationAdd'))

const routes = {
  information : {
      path : '/info/information',
      name: "Information",
      exact : true,
      main : <Information />
  },
  information_edit : {
      path : '/info/information/:id/edit',
      name: "Information Edit",
      exact : false,
      main : <InformationEdit />
  },
  information_add : {
      path : '/info/information/add',
      name: "Information Add",
      exact : true,
      main : <InformationAdd />
  },
}


export default routes;
