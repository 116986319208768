import globalState from '@adminv2/system/globalState'
import GLOBAL from "@adminv2/app.json";
import axios from "axios";
const Todos = {
    getList: (data) => {
      return new Promise((resolve) => {
        let url = GLOBAL.SERVER + "/api/user/translation/list";
        axios.defaults.headers.common = {
          Authorization: `Bearer ${globalState.getBearerToken()}`,
        };
        axios
          .post(url, data)
          .then((response) => {
            resolve(response);
          })
          .catch(function (error) {
            resolve({});
          });
      });
    },
    add: (data) => {
      return new Promise((resolve) => {
        let url = GLOBAL.SERVER + "/api/user/translation/add";
        axios.defaults.headers.common = {
          Authorization: `Bearer ${globalState.getBearerToken()}`,
        };
        axios
          .post(url, data)
          .then((response) => {
            resolve(response);
          })
          .catch(function (error) {
            resolve({});
          });
      });
    },
    delete: (data) => {
      return new Promise((resolve) => {
        let url = GLOBAL.SERVER + "/api/user/translation/delete-translate";
        axios.defaults.headers.common = {
          Authorization: `Bearer ${globalState.getBearerToken()}`,
        };
        axios
          .post(url, data)
          .then((response) => {
            resolve(response);
          })
          .catch(function (error) {
            resolve({});
          });
      });
    },
}
export default Todos
