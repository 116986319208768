import React, {useEffect, useMemo} from 'react';
import {Form} from 'react-bootstrap';

import APIs from '@adminv2/apis';
import Lang from '@adminv2/system/language';

import {Select, Spin} from 'antd';
import {debounce} from 'lodash';
const {Option} = Select;

function CustomerPicker(props) {
  const [loading, setLoading] = React.useState(false);
  const [customers, setCustomers] = React.useState(props.customers || []);
  const inputName = props.name || '';
  const errors = props.errors || {};
  const value = props.value || '';

  const getCustomers = async (key = '') => {
    setLoading(true);
    let response = await APIs.customer.search(key);
    if (response.data != null && Array.isArray(response.data.results)) {
      setCustomers(response.data.results);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (customers.length == 0) {
      getCustomers();
    }
  }, []);

  const debounceFetcher = useMemo(() => {
    const loadOptions = value => {
      getCustomers(value);
    };
    return debounce(loadOptions, 500);
  }, []);
  let className = 'form-control ant-select-without-border ant-select-search'
  if(props.size == 'sm'){
    className += ' form-select-sm'
  }
  return (
    <Form.Group className={props.className || ''}>
      {props.hideLabel !== true ? <Form.Label>{Lang.t('Customer')}</Form.Label> : <></>}
      <Select
        className={className}
        style={{
          height: 'auto',
        }}
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={loading ? <Spin size="small" /> : null}
        value={value}
        allowClear
        showSearch
        optionLabelProp="label"
        onChange={value => {
          const findValue = customers.find(c => c.id === value);
          props.onChange(findValue || {});
        }}>
        {value && <Option className="d-none" value={value} label={props.displayValue}></Option>}
        {customers.map(customer => (
          <Option key={customer.id} value={customer.id} label={customer.firstname + ' ' + customer.lastname}>
            <div className="d-flex">
              <img
                src={customer.image != null && customer.image != '' ? customer.image : '/image/anonymous-icon.jpg'}
                width="40"
                height="40"
                className="me-2"
                alt=""
              />
              <div>
                <div className="fw-bold">{customer.firstname + ' ' + customer.lastname}</div>
                <div className="small">{customer.email}</div>
                <div className="small">{customer.telephone}</div>
              </div>
            </div>
          </Option>
        ))}
      </Select>
      {errors[inputName] && (
        <div className="invalid-feedback">{errors[inputName] != null ? errors[inputName][0] : ''}</div>
      )}
    </Form.Group>
  );
}

export default CustomerPicker;
