import React from 'react';
import {
  Row,Col
} from 'react-bootstrap'
import {Lang,routes,APIs} from '@adminv2/App'

import '@adminv2/scss/components/dashboard/others/RealtimeServerInfo.scss'

function RealtimeServerInfo( props ) {
  const info = props.info
  const [serverInfo, setServerInfo] = React.useState({});

  const getServerInfo = async() => {
    let response = await APIs.getSystem('GetRealtimeServerInfo')
    // console.log(response)
    if (response.data!=null && response.data.uptime!=null) {
      setServerInfo(response.data)
    }
  }

  React.useEffect(() => {
    getServerInfo()
  },[]);

  return (
      <div className="component_dashboard--realtimeServerInfo p-2 h-100">
        <h2 className="text-center text-white fw-bold m-0">{Lang.t('RealTime Server')}</h2>
        <hr className="mt-2"/>
        <Row className="g-0 text-center">
          <Col xs={6} lg={6} xl={2}>
            <div class="small mb-2">{Lang.t('Subscriptions')}</div>
            <div className="small">{serverInfo.subscription_count || 0}</div>
          </Col>
          <Col xs={6} lg={6} xl={2}>
            <div class="small mb-2">{Lang.t('Uptime')}</div>
            <div className="small">{ Math.round(((serverInfo.uptime || 0) / (60 * 60)) * 100) / 100 }h</div>
          </Col>
          <Col xs={12} lg={12} xl={8}>
            <div className="memory_usage d-flex flex-wrap justify-content-center">
              <div>
                <div className="mb-2 small">Rss</div>
                <div className="small">
                  { Math.round((( serverInfo.memory_usage!=null && serverInfo.memory_usage.rss!=null ? serverInfo.memory_usage.rss : 0) / (1024 * 1024)) * 100) /100 }MB
                </div>
              </div>
              <div>
                <div className="mb-2 small">Heap Total</div>
                <div className="small">
                  { Math.round((serverInfo.memory_usage!=null && serverInfo.memory_usage.heapTotal!=null ? serverInfo.memory_usage.heapTotal : 0)/(1024 * 1024) *100) /100 }MB
                </div>
              </div>
              <div>
                <div className="mb-2 small">Heap Used</div>
                <div className="small">
                  { Math.round((serverInfo.memory_usage!=null && serverInfo.memory_usage.heapUsed!=null ? serverInfo.memory_usage.heapUsed : 0)/(1024 * 1024) * 100) /100}MB
                </div>
              </div>
              <div>
                <div className="mb-2 small">External</div>
                <div className="small">
                  { Math.round((serverInfo.memory_usage!=null && serverInfo.memory_usage.external!=null ? serverInfo.memory_usage.external : 0)/(1024 * 1024) * 100) /100}MB
                </div>
              </div>
              <div>
                <div className="mb-2 small">Buffers</div>
                <div className="small">
                  { Math.round((serverInfo.memory_usage!=null && serverInfo.memory_usage.arrayBuffers!=null ? serverInfo.memory_usage.arrayBuffers : 0)/(1024 * 1024) * 100) /100}MB
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
  );
}

export default RealtimeServerInfo;
