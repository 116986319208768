import React from 'react';
const Customer = React.lazy(() => import('@adminv2/views/customer/customer/Customer'));
const CustomerEdit = React.lazy(() => import('@adminv2/views/customer/customer/CustomerEdit'));
const CustomerAdd = React.lazy(() => import('@adminv2/views/customer/customer/CustomerAdd'));

const CustomerGroup = React.lazy(() => import('@adminv2/views/customer/customer_group/CustomerGroup'));
const CustomerGroupEdit = React.lazy(() => import('@adminv2/views/customer/customer_group/CustomerGroupEdit'));
const CustomerGroupAdd = React.lazy(() => import('@adminv2/views/customer/customer_group/CustomerGroupAdd'));

const Anemometer = React.lazy(() => import('@adminv2/views/customer/anemometer/Anemometer'));
const AnemometerEdit = React.lazy(() => import('@adminv2/views/customer/anemometer/AnemometerEdit'));
const AnemometerAdd = React.lazy(() => import('@adminv2/views/customer/anemometer/AnemometerAdd'));

const CustomerSubscription = React.lazy(() =>
  import('@adminv2/views/customer/customer_subscription/CustomerSubscription'),
);
const CustomerSubscriptionEdit = React.lazy(() =>
  import('@adminv2/views/customer/customer_subscription/CustomerSubscriptionEdit'),
);
const CustomerSubscriptionAdd = React.lazy(() =>
  import('@adminv2/views/customer/customer_subscription/CustomerSubscriptionAdd'),
);

const CustomField = React.lazy(() => import('@adminv2/views/customer/custom_field/CustomField'));
const CustomFieldEdit = React.lazy(() => import('@adminv2/views/customer/custom_field/CustomFieldEdit'));
const CustomFieldAdd = React.lazy(() => import('@adminv2/views/customer/custom_field/CustomFieldAdd'));

const routes = {
  customer: {
    path: '/customer',
    name: 'Customer',
    exact: true,
    main: <Customer />,
  },
  customer_edit: {
    path: '/customer/:id/edit',
    name: 'Customer Edit',
    exact: false,
    main: <CustomerEdit />,
  },
  customer_add: {
    path: '/customer/add',
    name: 'Customer Add',
    exact: true,
    main: <CustomerAdd />,
  },
  customer_group: {
    path: '/customergroup',
    name: 'Customer Group',
    exact: true,
    main: <CustomerGroup />,
  },
  customer_group_edit: {
    path: '/customergroup/:id/edit',
    name: 'Customer Group Edit',
    exact: false,
    main: <CustomerGroupEdit />,
  },
  customer_group_add: {
    path: '/customergroup/add',
    name: 'Customer Group Add',
    exact: true,
    main: <CustomerGroupAdd />,
  },

  anemometer: {
    path: '/anemometer',
    name: 'Anemometer',
    exact: true,
    main: <Anemometer />,
  },
  anemometer_edit: {
    path: '/anemometer/:id/edit',
    name: 'Anemometer Edit',
    exact: false,
    main: <AnemometerEdit />,
  },
  anemometer_add: {
    path: '/anemometer/add',
    name: 'Anemometer Add',
    exact: true,
    main: <AnemometerAdd />,
  },

  custom_field: {
    path: '/custom-field',
    name: 'Custom Field',
    exact: true,
    main: <CustomField />,
  },
  custom_field_edit: {
    path: '/custom-field/:id/edit',
    name: 'Custom Field Edit',
    exact: false,
    main: <CustomFieldEdit />,
  },
  custom_field_add: {
    path: '/custom-field/add',
    name: 'Custom Field Add',
    exact: true,
    main: <CustomFieldAdd />,
  },
  customer_subscription: {
    path: '/customer-subscription',
    name: 'Customer',
    exact: true,
    main: <CustomerSubscription />,
  },
  customer_subscription_edit: {
    path: '/customer-subscription/:id/edit',
    name: 'Customer Subscription Info',
    exact: false,
    main: <CustomerSubscriptionEdit />,
  },
  customer_subscription_add: {
    path: '/customer-subscription/add',
    name: 'Customer Subscription Add',
    exact: true,
    main: <CustomerSubscriptionAdd />,
  },
};

export default routes;
