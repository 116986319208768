import React from 'react';

const FAQs = React.lazy(() => import('@adminv2/views/faqs/FAQs'))
const FAQsEdit = React.lazy(() => import('@adminv2/views/faqs/FAQsEdit'))
const FAQsAdd = React.lazy(() => import('@adminv2/views/faqs/FAQsAdd'))
const Category = React.lazy(() => import('@adminv2/views/faqs/Category'))
const CategoryEdit = React.lazy(() => import('@adminv2/views/faqs/CategoryEdit'))
const CategoryAdd = React.lazy(() => import('@adminv2/views/faqs/CategoryAdd'))

const routes = {
  FAQs : {
      path : '/faqs',
      name: "FAQs",
      exact : true,
      main : <FAQs />
  },
  FAQs_edit : {
      path : '/faqs/:id/edit',
      name: "FAQs Edit",
      exact : false,
      main : <FAQsEdit />
  },
  FAQs_add : {
      path : '/faqs/add',
      name: "FAQs Add",
      exact : true,
      main : <FAQsAdd />
  },
  FAQ_category : {
      path : '/fc',
      name: "FAQCategory",
      exact : true,
      main : <Category />
  },
  FAQ_category_edit : {
      path : '/fc/:id/edit',
      name: "FAQs Category Edit",
      exact : false,
      main : <CategoryEdit />
  },
  FAQ_category_add : {
      path : '/fc/add',
      name: "FAQs Category Add",
      exact : true,
      main : <CategoryAdd />
  },
}


export default routes;
