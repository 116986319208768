import React, { useEffect } from "react";
import { Image, Form, Dropdown } from "react-bootstrap";

import Lang from "@adminv2/system/language";
import APIs from "@adminv2/apis";

import * as Components from "@adminv2/components/index";

function FengshuiMasterPicker(props) {
  const [search, setSearch] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [customers, setCustomers] = React.useState(props.customers || []);
  const inputName = props.name || "";
  const errors = props.errors || {};
  const value = props.value || "";
  let inputSearch = React.useRef("");

  const getCustomers = async (key = "") => {
    setLoading(true);
    let response = await APIs.fengshuimaster.search(key);
    if (response.data != null && Array.isArray(response.data.results)) {
      setCustomers(response.data.results);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (customers.length == 0) {
      getCustomers();
    }
    setSearch(value.name);
  }, []);

  const CustomToggle = ({ children, onClick }) => (
    <Form.Group className="">
      <Form.Control
        ref={inputSearch}
        value={search || ""}
        onChange={(event) => {
          inputSearch.current.focus();
          setSearch(event.target.value);
          getCustomers(event.target.value);
        }}
        onClick={onClick}
      />
      <span className="position-absolute right-2 top-2">
        <i className="fa fa-angle-down"></i>
      </span>
      {value && (
        <a
          className="position-absolute left-1 top-0 text-danger fw-bold"
          onClick={() => {
            setSearch("");
            props.onChange("");
          }}
        >
          <i className="fa fa-times"></i>
        </a>
      )}
    </Form.Group>
  );

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>

      <Dropdown.Menu className="p-0 rounded-0">
        {loading && <Components.LoadingIndicator />}
        {(!loading && Array.isArray(customers) ? customers : []).map(
          (customer, key) => (
            <Dropdown.Item
              onClick={() => {
                setSearch(customer.name);
                props.onChange(customer);
                inputSearch.current.blur();
              }}
            >
              <div className="d-flex">
                <img
                  src={
                    customer.image != null && customer.image != ""
                      ? customer.image
                      : "/image/anonymous-icon.jpg"
                  }
                  width="40"
                  className="me-2"
                />
                <div>
                  <div className="fw-bold">{customer.name}</div>
                  <div className="small">{customer.email}</div>
                  <div className="small">{customer.telephone}</div>
                </div>
              </div>
            </Dropdown.Item>
          )
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default FengshuiMasterPicker;
