import GLOBAL from "@adminv2/app.json";
import routes from "@adminv2/routes";
import globalState from "@adminv2/system/globalState";
import Navigation from "@adminv2/system/navigation";
import axios from "axios";
const Todos = {
  getOrderList: (data) => {
    return new Promise((resolve) => {
      delete data.filter.type;
      delete data.filter.types;
      let url = GLOBAL.SERVER + "/api/user/order/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getBatdongsanOrderList: (data) => {
    return new Promise((resolve) => {
      data.filter.type = "bat-dong-san";
      delete data.filter.types;
      let url = GLOBAL.SERVER + "/api/user/order/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getKhoaHocOrderList: (data) => {
    return new Promise((resolve) => {
      data.filter.type = "khoa-hoc";
      delete data.filter.types;
      let url = GLOBAL.SERVER + "/api/user/order/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getSubscriptionPlanOrderList: (data) => {
    return new Promise((resolve) => {
      data.filter.type = "subscription-plan";
      delete data.filter.types;
      let url = GLOBAL.SERVER + "/api/user/order/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getCoinPackageOrderList: (data) => {
    return new Promise((resolve) => {
      data.filter.type = "coin-package";
      delete data.filter.types;
      let url = GLOBAL.SERVER + "/api/user/order/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getHoSoList: (data) => {
    return new Promise((resolve) => {
      // data.filter.types = [
      //   'khoa-hoc','xem-phong-thuy','bat-dong-san','xem-ngay',
      //   'tu-van-ky-mon','tu-van-bat-tu','tap-tuc-nhang-den'
      // ]
      let url = GLOBAL.SERVER + "/api/user/order/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getOrderForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/order/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  orderCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/order/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  orderEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/order/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  orderShipping: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/order/" + id + "/shipping";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  orderCancelShipping: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/order/" + id + "/cancel_shipping";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  orderVerify: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/order/" + id + "/verify";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  orderCancel: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/order/" + id + "/cancel";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  orderComplete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/order/" + id + "/complete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  orderDelete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/order/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  orderDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/order/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  orderSendInvoice: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/order/" + id + "/sendInvoice";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getOrderShippingForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/order/" + id + "/shipping/form";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  caculate: (data = {}) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/order/caculate";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  getShippingList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/shipping/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getShippingForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/shipping/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  shippingEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/shipping/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  orderProductEdit: (order_id, id, data) => {
    return new Promise((resolve) => {
      let url =
        GLOBAL.SERVER +
        "/api/user/order/" +
        order_id +
        "/products/" +
        id +
        "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  checkRouteForOrder: (cat, info) => {
    if (cat != info.type) {
      if (info.type) {
        if (info.type == "khoa-hoc") {
          Navigation.navigate(
            routes.khoa_hoc_order_edit.path.replace(":id", info.id)
          );
        } else if (info.type == "bat-dong-san") {
          Navigation.navigate(
            routes.batdongsan_order_edit.path.replace(":id", info.id)
          );
        } else {
          Navigation.navigate(
            routes.ho_so_cat_edit.path
              .replace(":cat", info.type)
              .replace(":id", info.id)
          );
        }
      } else {
        Navigation.navigate(routes.order_edit.path.replace(":id", info.id));
      }
    }
  },
};
export default Todos;
