import React, { useEffect } from "react";
import { Form } from "react-bootstrap";

import APIs from "@adminv2/apis";
import Lang from "@adminv2/system/language";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Select, Space } from "antd";
const { Option } = Select;

const Todos = APIs.localisation;

function StreetPicker(props) {
  const [loading, setLoading] = React.useState(false);
  const [streets, setStreets] = React.useState([]);
  const [inputStreet, setInputStreet] = React.useState("");

  const inputName = props.inputName || "";
  const countryId = props.countryId || "";
  const zoneId = props.zoneId || "";
  const subZoneId = props.subZoneId || "";
  const wardId = props.wardId || "";
  const errors = props.errors || {};
  const value = props.value || "";

  const getStreets = async (name) => {
    setLoading(true);
    const filter = {
      country_id: countryId,
      zone_id: zoneId,
      sub_zone_id: subZoneId,
      ward_id: wardId,
      name,
    };
    let response = await Todos.getStreets({ filter });
    if (Array.isArray(response)) {
      setStreets(response);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (countryId && zoneId && subZoneId && wardId) {
      getStreets();
    } else {
      setStreets([]);
    }
  }, [props.countryId, props.zoneId, props.subZoneId, props.wardId]);

  const addStreets = async (name) => {
    const data = {
      country_id: countryId,
      zone_id: zoneId,
      sub_zone_id: subZoneId,
      ward_id: wardId,
      name,
    };
    const response = await Todos.streetCreate(data);
    if (response.data) {
      getStreets();
    }
  };

  const addItem = (e) => {
    e.preventDefault();
    addStreets(inputStreet);
  };

  return (
    <Form.Group className={props.className || ""}>
      <Form.Label>{Lang.t("Street")}</Form.Label>
      <Select
        disabled={!wardId || loading}
        className={
          "form-control ant-select-without-border" +
          " " +
          props.selectClassName +
          " " +
          (errors != null && errors[inputName] != null ? "is-invalid" : "")
        }
        style={{
          height: "auto",
        }}
        value={value}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: "8px 0" }} />
            <Space style={{ padding: "0 8px 4px" }}>
              <Input
                placeholder="Nhập tên đường"
                value={inputStreet}
                onChange={(value) => {
                  setInputStreet(value.target.value);
                }}
                onKeyDown={(e) => e.stopPropagation()}
              />
              <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                Thêm mới
              </Button>
            </Space>
          </>
        )}
        onChange={(value) => {
          const findStreet = streets.find((s) => s.id == value);
          findStreet &&
            props.onChange({ street_id: value, street_name: findStreet.name });
        }}
      >
        <Option value={""} label={`-- ${Lang.t("Select SubZone")} --`}>
          {`-- ${Lang.t("Select Street")} --`}
        </Option>
        {streets.map((street) => (
          <Option key={street.id} value={street.id} label={street.name}>
            {street.name}
          </Option>
        ))}
      </Select>
      {errors[inputName] && (
        <div className="invalid-feedback">
          {errors[inputName] != null ? errors[inputName][0] : ""}
        </div>
      )}
    </Form.Group>
  );
}

export default StreetPicker;
