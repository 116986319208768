import axios from 'axios';
import globalState from './globalState';

function TelegramLog(text) {
  const dataConfig = globalState.getState() || {};
  const telegramBotKey = dataConfig?.log_channels?.Telegram?.TELEGRAM_BOT_KEY,
    telegramBotGroupId = dataConfig?.log_channels?.Telegram?.TELEGRAM_BOT_GROUP_ID;
  if (telegramBotKey && telegramBotGroupId) {
    return new Promise(resolve => {
      axios
        .post(`https://api.telegram.org/bot${telegramBotKey}/sendMessage`, {
          text,
          chat_id: telegramBotGroupId,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          resolve({error});
        });
    });
  }
  return;
}
export default TelegramLog;
