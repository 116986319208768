import { v4 as uuidv4 } from "uuid";
import * as THREE from "three";
const eps = 1e-9;
const N_PIXEL_NEIGHBOR = 8
const sign = (x) => {
  if (x > eps) return 1;
  if (x < -eps) return -1;
  return 0;
};
const cross = (AB, AC) => {
  return AB.x * AC.y - AC.x * AB.y;
};
const dot = (AB, AC) => {
  return AB.x * AC.x + AB.y * AC.y;
};
const Helpers = {
  wallWidth: 0.15,
  copyToClipboard: (content = "") => {
    if (typeof window !== "undefined") {
      const el = document.createElement("textarea");
      el.value = content;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    }
  },
  getMaxMinOfBoundaries: (points=[],center = [0,0],degree = 0) => {
    let minX= null;
    let maxX= null;
    let minY= null;
    let maxY= null;
    let boundaries = [];
    points.map(boundary => {
      boundaries.push(Helpers.doitructoado(boundary,center,degree))
    })
    boundaries.map(point => {
      if(minX == null || point[0] < minX){
        minX = point[0];
      }
      if(maxX == null || point[0] > maxX){
        maxX = point[0];
      }
      if(minY == null || point[1] < minY){
        minY = point[1];
      }
      if(maxY == null || point[1] > maxY){
        maxY = point[1];
      }
    })
    return [
      Helpers.doitructoado([minX,minY],[0,0],-degree), 
      Helpers.doitructoado([maxX,minY],[0,0],-degree), 
      Helpers.doitructoado([maxX, maxY],[0,0],-degree),
      Helpers.doitructoado([minX, maxY],[0,0],-degree)
    ];
  },
  doitructoado: (point=[],center,degree = 0) => {
    let x = Math.cos(degree*Math.PI/180) * (point[0]-center[0]) - Math.sin(degree*Math.PI/180) * (point[1]-center[1]) + center[0]
    let y = Math.sin(degree*Math.PI/180) * (point[0]-center[0]) + Math.cos(degree*Math.PI/180) * (point[1]-center[1]) + center[1]
    if(!degree){
      x = point[0]+center[0]
      y = point[1]+center[1]
    }
    
    // console.log(point,center,degree,[x,y])
    return [x,y]
  },
  getTrungCungBoundariesOfABoundaries: (points=[],data = []) => {
    // Đổi hệ trục toạ độ ngôi nhà.
    const degree = (data['room_direction'] || 0) - (data['direction'] || 0);
    const center = data['center'] || [0,0]
    let minMax = Helpers.getMaxMinOfBoundaries(points,[0,0],degree)
    
    const point1 = new THREE.Vector2(minMax[0][0],minMax[0][1]);
    const point2 = new THREE.Vector2(minMax[1][0],minMax[1][1]);
    const point3 = new THREE.Vector2(minMax[2][0],minMax[2][1]);
    const point4 = new THREE.Vector2(minMax[3][0],minMax[3][1]);
    const pp1 = point2.clone().sub(point1).divideScalar(3).add(point4.clone().sub(point1).divideScalar(3)).add(point1)
    const pp2 = point1.clone().sub(point2).divideScalar(3).add(point3.clone().sub(point2).divideScalar(3)).add(point2)
    const pp3 = point2.clone().sub(point3).divideScalar(3).add(point4.clone().sub(point3).divideScalar(3)).add(point3)
    const pp4 = point3.clone().sub(point4).divideScalar(3).add(point1.clone().sub(point4).divideScalar(3)).add(point4)
    let trungcung4diem = [
      [pp1.x,pp1.y],[pp2.x,pp2.y],[pp3.x,pp3.y],[pp4.x,pp4.y]
    ];
    const trungcung4diemcentroid = Helpers.getPolygonCentroid(trungcung4diem)
    // console.log(trungcung4diem,'aaa')
    trungcung4diem.map((item,key) => {
      trungcung4diem[key] = Helpers.doitructoado(item,[center[0] - trungcung4diemcentroid[0],center[1] - trungcung4diemcentroid[1]],0)
      // console.log(Helpers.doitructoado(item,[center[0] - trungcung4diemcentroid[0],center[1] - trungcung4diemcentroid[1]],0),key)
    })
    // console.log(trungcung4diem,'bbb')
    return trungcung4diem;
  },
  findOut1In4PartInside: (point, polygons, height) => {
    const newPoints = [
      [point[0] - height, point[1] - height],
      [point[0] - height, point[1] + height],
      [point[0] + height, point[1] - height],
      [point[0] + height, point[1] + height],
    ];

    for (var i = 0; i < newPoints.length; i++) {
      if (Helpers.checkPointIsInsidePolygons(newPoints[i], polygons)) {
        // console.log(i+':',point,newPoints[i])
        return newPoints[i];
      }
    }
    return point;
  },
  checkPointIsInsidePolygons: (point, vs) => {
    if(!Array.isArray(point) || point.length < 2 || vs?.length < 2) return false
    var x = point[0]
    var y = point[1];
    var inside = false;
    for (var i = 0, j = vs?.length - 1; i < vs?.length; j = i++) {
      var xi = vs[i][0],
        yi = vs[i][1];
      var xj = vs[j][0],
        yj = vs[j][1];

      var intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  },
  // getPolygonCentroid: (points) => {
  //   var centroid = [0, 0];
  //   for (var i = 0; i < points.length; i++) {
  //     var point = points[i];
  //     centroid[0] += point[0];
  //     centroid[1] += point[1];
  //   }
  //   centroid[0] /= points.length;
  //   centroid[1] /= points.length;
  //   // console.log(centroid)
  //   return centroid;
  // },
  getPolygonCentroid: (points) => {
    if(!Array.isArray(points) || points.length == 0) return [0, 0]
    var centroid = [0, 0];
    const area = Helpers.calcPolygonArea(points,false)
    for (var i = 0; i < points.length; i++) {
      const j = (i + 1) % points.length;
      const P = (points[i][0] * points[j][1]) - (points[i][1] * points[j][0]);
      centroid[0] = centroid[0] + (points[i][0] + points[j][0]) * P;
      centroid[1] = centroid[1] + (points[i][1] + points[j][1]) * P;
    }
    
    centroid[0] = centroid[0] / ( 6 * area);
    centroid[1] = centroid[1] / ( 6 * area);
    
    return centroid;
  },
  calcPolygonArea: (points,absStatus = true) => {
    if(!Array.isArray(points) || points.length == 0) return 0
    // var total = 0;

    // for (var i = 0, l = points.length; i < l; i++) {
    //   var addX = points[i][0];
    //   var addY = points[i == points.length - 1 ? 0 : i + 1][1];
    //   var subX = points[i == points.length - 1 ? 0 : i + 1][0];
    //   var subY = points[i][1];

    //   total += addX * addY * 0.5;
    //   total -= subX * subY * 0.5;
    // }
    let area = 0;

    for (let i = 0; i < points.length; i++) {
      const j = (i + 1) % points.length;
      const point = points[i];
      const point2 = points[j];

      area += point[0] * point2[1] - point2[0] * point[1]
    }
    // console.log(area)
    if (!absStatus) {
      return area / 2;
    }
    return Math.abs(area / 2);
  },
  getWallBoundaries: (boundaries,wallWidth = 0.15) => {
    let bdr = []
    boundaries.map((boundary, i) => {
      const prevBoundary = boundaries[(i - 1 + boundaries.length) % boundaries.length]
      const nextBoundary = boundaries[(i + 1) % boundaries.length]
      const point = new THREE.Vector3(boundary[0],boundary[1],0)
      const prevPoint = new THREE.Vector3(prevBoundary[0],prevBoundary[1],0)
      const nextPoint = new THREE.Vector3(nextBoundary[0],nextBoundary[1],0)
      const vt1 = prevPoint.clone().sub(point).negate().setLength(wallWidth/2)
      const vt2 = nextPoint.clone().sub(point).negate().setLength(wallWidth/2)
      let vttt = vt1.clone().add(vt2).add(point)
      if(Helpers.checkPointIsInsidePolygons([vttt.x,vttt.y],boundaries)){
        vttt = vttt.clone().sub(point).negate().add(point)
      }
      bdr.push([Math.round(vttt.x * 100)/100,Math.round(vttt.y * 100)/100])
    })
    return bdr
  },
  // Tim hinh chieu cua diem a Len duong thang tao boi b va c.
  timHinhChieuCuaDiemLenDuongThangBoi2Diem: (a, b, c) => {
    const vtcp = [c[0] - b[0], c[1] - b[1]];
    const vtpt = [vtcp[1], -vtcp[0]];
    // Puhong trinh duong thang
    // vtpt[0] * x + vtpt[1] * y - vtpt[0] * b[0] - vtpt[1] * b[1] = 0
    // H(a1,b1) La hinh chieu
    // vtpt[0] * a1 + vtpt[1] * b1 - vtpt[0] * b[0] - vtpt[1] * b[1] = 0
    // a1 = (vtpt[0] * b[0] + vtpt[1] * b[1] - vtpt[1] * b1) / vtpt[0]
    // b1 = - (a1 - a[0]) * vtpt[0] / vtpt[1] + a[1]
    const b1 =
      ((vtpt[0] * b[0]) / vtpt[1] + b[1] - (a[0] * vtpt[0]) / vtpt[1] + a[1]) /
      2;

    // const b1 = - vtpt[0] * b[0] / vtpt[1] - vtpt[1] * b[1] / vtpt[1] + b1 * vtpt[1] / vtpt[1] + a[0] * vtpt[0] / vtpt[1] + a[1];
    const a1 = (vtpt[0] * b[0] + vtpt[1] * b[1] - vtpt[1] * b1) / vtpt[0];
    // console.log(b,c)
    if (!b1 || b1 == "-Infinity" || b1 == "Infinity") {
      return a;
    }
    if (!a1 || a1 == "-Infinity" || a1 == "Infinity") {
      return a;
    }

    return [a1, b1];
  },
  getPositionNearbyInBoundary: (boundaryCollection, [x, y]) => {
    let boundary = [];
    let po = [x, y, 0];
    let ro = [0, 0, 0];
    let oldDistance = 100000;
    const point = new THREE.Vector3(x, y, 0);
    for (let i = 0; i < boundaryCollection.length; i++) {
      let boundaries = boundaryCollection[i];
      for (let j = 0; j < boundaries.length; j++) {
        const nextIndex = boundaries[j + 1] ? j + 1 : 0;
        const nextBoundary = boundaries[nextIndex];
        
        
        const pointStart = new THREE.Vector3(
          boundaries[j][0],
          boundaries[j][1],
          0
        );
        const pointEnd = new THREE.Vector3(nextBoundary[0], nextBoundary[1], 0);
        // const distance = point.distanceTo(
        //   new THREE.Vector3(
        //     pointEnd.x - pointStart.x,
        //     pointEnd.y - pointStart.y,
        //     0
        //   )
        // );

        const line = new THREE.Vector3(
          pointEnd.x - pointStart.x,
          pointEnd.y - pointStart.y,
          0
        );
        const newVectorProject = new THREE.Vector3(
          point.x - pointStart.x,
          point.y - pointStart.y,
          0
        ).projectOnVector(line);
        
        const newPosition = newVectorProject.clone().add(
          new THREE.Vector3(pointStart.x, pointStart.y, 0)
        );
        const distance = newPosition.distanceTo(point)
        // console.log(newVectorProject)
        // console.log(point,pointStart)
        if(newVectorProject.x == 0 && newVectorProject.y == 0) return
        if (
          distance < oldDistance &&
          !(
            newPosition.distanceTo(pointStart) > line.length() ||
            newPosition.distanceTo(pointEnd) > line.length()
          )
        ) {
          // console.log(distance,point.x,point.y,pointEnd.x - pointStart.x,pointEnd.y - pointStart.y)
          oldDistance = distance;

          boundary = [
            [pointStart.x, pointStart.y],
            [pointEnd.x, pointEnd.y],
          ];
          po = [newPosition.x, newPosition.y, newPosition.z];
          // ro = [0,0,line.angleTo(new THREE.Vector3(1,0,0))]
          const angle = Helpers.caculateDegreeFromTwoPointFollowYAxis(
            pointEnd.x,
            pointEnd.y,
            po[0],
            po[1]
          );
          // console.log(pointEnd.x,pointEnd.y,po[0],po[1])
          ro = [0, 0, ((angle - 90) * Math.PI) / 180];
        }
      }
    }
    return {
      boundary: boundary,
      position: po,
      rotation: ro,
    };
  },
  caculateDegreeFromTwoPointFollowYAxis: (x, y, a, b) => {
    const R = Math.sqrt(Math.pow(a - x, 2) + Math.pow(b - y, 2));
    if (a - x <= 0 && b - y >= 0) {
      const sina = (x - a) / R;
      return (Math.asin(sina) * 180) / Math.PI;
    } else if (a - x <= 0 && b - y <= 0) {
      const sina = (x - a) / R;
      // console.log(180 - Math.asin(sina) * 180 / Math.PI)
      return 180 - (Math.asin(sina) * 180) / Math.PI;
    } else if (a - x >= 0 && b - y <= 0) {
      const sina = (a - x) / R;
      return 180 + (Math.asin(sina) * 180) / Math.PI;
    } else if (a - x >= 0 && b - y >= 0) {
      const sina = (b - y) / R;
      return 270 + (Math.asin(sina) * 180) / Math.PI;
    } else {
      return 0;
    }
  },
  getBoundaiesParent: (boundaries = [0, 0, 0, 0], distance = 0.5) => {
    let maxX, maxY, minX, minY;
    let arrX = [];
    let arrY = [];

    boundaries.map((item) => {
      arrX.push(item[0]);
      arrY.push(item[1]);
    });
    minX = Math.min(...arrX) - distance;
    maxX = Math.max(...arrX) + distance;
    minY = Math.min(...arrY) - distance;
    maxY = Math.max(...arrY) + distance;
    return { minX, maxX, minY, maxY };
  },
  kiemTraGiaoNhau: (A, B, C, D) => {
    const ABxAC = sign(
      cross(
        {
          x: B.x - A.x,
          y: B.y - A.y,
        },
        { x: C.x - A.x, y: C.y - A.y }
      )
    );
    const ABxAD = sign(
      cross(
        {
          x: B.x - A.x,
          y: B.y - A.y,
        },
        { x: D.x - A.x, y: D.y - A.y }
      )
    );
    const CDxCA = sign(
      cross(
        {
          x: D.x - C.x,
          y: D.y - C.y,
        },
        { x: A.x - C.x, y: A.y - C.y }
      )
    );
    const CDxCB = sign(
      cross(
        {
          x: D.x - C.x,
          y: D.y - C.y,
        },
        { x: B.x - C.x, y: B.y - C.y }
      )
    );
    if (ABxAC == 0 || ABxAD == 0 || CDxCA == 0 || CDxCB == 0) {
      // C on segment AB if ABxAC = 0 and CA.CB <= 0
      if (
        ABxAC == 0 &&
        sign(
          dot(
            {
              x: A.x - C.x,
              y: A.y - C.y,
            },
            { x: B.x - C.x, y: B.y - C.y }
          )
        ) <= 0
      )
        return true;
      if (
        ABxAD == 0 &&
        sign(
          dot(
            {
              x: A.x - D.x,
              y: A.y - D.y,
            },
            { x: B.x - D.x, y: B.y - D.y }
          )
        ) <= 0
      )
        return true;
      if (
        CDxCA == 0 &&
        sign(
          dot(
            {
              x: C.x - A.x,
              y: C.y - A.y,
            },
            { x: D.x - A.x, y: D.y - A.y }
          )
        ) <= 0
      )
        return true;
      if (
        CDxCB == 0 &&
        sign(
          dot(
            {
              x: C.x - B.x,
              y: C.y - B.y,
            },
            { x: D.x - B.x, y: D.y - B.y }
          )
        ) <= 0
      )
        return true;
      return false;
    }
  },
  // give pixel neighborhood counter-clockwise ID's for
  // easier access with findContour algorithm
  neighborIDToIndex: (i, j, id) => {
    if (id == 0){return [i,j+1];}
    if (id == 1){return [i-1,j+1];}
    if (id == 2){return [i-1,j];}
    if (id == 3){return [i-1,j-1];}
    if (id == 4){return [i,j-1];}
    if (id == 5){return [i+1,j-1];}
    if (id == 6){return [i+1,j];}
    if (id == 7){return [i+1,j+1];}
    return null;
  },
  neighborIndexToID: (i0, j0, i, j) => {
    let di = i - i0;
    let dj = j - j0;
    if (di == 0 && dj == 1){return 0;}
    if (di ==-1 && dj == 1){return 1;}
    if (di ==-1 && dj == 0){return 2;}
    if (di ==-1 && dj ==-1){return 3;}
    if (di == 0 && dj ==-1){return 4;}
    if (di == 1 && dj ==-1){return 5;}
    if (di == 1 && dj == 0){return 6;}
    if (di == 1 && dj == 1){return 7;}
    return -1;
  },

  // first counter clockwise non-zero element in neighborhood
  ccwNon0: (F, w, h, i0, j0, i, j, offset) => {
    let id = Helpers.neighborIndexToID(i0,j0,i,j);
    for (let k = 0; k < N_PIXEL_NEIGHBOR; k++){
      let kk = (k+id+offset + N_PIXEL_NEIGHBOR*2) % N_PIXEL_NEIGHBOR;
      let ij = Helpers.neighborIDToIndex(i0,j0,kk);
      if (F[ij[0]*w+ij[1]]!=0){
        return ij;
      }
    }
    return null;
  },

  // first clockwise non-zero element in neighborhood
  cwNon0: (F, w, h, i0, j0, i, j, offset) => {
    let id = Helpers.neighborIndexToID(i0,j0,i,j);
    for (let k = 0; k < N_PIXEL_NEIGHBOR; k++){
      let kk = (-k+id-offset + N_PIXEL_NEIGHBOR*2) % N_PIXEL_NEIGHBOR;
      let ij = Helpers.neighborIDToIndex(i0,j0,kk);
      if (F[ij[0]*w+ij[1]]!=0){
        return ij;
      }
    }
    return null;
  },

  /**
   * Find contours in a binary image
   * <p>
   * Implements Suzuki, S. and Abe, K.
   * Topological Structural Analysis of Digitized Binary Images by Border Following.
   * <p>
   * See source code for step-by-step correspondence to the paper's algorithm
   * description.
   * @param  F    The bitmap, stored in 1-dimensional row-major form. 
   *              0=background, 1=foreground, will be modified by the function
   *              to hold semantic information
   * @param  w    Width of the bitmap
   * @param  h    Height of the bitmap
   * @return      An array of contours found in the image.
   * @see         Contour
   */
   findContours: (F, w, h) => {
    // Topological Structural Analysis of Digitized Binary Images by Border Following.
    // Suzuki, S. and Abe, K., CVGIP 30 1, pp 32-46 (1985)
    let nbd = 1;
    let lnbd = 1;

    let contours = [];
    
    // Without loss of generality, we assume that 0-pixels fill the frame 
    // of a binary picture
    for (let i = 1; i < h-1; i++){
      F[i*w] = 0; F[i*w+w-1]=0;
    }
    for (let i = 0; i < w; i++){
      F[i] = 0; F[w*h-1-i]=0;
    }

    //Scan the picture with a TV raster and perform the following steps 
    //for each pixel such that fij # 0. Every time we begin to scan a 
    //new row of the picture, reset LNBD to 1.
    for (let i = 1; i < h-1; i++) {
      lnbd = 1;

      for (let j = 1; j < w-1; j++) {
        
        let i2 = 0, j2 = 0;
        if (F[i*w+j] == 0) {
          continue;
        }
        //(a) If fij = 1 and fi, j-1 = 0, then decide that the pixel 
        //(i, j) is the border following starting point of an outer 
        //border, increment NBD, and (i2, j2) <- (i, j - 1).
        if (F[i*w+j] == 1 && F[i*w+(j-1)] == 0) {
          nbd ++;
          i2 = i;
          j2 = j-1;
          
          
        //(b) Else if fij >= 1 and fi,j+1 = 0, then decide that the 
        //pixel (i, j) is the border following starting point of a 
        //hole border, increment NBD, (i2, j2) <- (i, j + 1), and 
        //LNBD + fij in case fij > 1.  
        } else if (F[i*w+j]>=1 && F[i*w+j+1] == 0) {
          nbd ++;
          i2 = i;
          j2 = j+1;
          if (F[i*w+j]>1) {
            lnbd = F[i*w+j];
          }
          
          
        } else {
          //(c) Otherwise, go to (4).
          //(4) If fij != 1, then LNBD <- |fij| and resume the raster
          //scan from pixel (i,j+1). The algorithm terminates when the
          //scan reaches the lower right corner of the picture
          if (F[i*w+j]!=1){lnbd = Math.abs(F[i*w+j]);}
          continue;
          
        }
        //(2) Depending on the types of the newly found border 
        //and the border with the sequential number LNBD 
        //(i.e., the last border met on the current row), 
        //decide the parent of the current border as shown in Table 1.
        // TABLE 1
        // Decision Rule for the Parent Border of the Newly Found Border B
        // ----------------------------------------------------------------
        // Type of border B'
        // \    with the sequential
        //     \     number LNBD
        // Type of B \                Outer border         Hole border
        // ---------------------------------------------------------------     
        // Outer border               The parent border    The border B'
        //                            of the border B'
        //
        // Hole border                The border B'      The parent border
        //                                               of the border B'
        // ----------------------------------------------------------------
        
        let B = {};
        B.points = []
        B.points.push([j,i]);
        B.isHole = (j2 == j+1);
        B.id = nbd;
        contours.push(B);

        let B0 = {}
        for (let c = 0; c < contours.length; c++){
          if (contours[c].id == lnbd){
            B0 = contours[c];
            break;
          }
        }
        if (B0.isHole){
          if (B.isHole){
            B.parent = B0.parent;
          }else{
            B.parent = lnbd;
          }
        }else{
          if (B.isHole){
            B.parent = lnbd;
          }else{
            B.parent = B0.parent;
          }
        }
        
        //(3) From the starting point (i, j), follow the detected border: 
        //this is done by the following substeps (3.1) through (3.5).
        
        //(3.1) Starting from (i2, j2), look around clockwise the pixels 
        //in the neigh- borhood of (i, j) and tind a nonzero pixel. 
        //Let (i1, j1) be the first found nonzero pixel. If no nonzero 
        //pixel is found, assign -NBD to fij and go to (4).
        let i1 = -1, j1 = -1;
        let i1j1 = Helpers.cwNon0(F,w,h,i,j,i2,j2,0);
        if (i1j1 == null){
          F[i*w+j] = -nbd;
          //go to (4)
          if (F[i*w+j]!=1){lnbd = Math.abs(F[i*w+j]);}
          continue;
        }
        i1 = i1j1[0]; j1 = i1j1[1];
        
        // (3.2) (i2, j2) <- (i1, j1) ad (i3,j3) <- (i, j).
        i2 = i1;
        j2 = j1;
        let i3 = i;
        let j3 = j;
        
        while (true){

          //(3.3) Starting from the next elementof the pixel (i2, j2) 
          //in the counterclock- wise order, examine counterclockwise 
          //the pixels in the neighborhood of the current pixel (i3, j3) 
          //to find a nonzero pixel and let the first one be (i4, j4).
          
          let i4j4 = Helpers.ccwNon0(F,w,h,i3,j3,i2,j2,1);
   
          var i4 = i4j4[0];
          var j4 = i4j4[1];

          contours[contours.length-1].points.push([j4,i4]);
          
          //(a) If the pixel (i3, j3 + 1) is a O-pixel examined in the
          //substep (3.3) then fi3, j3 <-  -NBD.
          if (F[i3*w+j3+1] == 0){
            F[i3*w+j3] = -nbd;
            
          //(b) If the pixel (i3, j3 + 1) is not a O-pixel examined 
          //in the substep (3.3) and fi3,j3 = 1, then fi3,j3 <- NBD.
          }else if (F[i3*w+j3] == 1){
            F[i3*w+j3] = nbd;
          }else{
            //(c) Otherwise, do not change fi3, j3.
          }
          
          //(3.5) If (i4, j4) = (i, j) and (i3, j3) = (i1, j1) 
          //(coming back to the starting point), then go to (4);
          if (i4 == i && j4 == j && i3 == i1 && j3 == j1){
            if (F[i*w+j]!=1){lnbd = Math.abs(F[i*w+j]);}
            break;
            
          //otherwise, (i2, j2) + (i3, j3),(i3, j3) + (i4, j4), 
          //and go back to (3.3).
          }else{
            i2 = i3;
            j2 = j3;
            i3 = i4;
            j3 = j4;
          }
        }
      }
    }
    return contours;
  },


  pointDistanceToSegment: (p, p0, p1) => {
    // https://stackoverflow.com/a/6853926
    let x = p[0];   let y = p[1];
    let x1 = p0[0]; let y1 = p0[1];
    let x2 = p1[0]; let y2 = p1[1];
    let A = x - x1; let B = y - y1; let C = x2 - x1; let D = y2 - y1;
    let dot = A*C+B*D;
    let len_sq = C*C+D*D;
    let param = -1;
    if (len_sq != 0) {
      param = dot / len_sq;
    }
    let xx; let yy;
    if (param < 0) {
      xx = x1; yy = y1;
    }else if (param > 1) {
      xx = x2; yy = y2;
    }else {
      xx = x1 + param*C;
      yy = y1 + param*D;
    }
    let dx = x - xx;
    let dy = y - yy;
    return Math.sqrt(dx*dx+dy*dy);
  },

  /**
   * Simplify contour by removing definately extraneous vertices, 
   * without modifying shape of the contour.
   * @param  polyline  The vertices
   * @return           A simplified copy
   * @see              approxPolyDP
   */
  approxPolySimple: (polyline) => {
    let epsilon = 0.1;
    if (polyline.length <= 2){
      return polyline;
    }
    let ret = []
    ret.push(polyline[0].slice());
      
    for (let i = 1; i < polyline.length-1; i++){
      let   d = Helpers.pointDistanceToSegment(polyline[i], 
                                       polyline[i-1], 
                                       polyline[i+1]);
      if (d > epsilon){
        ret.push(polyline[i].slice());
      }   
    }
    ret.push(polyline[polyline.length-1].slice());
    return ret;
  },

  /**
   * Simplify contour using Douglas Peucker algorithm.
   * <p>   
   * Implements David Douglas and Thomas Peucker, 
   * "Algorithms for the reduction of the number of points required to 
   * represent a digitized line or its caricature", 
   * The Canadian Cartographer 10(2), 112–122 (1973)
   * @param  polyline  The vertices
   * @param  epsilon   Maximum allowed error
   * @return           A simplified copy
   * @see              approxPolySimple
   */
  approxPolyDP: (polyline, epsilon) => {
    // https://en.wikipedia.org/wiki/Ramer–Douglas–Peucker_algorithm
    // David Douglas & Thomas Peucker, 
    // "Algorithms for the reduction of the number of points required to 
    // represent a digitized line or its caricature", 
    // The Canadian Cartographer 10(2), 112–122 (1973)
    
    if (polyline.length <= 2){
      return polyline;
    }
    let dmax   = 0;
    let argmax = -1;
    for (let i = 1; i < polyline.length-1; i++){
      let d = Helpers.pointDistanceToSegment(polyline[i], 
                                     polyline[0], 
                                     polyline[polyline.length-1]);
      if (d > dmax){
        dmax = d;
        argmax = i;
      }  
    }
    // console.log(dmax)
    let ret = [];
    if (dmax > epsilon){
      let L = Helpers.approxPolyDP(polyline.slice(0,argmax+1),epsilon);
      let R = Helpers.approxPolyDP(polyline.slice(argmax,polyline.length),epsilon);
      ret = ret.concat(L.slice(0,L.length-1)).concat(R);
    }else{
      ret.push(polyline[0].slice());
      ret.push(polyline[polyline.length-1].slice());
    }
    return ret;
  },
  calDetailChildrenWhenRoomMove: (oldAttr,newAttr, children) => {
    let item = {...children}
    const oldPosition = oldAttr && oldAttr?.position || [0,0,0]
    const changePosition = [
      newAttr.position[0] - oldPosition[0],
      newAttr.position[1] - oldPosition[1],
      newAttr.position[2] - oldPosition[2]
    ]
    if(item.type == 'door'){
      oldAttr.wallBoundaries?.forEach((bdr,k) => {
        const nextIndex = (k + 1) % oldAttr.wallBoundaries.length
        const nextWbdr = oldAttr.wallBoundaries[nextIndex]
        if(
          JSON.stringify(item.boundary) == JSON.stringify([bdr,nextWbdr])
        ) {
          item.boundary = [newAttr.wallBoundaries[k],newAttr.wallBoundaries[nextIndex]]
        }else if(
          JSON.stringify(item.boundary) == JSON.stringify([nextWbdr,bdr])
        ) {
          item.boundary = [newAttr.wallBoundaries[nextIndex],newAttr.wallBoundaries[k]]
        }
      })
    }else if(item.boundary && Array.isArray(item.boundary)){
        let newBoundary = []
        item.boundary.map((bdr) => {
          newBoundary.push([
            bdr[0] + changePosition[0],
            bdr[1] + changePosition[1],
          ])
        })
        item.boundary = newBoundary
    }
    if(Array.isArray(item.position) && item.position.length == 3){
      const newPosition = [
        (item.position[0] || 0) + changePosition[0],
        (item.position[1] || 0) + changePosition[1],
        (item.position[2] || 0) + changePosition[2]
      ]
      item.position = newPosition
    }
    return item
  },
  calDetailChildrenWhenRoomRotate: (centroid,changeRotation, children) => {
    let item = {...children}
    const positionPoint = new THREE.Vector3(centroid[0],centroid[1],0)
    if(Array.isArray(item.position) && item.position.length == 3){
      const oldPoint = new THREE.Vector3(item.position[0],item.position[1],0)
      const newPosition = oldPoint.clone().sub(positionPoint).applyAxisAngle(new THREE.Vector3(0,0,1),changeRotation[2]).add(positionPoint)
      // console.log(item.position,[newPosition.x,newPosition.y]) 
      item.position = [newPosition.x,newPosition.y,item.position[2]]
    }
    if(item.boundary && Array.isArray(item.boundary)){
      let newBoundary = []
      item.boundary.map((bdr) => {
        const bdrPoint = new THREE.Vector3(bdr[0],bdr[1],0)
        const newPosition = bdrPoint.clone().sub(positionPoint).applyAxisAngle(new THREE.Vector3(0,0,1),changeRotation[2]).add(positionPoint)
        newBoundary.push([
          newPosition.x,
          newPosition.y,
        ])
      })
      item.boundary = newBoundary
    }
    return item
  },
  calDetailDoorWhenRoomChangeWall: (oldAttr,newAttr,children) => {
    let item = {...children}
    if(!children.boundary) return item
    const oldBoundary = children.boundary?.map((x) => [Math.round(x[0] * 100) / 100,Math.round(x[1] * 100) / 100]);
    const oldBoundaries = oldAttr && oldAttr.boundaries || []
    const wallBoundaries = oldAttr.wallBoundaries || []
    let changeBoundaryIndexs = []
    
    oldBoundaries?.forEach((bdr,k) => {
      if(JSON.stringify(bdr) != JSON.stringify(newAttr.boundaries[k])) changeBoundaryIndexs.push(k)
    })
    let findOldBoundaryIndexs = []
    if(changeBoundaryIndexs.length == 2){
      if(JSON.stringify(oldBoundary[0]) == JSON.stringify(wallBoundaries[changeBoundaryIndexs[0]])
        && JSON.stringify(oldBoundary[1]) == JSON.stringify(wallBoundaries[changeBoundaryIndexs[1]])){
        findOldBoundaryIndexs = changeBoundaryIndexs
      }else if(JSON.stringify(oldBoundary[0]) == JSON.stringify(wallBoundaries[changeBoundaryIndexs[1]])
        && JSON.stringify(oldBoundary[1]) == JSON.stringify(wallBoundaries[changeBoundaryIndexs[0]])){
        findOldBoundaryIndexs = [changeBoundaryIndexs[1],changeBoundaryIndexs[0]]
      }
    }
    if(findOldBoundaryIndexs.length != 2){
      changeBoundaryIndexs?.forEach((index,k) => {
        const preIndex = (index - 1 + wallBoundaries.length) % wallBoundaries.length
        const nextIndex = (index + 1) % wallBoundaries.length
        
        if(!changeBoundaryIndexs.includes(preIndex)){
          if(JSON.stringify(oldBoundary[0]) == JSON.stringify(wallBoundaries[preIndex])
          && JSON.stringify(oldBoundary[1]) == JSON.stringify(wallBoundaries[index])){
            findOldBoundaryIndexs = [preIndex,index]
          }else if(JSON.stringify(oldBoundary[0]) == JSON.stringify(wallBoundaries[index])
          && JSON.stringify(oldBoundary[1]) == JSON.stringify(wallBoundaries[preIndex])){
            findOldBoundaryIndexs = [index,preIndex]
          }
        }
        if(!changeBoundaryIndexs.includes(nextIndex)){
          if(JSON.stringify(oldBoundary[0]) == JSON.stringify(wallBoundaries[nextIndex])
          && JSON.stringify(oldBoundary[1]) == JSON.stringify(wallBoundaries[index])){
            findOldBoundaryIndexs = [nextIndex,index]
          }else if(JSON.stringify(oldBoundary[0]) == JSON.stringify(wallBoundaries[index])
          && JSON.stringify(oldBoundary[1]) == JSON.stringify(wallBoundaries[nextIndex])){
            findOldBoundaryIndexs = [index,nextIndex]
          }
        }
      })
    }
    // console.log(JSON.stringify(oldBoundary),JSON.stringify(wallBoundaries),changeBoundaryIndexs,findOldBoundaryIndexs)
    if(findOldBoundaryIndexs.length == 2){
      item.boundary = [newAttr.wallBoundaries[findOldBoundaryIndexs[0]],newAttr.wallBoundaries[findOldBoundaryIndexs[1]]]
      const angle = Helpers.caculateDegreeFromTwoPointFollowYAxis(
        newAttr.wallBoundaries[findOldBoundaryIndexs[1]][0],
        newAttr.wallBoundaries[findOldBoundaryIndexs[1]][1],
        newAttr.wallBoundaries[findOldBoundaryIndexs[0]][0],
        newAttr.wallBoundaries[findOldBoundaryIndexs[0]][1],
      );
      // const vtcp = new THREE.Vector2(
      //   newAttr.wallBoundaries[findOldBoundaryIndexs[0]][0] - wallBoundaries[findOldBoundaryIndexs[0]][0],
      //   newAttr.wallBoundaries[findOldBoundaryIndexs[1]][1] - wallBoundaries[findOldBoundaryIndexs[1]][1]
      // )
      const newPo = (new THREE.Vector2(
        newAttr.wallBoundaries[findOldBoundaryIndexs[1]][0] - newAttr.wallBoundaries[findOldBoundaryIndexs[0]][0],
        newAttr.wallBoundaries[findOldBoundaryIndexs[1]][1] - newAttr.wallBoundaries[findOldBoundaryIndexs[0]][1],
      )).setLength((new THREE.Vector2(
        wallBoundaries[findOldBoundaryIndexs[0]][0] - item.position[0],
        wallBoundaries[findOldBoundaryIndexs[0]][1] - item.position[1]
      )).length()).add(new THREE.Vector2(
        newAttr.wallBoundaries[findOldBoundaryIndexs[0]][0],
        newAttr.wallBoundaries[findOldBoundaryIndexs[0]][1]
      ))
      // console.log(JSON.stringify(wallBoundaries),JSON.stringify(newAttr.wallBoundaries))
      // const newPo = vtcp.clone().add(new THREE.Vector2(children.position[0],children.position[1]))
      item.position = [newPo.x,newPo.y,item.position[2]]
      item.rotation = [0, 0, ((angle - 90) * Math.PI) / 180]
      // return item
    }

    return item
  },
  getDirectionPhongThuyName: (angle = 0) => {
    let directionName = 'Tý';
    if ((angle <= 360 && angle >= 352.5) || (angle >= 0 && angle <= 7.5)) {
      directionName = 'Tý';
    } else if (angle >= 7.5 && angle <= 22.5) {
      directionName = 'Quí';
    } else if (angle >= 22.5 && angle <= 35.5) {
      directionName = 'Sửu';
    } else if (angle >= 35.5 && angle <= 52.5) {
      directionName = 'Cấn';
    } else if (angle >= 52.5 && angle <= 67.5) {
      directionName = 'Dần';
    } else if (angle >= 67.5 && angle <= 82.5) {
      directionName = 'Giáp';
    } else if (angle >= 82.5 && angle <= 97.5) {
      directionName = 'Mão';
    } else if (angle >= 97.5 && angle <= 112.5) {
      directionName = 'Ất';
    } else if (angle >= 112.5 && angle <= 127.5) {
      directionName = 'Thìn';
    } else if (angle >= 127.5 && angle <= 142.5) {
      directionName = 'Tốn';
    } else if (angle >= 142.5 && angle <= 157.5) {
      directionName = 'Tỵ';
    } else if (angle >= 157.5 && angle <= 172.5) {
      directionName = 'Bính';
    } else if (angle >= 172.5 && angle <= 187.5) {
      directionName = 'Ngọ';
    } else if (angle >= 187.5 && angle <= 202.5) {
      directionName = 'Đinh';
    } else if (angle >= 202.5 && angle <= 217.5) {
      directionName = 'Mùi';
    } else if (angle >= 217.5 && angle <= 232.5) {
      directionName = 'Khôn';
    } else if (angle >= 232.5 && angle <= 247.5) {
      directionName = 'Thân';
    } else if (angle >= 247.5 && angle <= 262.5) {
      directionName = 'Canh';
    } else if (angle >= 262.5 && angle <= 277.5) {
      directionName = 'Dậu';
    } else if (angle >= 277.5 && angle <= 292.5) {
      directionName = 'Tân';
    } else if (angle >= 292.5 && angle <= 307.5) {
      directionName = 'Tuất';
    } else if (angle >= 307.5 && angle <= 322.5) {
      directionName = 'Càn';
    } else if (angle >= 322.5 && angle <= 337.5) {
      directionName = 'Hợi';
    } else if (angle >= 337.5 && angle <= 352.5) {
      directionName = 'Nhâm';
    }
    return directionName;
  },
};

export default Helpers;
