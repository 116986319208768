import React from "react";
import { Navigate } from "react-router-dom";

import auth_routes from "@adminv2/routes/auth";
import catalog_routes from "@adminv2/routes/catalog";
import common_routes from "@adminv2/routes/common";

import coinpackage_routes from "@adminv2/routes/coinpackage";
import customer_routes from "@adminv2/routes/customer";
import familytree_routes from "@adminv2/routes/familytree";
import faqs_routes from "@adminv2/routes/faqs";
import fengshuimaster_routes from "@adminv2/routes/fengshuimaster";
import floorplan_routes from "@adminv2/routes/floorplan";
import information_routes from "@adminv2/routes/information";
import layout_routes from "@adminv2/routes/layout";
import localisation_routes from "@adminv2/routes/localisation";
import marketing_routes from "@adminv2/routes/marketing";
import marketingchannel_routes from "@adminv2/routes/marketingchannel";
import news_routes from "@adminv2/routes/news";
import sale_routes from "@adminv2/routes/sale";
import salechannel_routes from "@adminv2/routes/salechannel";
import setting_routes from "@adminv2/routes/setting";
import stock_routes from "@adminv2/routes/stock";
import translation_routes from "@adminv2/routes/translation";
import user_routes from "@adminv2/routes/user";
import configorder_routes from "@adminv2/routes/configorder";

const Dashboard = React.lazy(() => import("@adminv2/views/Dashboard"));

const routes = {
  ...auth_routes,
  dashboard: {
    path: "/dashboard",
    name: "Dashboard",
    exact: false,
    main: <Dashboard />,
  },
  ...common_routes,
  ...catalog_routes,
  ...customer_routes,
  ...faqs_routes,
  ...information_routes,
  ...layout_routes,
  ...localisation_routes,
  ...marketing_routes,
  ...news_routes,
  ...sale_routes,
  ...coinpackage_routes,
  ...salechannel_routes,
  ...marketingchannel_routes,
  ...setting_routes,
  ...stock_routes,
  ...translation_routes,
  ...user_routes,
  ...fengshuimaster_routes,
  ...familytree_routes,
  ...floorplan_routes,
  ...configorder_routes,
  home: {
    path: "/",
    name: "Dashboard",
    exact: false,
    main: <Navigate to="/login" />,
  },
  getRoutePath: (path) => {
    for (var i = 0; i < Object.keys(routes).length; i++) {
      let key = Object.keys(routes)[i];
      if (
        routes[key] != null &&
        routes[key].path != null &&
        routes[key].path == path
      ) {
        return routes[key];
      }
    }
    return {};
  },
  getCurrentRoute: () => {
    const { pathname } = window.location;
    const route = routes.getRoutePath(pathname);
    return route;
  },
  getParentRoutePath: (path) => {
    for (var i = 0; i < Object.keys(routes).length; i++) {
      let key = Object.keys(routes)[i];
      if (
        routes[key] != null &&
        routes[key].path != null &&
        path.includes(routes[key].path + "/")
      ) {
        return routes[key];
      }
    }
    return {};
  },
  getParentRoutePaths: (path) => {
    let parents = []
    for (var i = 0; i < Object.keys(routes).length; i++) {
      let key = Object.keys(routes)[i];
      if (
        routes[key] != null &&
        routes[key].path != null &&
        path.includes(routes[key].path + "/")
      ) {
        parents.push(routes[key]);
      }
    }
    parents.sort((a, b) => {
      return a.path?.length - b.path?.length;
    });
    return parents;
  },
};

export default routes;
