import React from "react";

const FamilyTree = React.lazy(() =>
  import("@adminv2/views/family-tree/FamilyTree")
);
const FamilyTreeEdit = React.lazy(() =>
  import("@adminv2/views/family-tree/FamilyTreeEdit")
);
const FamilyTreeInfo = React.lazy(() =>
  import("@adminv2/views/family-tree/FamilyTreeInfo")
);
const FamilyTreeAdd = React.lazy(() =>
  import("@adminv2/views/family-tree/FamilyTreeAdd")
);

const FamilyTreeVaive = React.lazy(() =>
  import("@adminv2/views/family-tree/FamilyTreeVaive")
);
const FamilyTreeVaiveEdit = React.lazy(() =>
  import("@adminv2/views/family-tree/FamilyTreeVaiveEdit")
);
const FamilyTreeVaiveAdd = React.lazy(() =>
  import("@adminv2/views/family-tree/FamilyTreeVaiveAdd")
);

const FamilyTreeNgheNghiep = React.lazy(() =>
  import("@adminv2/views/family-tree/FamilyTreeNgheNghiep")
);
const FamilyTreeNgheNghiepEdit = React.lazy(() =>
  import("@adminv2/views/family-tree/FamilyTreeNgheNghiepEdit")
);
const FamilyTreeNgheNghiepAdd = React.lazy(() =>
  import("@adminv2/views/family-tree/FamilyTreeNgheNghiepAdd")
);

const NhaThoHo = React.lazy(() =>
  import("@adminv2/views/family-tree/NhaThoHoList")
);

const MoTo = React.lazy(() =>
  import("@adminv2/views/family-tree/MoToList")
);
const routes = {
  
  family_tree: {
    path: "/family-tree",
    name: "Family Tree",
    exact: true,
    main: <FamilyTree />,
  },
  family_tree_edit: {
    path: "/family-tree/:id/edit",
    name: "Family Tree Edit",
    exact: false,
    main: <FamilyTreeEdit />,
  },
  family_tree_info: {
    path: "/family-tree/:id/info",
    name: "Family Tree Info",
    exact: false,
    main: <FamilyTreeInfo />,
  },
  family_tree_add: {
    path: "/family-tree/add",
    name: "Family Tree Add",
    exact: true,
    main: <FamilyTreeAdd />,
  },
  // Vai vế
  family_tree_vaive: {
    path: "/family-tree-vai-ve",
    name: "Family Tree Vai vế",
    exact: true,
    main: <FamilyTreeVaive />,
  },
  family_tree_vaive_edit: {
    path: "/family-tree-vai-ve/:id/edit",
    name: "Family Tree Vai vế Edit",
    exact: false,
    main: <FamilyTreeVaiveEdit />,
  },
  family_tree_vaive_add: {
    path: "/family-tree-vai-ve/add",
    name: "Family Tree Vai vế Add",
    exact: true,
    main: <FamilyTreeVaiveAdd />,
  },
  // Nghề nghiệp
  family_tree_nghenghiep: {
    path: "/family-tree-nghe-nghiep",
    name: "Family Tree Nghề nghiệp",
    exact: true,
    main: <FamilyTreeNgheNghiep />,
  },
  family_tree_nghenghiep_edit: {
    path: "/family-tree-nghe-nghiep/:id/edit",
    name: "Family Tree Nghề nghiệp Edit",
    exact: false,
    main: <FamilyTreeNgheNghiepEdit />,
  },
  family_tree_nghenghiep_add: {
    path: "/family-tree-nghe-nghiep/add",
    name: "Family Tree Nghề nghiệp Add",
    exact: true,
    main: <FamilyTreeNgheNghiepAdd />,
  },
  nha_tho_ho: {
    path: "/family-tree-nha-tho-ho",
    name: "Nhà thờ họ",
    exact: true,
    main: <NhaThoHo />,
  },
  mo_to: {
    path: "/family-tree-mo-to",
    name: "Mộ tổ",
    exact: true,
    main: <MoTo />,
  },
};

export default routes;
