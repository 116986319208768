import GLOBAL from '@adminv2/app.json';
import globalState from '@adminv2/system/globalState';
import axios from 'axios';
const Todos = {
  search: (key = '') => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer/search?filter[name]=' + key;
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getList: data => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer/list';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getForm: (id = 0) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer/form?id=' + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  export: data => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer/export';
      url += '?' + new URLSearchParams(data).toString();
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      })
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  create: data => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer/add';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  edit: (id, data) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer/' + id + '/update';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  delete: id => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer/' + id + '/delete';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  deletes: selected => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer/deletes';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, {selected: selected})
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  getCustomFieldList: data => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/custom-field/list';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getCustomFieldForm: (id = 0) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/custom-field/form?id=' + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  customFieldCreate: data => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/custom-field/add';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  customFieldEdit: (id, data) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/custom-field/' + id + '/update';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  customFieldDelete: id => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/custom-field/' + id + '/delete';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  customFieldDeletes: selected => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/custom-field/deletes';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, {selected: selected})
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getCustomerGroupList: data => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer-group/list';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getCustomerGroupForm: (id = 0) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer-group/form?id=' + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  customerGroupCreate: data => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer-group/add';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  customerGroupEdit: (id, data) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer-group/' + id + '/update';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  customerGroupDelete: id => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer-group/' + id + '/delete';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  customerGroupDeletes: selected => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer-group/deletes';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, {selected: selected})
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  // anome
  getanemometerList: data => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/anemometer/list';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getanemometerForm: (id = 0) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/anemometer/form?id=' + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  anemometerCreate: data => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/anemometer/add';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  anemometerEdit: (id, data) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/anemometer/' + id + '/update';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  anemometerDelete: id => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/anemometer/' + id + '/delete';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  anemometerDeletes: selected => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/anemometer/deletes';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, {selected: selected})
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  getCustomerOrders: id => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer/' + id + '/orders';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getCustomerBalance: id => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer/' + id + '/coin-balance/histories';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  addBalance: (id, data) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer/' + id + '/coin-balance/add';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  getCustomerRewardPoint: id => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer/' + id + '/reward/histories';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  addReward: (id, data) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer/' + id + '/reward/add';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  getSubscriptionList: data => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer-subscription/list';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getSubscriptionForm: (id = 0) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer-subscription/form?id=' + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  subscriptionCreate: data => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer-subscription/add';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  subscriptionEdit: (id, data) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer-subscription/' + id + '/update';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getOrders: (id, data = {}) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/customer/' + id + '/orders';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
};
export default Todos;
