import React from 'react';
import {routes} from '@adminv2/App'

function Footer() {
  return (
      <div className="p-5 d-flex justify-content-between border-top mt-5">
        <span>Version: v1.0.0</span>
        <span>Powered by <a href="https://thayai.com" target="_blank">ThayAI.com</a></span>
      </div>
  );
}

export default Footer;
