import React from 'react';

const News = React.lazy(() => import('@adminv2/views/news/News'))
const NewsEdit = React.lazy(() => import('@adminv2/views/news/NewsEdit'))
const NewsAdd = React.lazy(() => import('@adminv2/views/news/NewsAdd'))

const Category = React.lazy(() => import('@adminv2/views/news/Category'))
const CategoryEdit = React.lazy(() => import('@adminv2/views/news/CategoryEdit'))
const CategoryAdd = React.lazy(() => import('@adminv2/views/news/CategoryAdd'))

const KhoaHocNews = React.lazy(() => import('@adminv2/views/news/khoa-hoc/News'))
const KhoaHocNewsEdit = React.lazy(() => import('@adminv2/views/news/khoa-hoc/NewsEdit'))
const KhoaHocNewsAdd = React.lazy(() => import('@adminv2/views/news/khoa-hoc/NewsAdd'))
const KhoaHocNewsCategory = React.lazy(() => import('@adminv2/views/news/khoa-hoc/Category'))
const KhoaHocNewsCategoryEdit = React.lazy(() => import('@adminv2/views/news/khoa-hoc/CategoryEdit'))
const KhoaHocNewsCategoryAdd = React.lazy(() => import('@adminv2/views/news/khoa-hoc/CategoryAdd'))

const TapTucNhangDenNews = React.lazy(() => import('@adminv2/views/news/tap-tuc-nhang-den/News'))
const TapTucNhangDenNewsEdit = React.lazy(() => import('@adminv2/views/news/tap-tuc-nhang-den/NewsEdit'))
const TapTucNhangDenNewsAdd = React.lazy(() => import('@adminv2/views/news/tap-tuc-nhang-den/NewsAdd'))
const TapTucNhangDenNewsCategory = React.lazy(() => import('@adminv2/views/news/tap-tuc-nhang-den/Category'))
const TapTucNhangDenNewsCategoryEdit = React.lazy(() => import('@adminv2/views/news/tap-tuc-nhang-den/CategoryEdit'))
const TapTucNhangDenNewsCategoryAdd = React.lazy(() => import('@adminv2/views/news/tap-tuc-nhang-den/CategoryAdd'))

const routes = {
  news : {
      path : '/news',
      name: "News",
      exact : true,
      main : <News />
  },
  news_edit : {
      path : '/news/:id/edit',
      name: "News Edit",
      exact : false,
      main : <NewsEdit />
  },
  news_add : {
      path : '/news/add',
      name: "News Add",
      exact : true,
      main : <NewsAdd />
  },
  news_category : {
      path : '/nc',
      name: "News Category",
      exact : true,
      main : <Category />
  },
  news_category_edit : {
      path : '/nc/:id/edit',
      name: "News Category Edit",
      exact : false,
      main : <CategoryEdit />
  },
  news_category_add : {
      path : '/nc/add',
      name: "News Category Add",
      exact : true,
      main : <CategoryAdd />
  },

    khoa_hoc_news : {
        path : '/khoa-hoc-news',
        name: "Bản tin nghề",
        exact : true,
        main : <KhoaHocNews />
    },
    khoa_hoc_news_edit : {
        path : '/khoa-hoc-news/:id/edit',
        name: "Bản tin nghề Edit",
        exact : false,
        main : <KhoaHocNewsEdit />
    },
    khoa_hoc_news_add : {
        path : '/khoa-hoc-news/add',
        name: "Bản tin nghề Add",
        exact : true,
        main : <KhoaHocNewsAdd />
    },
    khoa_hoc_news_category : {
        path : '/khoa-hoc-nc',
        name: "Danh mục Bản tin nghề",
        exact : true,
        main : <KhoaHocNewsCategory />
    },
    khoa_hoc_news_category_edit : {
        path : '/khoa-hoc-nc/:id/edit',
        name: "Danh mục Bản tin nghề Edit",
        exact : false,
        main : <KhoaHocNewsCategoryEdit />
    },
    khoa_hoc_news_category_add : {
        path : '/khoa-hoc-nc/add',
        name: "Danh mục Bản tin nghề Add",
        exact : true,
        main : <KhoaHocNewsCategoryAdd />
    },
    // Tap tuc nhang den
    tap_tuc_nhang_den_news : {
        path : '/tap-tuc-nhang-den-news',
        name: "Tập tục nhang đèn",
        exact : true,
        main : <TapTucNhangDenNews />
    },
    tap_tuc_nhang_den_news_edit : {
        path : '/tap-tuc-nhang-den-news/:id/edit',
        name: "Tập tục nhang đèn Edit",
        exact : false,
        main : <TapTucNhangDenNewsEdit />
    },
    tap_tuc_nhang_den_news_add : {
        path : '/tap-tuc-nhang-den-news/add',
        name: "Tập tục nhang đèn Add",
        exact : true,
        main : <TapTucNhangDenNewsAdd />
    },
    tap_tuc_nhang_den_news_category : {
        path : '/tap-tuc-nhang-den-nc',
        name: "Danh mục",
        exact : true,
        main : <TapTucNhangDenNewsCategory />
    },
    tap_tuc_nhang_den_news_category_edit : {
        path : '/tap-tuc-nhang-den-nc/:id/edit',
        name: "Danh mục Edit",
        exact : false,
        main : <TapTucNhangDenNewsCategoryEdit />
    },
    tap_tuc_nhang_den_news_category_add : {
        path : '/tap-tuc-nhang-den-nc/add',
        name: "Danh mục Add",
        exact : true,
        main : <TapTucNhangDenNewsCategoryAdd />
    },
}


export default routes;
