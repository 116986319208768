import React from 'react';

const GShopCampaigns = React.lazy(() => import('@adminv2/views/marketing/google-smart-shopping/Campaigns'))
const GShopCampaignAdd = React.lazy(() => import('@adminv2/views/marketing/google-smart-shopping/CampaignAdd'))
const GShopCampaignEdit = React.lazy(() => import('@adminv2/views/marketing/google-smart-shopping/CampaignEdit'))
const GShopCampaignsLoading = React.lazy(() => import('@adminv2/views/marketing/google-smart-shopping/CampaignsLoading'))
const GShopDocuments = React.lazy(() => import('@adminv2/views/marketing/google-smart-shopping/Documents'))
const GShopProducts = React.lazy(() => import('@adminv2/views/marketing/google-smart-shopping/Products'))
const GShopReports = React.lazy(() => import('@adminv2/views/marketing/google-smart-shopping/Reports'))
const GShopSettings = React.lazy(() => import('@adminv2/views/marketing/google-smart-shopping/Settings'))
const GShopSettingsForbidden = React.lazy(() => import('@adminv2/views/marketing/google-smart-shopping/SettingsForbidden'))
const GShopSettingsPolicy = React.lazy(() => import('@adminv2/views/marketing/google-smart-shopping/SettingsPolicy'))
const GShopSettingsAccount = React.lazy(() => import('@adminv2/views/marketing/google-smart-shopping/SettingsAccount'))
const GShopSettingsMerchant = React.lazy(() => import('@adminv2/views/marketing/google-smart-shopping/SettingsMerchant'))

const routes = {
  gshop : {
      path : '/gshop',
      name: "Google Smart Shopping",
      exact : true,
      main : <GShopCampaigns />
  },
  gshop_campaigns : {
      path : '/gshop/campaigns',
      name: "Campaigns",
      exact : true,
      main : <GShopCampaigns />
  },
  gshop_campaigns_loading : {
      path : '/gshop/campaigns/loading',
      name: "Campaigns",
      exact : true,
      main : <GShopCampaignsLoading />
  },
  gshop_campaign_add : {
      path : '/gshop/campaigns/add',
      name: "Add Campaigns",
      exact : true,
      main : <GShopCampaignAdd />
  },
  gshop_campaign_edit : {
      path : '/gshop/campaigns/edit',
      name: "Edit Campaigns",
      exact : true,
      main : <GShopCampaignEdit />
  },
  gshop_documents : {
      path : '/gshop/documents',
      name: "Documents",
      exact : true,
      main : <GShopDocuments />
  },
  gshop_products : {
      path : '/gshop/products',
      name: "Products",
      exact : true,
      main : <GShopProducts />
  },
  gshop_reports : {
      path : '/gshop/reports',
      name: "Reports",
      exact : true,
      main : <GShopReports />
  },
  gshop_settings : {
      path : '/gshop/settings',
      name: "Settings",
      exact : true,
      main : <GShopSettings />
  },
  gshop_settings_forbidden : {
      path : '/gshop/settings/forbidden',
      name: "Forbidden",
      exact : true,
      main : <GShopSettingsForbidden />
  },
  gshop_settings_policy : {
      path : '/gshop/settings/policy',
      name: "Policy",
      exact : true,
      main : <GShopSettingsPolicy />
  },
  gshop_settings_account : {
      path : '/gshop/settings/account',
      name: "Account",
      exact : true,
      main : <GShopSettingsAccount />
  },
  gshop_settings_merchant : {
      path : '/gshop/settings/merchant',
      name: "Merchant",
      exact : true,
      main : <GShopSettingsMerchant />
  },
}


export default routes;
