import React,{useState,useEffect} from 'react'
import {
  Table,ButtonGroup, Button,Image,
  Card,OverlayTrigger,Tooltip,
  Row,Col,Form,Modal
} from 'react-bootstrap'

import {
  Components, APIs, routes,
  globalState, View, Lang, Navigation,
  Notify
} from '@adminv2/App'

import { MapContainer, TileLayer, useMap,Marker, Popup } from 'react-leaflet'
import FengshuiMasterOrders from '@adminv2/views/fengshuimaster/FengshuiMasterOrders'

const FengshuiMasterMapModal = React.forwardRef((props, ref) => {
  const [loading, setLoading] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [ordersShow, setOrdersShow] = React.useState(false);
  const [results, setResults] = React.useState([]);


  const open = () => {
    setShow(true)
    getList({limit: 200})
  }
  const close = () => {
    setShow(false)
  }

  const getList = async(data) => {
    setLoading(true)
    setResults([])
    const response = await APIs.fengshuimaster.getList(data)
    setLoading(false)
    if (response.data && Array.isArray(response.data.results)) {
      setResults(response.data.results)
    }
  }

  // Use this from another hook.
  React.useImperativeHandle(ref, () => ({
    open: open,
    close: close
  }))

  return (
    <>
      <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Body className="p-0">
          {
            show == true &&
            <MapContainer 
              style={{width: '100%',height: '100%'}}
              center={[16.106914, 106.0714714]} 
              zoom={6}
            >
              {(Array.isArray(results) ? results : []).map((result, key) => {
                if (result.latitude && result.longitude) {
                  return(
                    <Marker 
                      position={[result.latitude, result.longitude]}
                      // icon={{uri: result.image}}
                    >
                      <Popup>
                        <div>
                          <a 
                            className="fw-bold"
                            onClick={() => {
                              window.open(routes.fengshuimaster_edit.path.replace(':id', result.id),'_blank')
                            }}
                          >{result.name}</a>
                        </div>
                        <div>{result.email}</div>
                        <div>{result.telephone}</div>
                        <div className="mt-2">
                          <a 
                            className="text-decoration-underline"
                            onClick={() => {
                              setOrdersShow(result.id)
                            }}
                          >Xem lịch sử hồ sơ</a>
                        </div>
                      </Popup>
                    </Marker>
                  )
                }
                
              })}
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </MapContainer>
          }
          <div className="position-absolute bg-white p-2" style={{top: 0,left: 80,right: 80,zIndex: 10000}}> 
            <Components.FilterBar
              fields={['country_id','zone_id','sub_zone_id','ward_id','rating']}
              onFilter={(data) => {
                getList({filter: data,limit: 5000})
              }}
            ></Components.FilterBar>
          </div>
          <div className="position-absolute p-2 bg-dark rounded" style={{top: 20,right: 20,zIndex: 1000000}}>
            <a onClick={() => setShow(false)}><i className="fa fa-times fw-bold" style={{fontSize: 30,color: '#fff'}}></i></a>
          </div>
          {
            loading &&
            <div className="position-absolute d-flex justify-content-center align-items-center" style={{backgroundColor: '#0000008f',top: 0,left: 0,right: 0,bottom: 0,zIndex: 100000}}>
              <Components.LoadingIndicator/>
            </div>
          }
        </Modal.Body>
      </Modal>
      <Modal show={ordersShow} onHide={() => {
        setOrdersShow('')
      }}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            ordersShow &&
            <>
              <FengshuiMasterOrders
                id={ordersShow}
              />
            </>
          }
        </Modal.Body>
      </Modal>
    </>
  )
})

export default FengshuiMasterMapModal
