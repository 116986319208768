import React from "react";

const Country = React.lazy(() =>
  import("@adminv2/views/localisation/country/Country")
);
const CountryEdit = React.lazy(() =>
  import("@adminv2/views/localisation/country/CountryEdit")
);
const CountryAdd = React.lazy(() =>
  import("@adminv2/views/localisation/country/CountryAdd")
);

const Bank = React.lazy(() => import("@adminv2/views/localisation/bank/Bank"));
const BankEdit = React.lazy(() =>
  import("@adminv2/views/localisation/bank/BankEdit")
);
const BankAdd = React.lazy(() =>
  import("@adminv2/views/localisation/bank/BankAdd")
);

const Currency = React.lazy(() =>
  import("@adminv2/views/localisation/currency/Currency")
);
const CurrencyEdit = React.lazy(() =>
  import("@adminv2/views/localisation/currency/CurrencyEdit")
);
const CurrencyAdd = React.lazy(() =>
  import("@adminv2/views/localisation/currency/CurrencyAdd")
);

const GeoZone = React.lazy(() =>
  import("@adminv2/views/localisation/geozone/GeoZone")
);
const GeoZoneEdit = React.lazy(() =>
  import("@adminv2/views/localisation/geozone/GeoZoneEdit")
);
const GeoZoneAdd = React.lazy(() =>
  import("@adminv2/views/localisation/geozone/GeoZoneAdd")
);

const Location = React.lazy(() =>
  import("@adminv2/views/localisation/location/Location")
);
const LocationEdit = React.lazy(() =>
  import("@adminv2/views/localisation/location/LocationEdit")
);
const LocationAdd = React.lazy(() =>
  import("@adminv2/views/localisation/location/LocationAdd")
);

const Zone = React.lazy(() => import("@adminv2/views/localisation/zone/Zone"));
const ZoneEdit = React.lazy(() =>
  import("@adminv2/views/localisation/zone/ZoneEdit")
);
const ZoneAdd = React.lazy(() =>
  import("@adminv2/views/localisation/zone/ZoneAdd")
);

const SubZone = React.lazy(() =>
  import("@adminv2/views/localisation/subzone/SubZone")
);
const SubZoneEdit = React.lazy(() =>
  import("@adminv2/views/localisation/subzone/SubZoneEdit")
);
const SubZoneAdd = React.lazy(() =>
  import("@adminv2/views/localisation/subzone/SubZoneAdd")
);

const Ward = React.lazy(() => import("@adminv2/views/localisation/ward/Ward"));
const WardEdit = React.lazy(() =>
  import("@adminv2/views/localisation/ward/WardEdit")
);
const WardAdd = React.lazy(() =>
  import("@adminv2/views/localisation/ward/WardAdd")
);


const AcademicLevel = React.lazy(() =>
  import("@adminv2/views/localisation/academic-level/AcademicLevel")
);
const AcademicLevelEdit = React.lazy(() =>
  import("@adminv2/views/localisation/academic-level/AcademicLevelEdit")
);
const AcademicLevelAdd = React.lazy(() =>
  import("@adminv2/views/localisation/academic-level/AcademicLevelAdd")
);

const routes = {
  country: {
    path: "/setting/country",
    name: "Country",
    exact: true,
    main: <Country />,
  },
  country_edit: {
    path: "/setting/country/:id/edit",
    name: "Country Edit",
    exact: false,
    main: <CountryEdit />,
  },
  country_add: {
    path: "/setting/country/add",
    name: "Country Add",
    exact: true,
    main: <CountryAdd />,
  },
  bank: {
    path: "/user/bank",
    name: "Bank",
    exact: true,
    main: <Bank />,
  },
  bank_edit: {
    path: "/user/bank/:id/edit",
    name: "Bank Edit",
    exact: false,
    main: <BankEdit />,
  },
  bank_add: {
    path: "/user/bank/add",
    name: "Bank Add",
    exact: true,
    main: <BankAdd />,
  },
  currency: {
    path: "/setting/currency",
    name: "Currency",
    exact: true,
    main: <Currency />,
  },
  currency_edit: {
    path: "/setting/currency/:id/edit",
    name: "Currency Edit",
    exact: false,
    main: <CurrencyEdit />,
  },
  currency_add: {
    path: "/setting/currency/add",
    name: "Currency Add",
    exact: true,
    main: <CurrencyAdd />,
  },
  geozone: {
    path: "/setting/geozone",
    name: "GeoZone",
    exact: true,
    main: <GeoZone />,
  },
  geozone_edit: {
    path: "/setting/geozone/:id/edit",
    name: "GeoZone Edit",
    exact: false,
    main: <GeoZoneEdit />,
  },
  geozone_add: {
    path: "/setting/geozone/add",
    name: "GeoZone Add",
    exact: true,
    main: <GeoZoneAdd />,
  },
  location: {
    path: "/setting/location",
    name: "Location",
    exact: true,
    main: <Location />,
  },
  location_edit: {
    path: "/setting/location/:id/edit",
    name: "Location Edit",
    exact: false,
    main: <LocationEdit />,
  },
  location_add: {
    path: "/setting/location/add",
    name: "Location Add",
    exact: true,
    main: <LocationAdd />,
  },
  subzone: {
    path: "/setting/subzone",
    name: "SubZone",
    exact: true,
    main: <SubZone />,
  },
  subzone_edit: {
    path: "/setting/subzone/:id/edit",
    name: "SubZone Edit",
    exact: false,
    main: <SubZoneEdit />,
  },
  subzone_add: {
    path: "/setting/subzone/add",
    name: "SubZone Add",
    exact: true,
    main: <SubZoneAdd />,
  },
  ward: {
    path: "/setting/ward",
    name: "Ward",
    exact: true,
    main: <Ward />,
  },
  ward_edit: {
    path: "/setting/ward/:id/edit",
    name: "Ward Edit",
    exact: false,
    main: <WardEdit />,
  },
  ward_add: {
    path: "/setting/ward/add",
    name: "Ward Add",
    exact: true,
    main: <WardAdd />,
  },
  zone: {
    path: "/setting/zone",
    name: "Zone",
    exact: true,
    main: <Zone />,
  },
  zone_edit: {
    path: "/setting/zone/:id/edit",
    name: "Zone Edit",
    exact: false,
    main: <ZoneEdit />,
  },
  zone_add: {
    path: "/setting/zone/add",
    name: "Zone Add",
    exact: true,
    main: <ZoneAdd />,
  },

  academic_level: {
    path: "/setting/academic-level",
    name: "AcademicLevel",
    exact: true,
    main: <AcademicLevel />,
  },
  academic_level_edit: {
    path: "/setting/academic-level/:id/edit",
    name: "AcademicLevel Edit",
    exact: false,
    main: <AcademicLevelEdit />,
  },
  academic_level_add: {
    path: "/setting/academic-level/add",
    name: "AcademicLevel Add",
    exact: true,
    main: <AcademicLevelAdd />,
  },
};

export default routes;
