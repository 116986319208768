import React from 'react';

const Coupon = React.lazy(() => import('@adminv2/views/marketing/coupon/Coupon'))
const CouponEdit = React.lazy(() => import('@adminv2/views/marketing/coupon/CouponEdit'))
const CouponAdd = React.lazy(() => import('@adminv2/views/marketing/coupon/CouponAdd'))
const Notification = React.lazy(() => import('@adminv2/views/marketing/notification/Notification'))
const NotificationEdit = React.lazy(() => import('@adminv2/views/marketing/notification/NotificationEdit'))
const NotificationAdd = React.lazy(() => import('@adminv2/views/marketing/notification/NotificationAdd'))
const Subscribe = React.lazy(() => import('@adminv2/views/marketing/Subscribe'))

const CustomerCareSchedule = React.lazy(() => import('@adminv2/views/marketing/customer-care-schedule/CustomerCareSchedule'))
const CustomerCareScheduleEdit = React.lazy(() => import('@adminv2/views/marketing/customer-care-schedule/CustomerCareScheduleEdit'))

const routes = {
  coupon : {
      path : '/marketing/coupon',
      name: "Coupon",
      exact : true,
      main : <Coupon />
  },
  coupon_edit : {
      path : '/marketing/coupon/:id/edit',
      name: "Coupon Edit",
      exact : false,
      main : <CouponEdit />
  },
  coupon_add : {
      path : '/marketing/coupon/add',
      name: "Coupon Add",
      exact : true,
      main : <CouponAdd />
  },
  marketing_notification : {
      path : '/marketing/notification',
      name: "Notification",
      exact : true,
      main : <Notification />
  },
  marketing_notification_edit : {
      path : '/marketing/notification/:id/edit',
      name: "Notification Edit",
      exact : false,
      main : <NotificationEdit />
  },
  marketing_notification_add : {
      path : '/marketing/notification/add',
      name: "Notification Add",
      exact : true,
      main : <NotificationAdd />
  },
  subscribe : {
      path : '/marketing/subscribe',
      name: "Subscribe",
      exact : true,
      main : <Subscribe />
  },
  customer_care_schedule : {
    path : '/marketing/customer-care-schedule',
    name: "CustomerCareSchedule",
    exact : true,
    main : <CustomerCareSchedule />
},
customer_care_schedule_edit : {
    path : '/marketing/customer-care-schedule/:id/edit',
    name: "CustomerCareSchedule Edit",
    exact : false,
    main : <CustomerCareScheduleEdit />
},
}


export default routes;
