
import React,{useEffect} from 'react';
import { Image,Form,Dropdown } from 'react-bootstrap';
import { AsyncTypeahead,Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Lang from '@adminv2/system/language'
import APIs from '@adminv2/apis'

import * as Components from '@adminv2/components/index'

function ProductPicker(props) {
  const [search, setSearch] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [products, setProducts] = React.useState(props.products || []);
  const errors = props.errors || {}
  const value = props.value || ''

  const getResults = async(key = '') => {
    setLoading(true)
    let response = await APIs.catalog.searchStatisticProduct(key,props.filter || {})
    if (response.data!=null && Array.isArray(response.data.results)) {
      setProducts(response.data.results)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (products.length == 0) {
      getResults()
    }
  },[]);

  return (
    <div>
      <Form.Label>{props.label ? props.label : Lang.t('Products')}</Form.Label>
      <AsyncTypeahead
        filterBy={() => true}
        id="search-products-input-name"
        isLoading={loading}
        labelKey="name"
        minLength={0}
        onSearch={getResults}
        options={products}
        placeholder={"Search some Products"}
        renderMenuItemChildren={(option, props) => (
          <div className="d-flex">
            <img src={option.image !=null && option.image != '' ? option.image : '/image/box-icon.jpg'} width="40" className="me-2"/>
            <div>
              <div>{option.name}</div>
              <div className="small">{option.model}</div>
            </div>
          </div>
        )}
        onChange={options => {
          if (options[0]!=null) {
            props.onChange(options[0])
          }
        }}
      />
    </div>
  );
};

export default ProductPicker;
