import React from 'react';
import {Pagination, Nav, Form} from 'react-bootstrap';
import {globalState} from '@adminv2/App';

const limits = [20, 50, 100, 500, 1000, 5000];

function PaginationComponent(props) {
  const [page, updatePage] = React.useState(globalState.getState().page || 1);

  const total_page = props.totalPage;
  const setPage = data => {
    updatePage(data);
    globalState.dispatch({type: 'root', data: {page: data}});
    // console.log(globalState.getState().filter)
  };
  const onClickAtPage = data => {
    setPage(data);
    props.onClickAtPage({page: data});
  };
  React.useEffect(() => {
    setPage(props.page)
  },[props.page])
  // console.log(page)
  return (
    <>
      <Nav className="d-flex align-items-center">
        <Pagination className="mb-0">
          <Pagination.First disabled={page == 1} onClick={() => onClickAtPage(1)} />
          <Pagination.Prev disabled={page - 1 < 1} onClick={() => onClickAtPage(page - 1)} />
          <Pagination.Item onClick={() => onClickAtPage(1)} disabled={page == 1}>
            1
          </Pagination.Item>
          {page >= 3 && <Pagination.Ellipsis disabled />}

          {[-1,0,1].map((x,key) => {
            if (page + x >= 2 && page + x <= total_page - 1) {
              return (
                <Pagination.Item
                  onClick={() => onClickAtPage(page + x)}
                  disabled={page == page + x}
                >{page + x}</Pagination.Item>
              )
            }
          })}
          {/* {page >= 3 && page <= total_page - 3 && <Pagination.Item disabled>{page - 1}</Pagination.Item>}
          {page >= 2 && page <= total_page - 2 && <Pagination.Item disabled>{page}</Pagination.Item>}
          {page >= 3 && page <= total_page - 3 && <Pagination.Item disabled>{page + 1}</Pagination.Item>} */}

          {page <= total_page - 3 && <Pagination.Ellipsis disabled />}
          <Pagination.Item disabled={page == total_page} onClick={() => onClickAtPage(total_page)}>
            {total_page}
          </Pagination.Item>
          <Pagination.Next disabled={page + 1 > total_page} onClick={() => onClickAtPage(page + 1)} />
          <Pagination.Last disabled={page == total_page} onClick={() => onClickAtPage(total_page)} />
        </Pagination>
      </Nav>
      {props.showLimitRecord != false && (
        <div className="d-flex justify-content-end ms-2">
          <small className="fw-bold">
            Showing <b className="text-danger">{page}</b> / <b>{total_page}</b> pages
          </small>
          <Form.Select
            className="ms-2"
            size="sm"
            value={globalState.getState().limit || 20}
            onChange={event => {
              globalState.dispatch({type: 'root', data: {limit: event.target.value}});
              setTimeout(() => {
                props.onClickChangeLimit();
              }, 200);
            }}>
            {(Array.isArray(limits) ? limits : []).map((item, key) => (
              <option value={item} key={key}>
                {item}
              </option>
            ))}
          </Form.Select>
        </div>
      )}
    </>
  );
}

export default PaginationComponent;
