import React, {useState, useEffect} from 'react';
import {BrowserRouter, Routes, Route, Navigate, useNavigate} from 'react-router-dom';
import {Form, Breadcrumb, ButtonGroup, Button, Toast, ToastContainer, Card} from 'react-bootstrap';
import {routes, Components, APIs, globalState, Notify, View, Lang} from '@adminv2/App';
import Navigation from '@adminv2/system/navigation';
import {useTranslation} from 'react-i18next';

import {AppContext} from '@adminv2/provider';

const AppContent = () => {
  const currentRoute = routes.getCurrentRoute();
  // Setup navigate, call any where.
  const navigate = useNavigate();
  Navigation.setFunction(navigate);
  // Setup global State, call any where.
  const [state, dispatch] = React.useContext(AppContext);
  globalState.initState(state);
  globalState.initDispatch(dispatch);
  // -----------------------------------------------------------
  const [messageType, setMessageType] = React.useState('danger');
  const [message_title, setMessageTitle] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [showMessageNotify, setShowMessageNotify] = useState(false);
  const [loading, setLoading] = useState(true);
  // Setup Notify, call any where.
  Notify.initShowMessageType(setMessageType);
  Notify.initShowMessageTitle(setMessageTitle);
  Notify.initShowMessage(setMessage);
  Notify.initShowNotify(show => {
    setShowMessageNotify(false);
    setShowMessageNotify(show);
  });
  const [showBackgroundLoading, setShowBackgroundLoading] = useState(false);
  View.initBgLoading(setShowBackgroundLoading);
  const [showOverlayLoading, setShowOverlayLoading] = useState(false);
  View.initOverlayLoading(setShowOverlayLoading);
  // Setup Translation, call any where.
  const [t, i18n] = useTranslation();
  Lang.setTranslationFunction(t);
  Lang.setI18n(i18n);
  // i18n.on('languageChanged', function(lng) {
  //   setShowBackgroundLoading(true)
  //   setShowBackgroundLoading(false)
  // })
  // -----------------------------------------------------------

  // ------------------------------------------
  useEffect(() => {
    // Check out of date accessToken.
    const fetch = async () => {
      if (APIs.isLogged()) {
        setShowBackgroundLoading(true);
        setLoading(true);
        await APIs.syncAuthInfo();
        setShowBackgroundLoading(false);
      }
      setLoading(false);
    };
    fetch();
  }, []);

  useEffect(() => {
    if (currentRoute != null && currentRoute.name != null) {
      document.title = Lang.t(currentRoute.name);
    }
  }, [currentRoute]);

  return (
    <div>
      {!loading && (
        <Routes>
          {Object.keys(routes).map((route_key, key) => {
            return (
              <Route
                key={key}
                path={routes[route_key].path}
                exact={routes[route_key].exact}
                element={
                  <Components.CheckPrivateRoute>
                    {routes[route_key].adminToolKit == null || routes[route_key].adminToolKit == true ? (
                      <>
                        <Components.SideBar />
                        <main className="content">
                          <Components.Header />
                          <div className="load-content">{routes[route_key].main}</div>
                          <Components.Footer />
                        </main>
                      </>
                    ) : (
                      <>{routes[route_key].main}</>
                    )}
                  </Components.CheckPrivateRoute>
                }
              />
            );
          })}
        </Routes>
      )}
      <ToastContainer position="top-end" className="p-3 position-fixed" style={{zIndex: 10000}}>
        <Toast
          bg={messageType}
          onClose={() => setShowMessageNotify(false)}
          show={showMessageNotify}
          delay={3000}
          autohide>
          <Toast.Header>
            {messageType == 'success' && <i className="fa fa-check-circle"></i>}
            {messageType == 'danger' && <i className="fa fa-exclamation-circle"></i>}
            {messageType == 'warning' && <i className="fa fa-exclamation-circle"></i>}
            {messageType == 'info' && <i className="fa fa-info-circle"></i>}
            <strong className="ms-2 me-auto">{message_title}</strong>
          </Toast.Header>
          <Toast.Body className="text-white">{message}</Toast.Body>
        </Toast>
      </ToastContainer>
      <Components.BackgroundLoading show={showBackgroundLoading} />
      <Components.OverlayLoading show={showOverlayLoading} />
    </div>
  );
};

export default AppContent;
