import React from 'react';

const Analytic = React.lazy(() => import('@adminv2/views/setting/Analytic'))
const Bank = React.lazy(() => import('@adminv2/views/setting/bank/Bank'))
const BankEdit = React.lazy(() => import('@adminv2/views/setting/bank/BankEdit'))
const BankAdd = React.lazy(() => import('@adminv2/views/setting/bank/BankAdd'))
const Captcha = React.lazy(() => import('@adminv2/views/setting/Captcha'))
const Dashboard = React.lazy(() => import('@adminv2/views/setting/Dashboard'))
const General = React.lazy(() => import('@adminv2/views/setting/General'))
const Login = React.lazy(() => import('@adminv2/views/setting/Login'))
const Module = React.lazy(() => import('@adminv2/views/setting/Module'))
const Payment = React.lazy(() => import('@adminv2/views/setting/Payment'))
const Logs = React.lazy(() => import('@adminv2/views/setting/Logs'))
const SaleChannel = React.lazy(() => import('@adminv2/views/setting/SaleChannel'))
const MarketingChannel = React.lazy(() => import('@adminv2/views/setting/MarketingChannel'))
const Setting = React.lazy(() => import('@adminv2/views/setting/Setting'))
const DuLieuHeThong = React.lazy(() => import('@adminv2/views/setting/DuLieuHeThong'))
const Shipment = React.lazy(() => import('@adminv2/views/setting/Shipment'))
const ShippingMethod = React.lazy(() => import('@adminv2/views/setting/ShippingMethod'))
const Theme = React.lazy(() => import('@adminv2/views/setting/theme/Theme'))
const ThemeEdit = React.lazy(() => import('@adminv2/views/setting/theme/ThemeEdit'))
const ThemeAdd = React.lazy(() => import('@adminv2/views/setting/theme/ThemeAdd'))
const Total = React.lazy(() => import('@adminv2/views/setting/Total'))

const Store = React.lazy(() => import('@adminv2/views/setting/store/Store'))
const StoreEdit = React.lazy(() => import('@adminv2/views/setting/store/StoreEdit'))
const StoreAdd = React.lazy(() => import('@adminv2/views/setting/store/StoreAdd'))

const routes = {
  setting_analytic : {
      path : '/setting/analytic',
      name: "Analytic",
      exact : true,
      main : <Analytic />
  },
  setting_bank : {
      path : '/setting/bank',
      name: "Bank",
      exact : true,
      main : <Bank />
  },
  setting_bank_edit : {
      path : '/setting/bank/:id/edit',
      name: "Bank Edit",
      exact : false,
      main : <BankEdit />
  },
  setting_bank_add : {
      path : '/setting/bank/add',
      name: "Bank Add",
      exact : true,
      main : <BankAdd />
  },
  setting_captcha : {
      path : '/setting/captcha',
      name: "Captcha",
      exact : true,
      main : <Captcha />
  },
  setting_dashboard : {
      path : '/setting/dashboard',
      name: "Dashboard",
      exact : true,
      main : <Dashboard />
  },
  setting_general : {
      path : '/setting/general',
      name: "General",
      exact : true,
      main : <General />
  },
  setting_dulieuhethong : {
    path : '/setting/dulieuhethong',
    name: "DuLieuHeThong",
    exact : true,
    main : <DuLieuHeThong />
},
  setting_login : {
      path : '/setting/login',
      name: "Login",
      exact : true,
      main : <Login />
  },
  setting_module : {
      path : '/setting/module',
      name: "Module",
      exact : true,
      main : <Module />
  },
  setting_payment : {
      path : '/setting/payment',
      name: "Payment Method",
      exact : true,
      main : <Payment />
  },
  setting_logs : {
    path : '/setting/logs',
    name: "Logs",
    exact : true,
    main : <Logs />
},
  setting_salechannel : {
      path : '/setting/salechannel',
      name: "SaleChannel",
      exact : true,
      main : <SaleChannel />
  },
  setting_marketingchannel : {
      path : '/setting/marketingchannel',
      name: "MarketingChannel",
      exact : true,
      main : <MarketingChannel />
  },
  setting : {
      path : '/setting',
      name: "Setting",
      exact : true,
      main : <Setting />
  },
  setting_shipment : {
      path : '/setting/shipment',
      name: "Shipment",
      exact : true,
      main : <Shipment />
  },
  setting_shipping_method : {
      path : '/setting/shipping',
      name: "Shipping Method",
      exact : true,
      main : <ShippingMethod />
  },
  store : {
      path : '/store',
      name: "Store",
      exact : true,
      main : <Store />
  },
  store_edit : {
      path : '/store/:id/edit',
      name: "Store Edit",
      exact : false,
      main : <StoreEdit />
  },
  store_add : {
      path : '/store/add',
      name: "Store Add",
      exact : true,
      main : <StoreAdd />
  },
  theme : {
      path : '/setting/theme',
      name: "Theme",
      exact : true,
      main : <Theme />
  },
  theme_edit : {
      path : '/setting/theme/:id/edit',
      name: "Theme Edit",
      exact : false,
      main : <ThemeEdit />
  },
  theme_add : {
      path : '/setting/theme/add',
      name: "Theme Add",
      exact : true,
      main : <ThemeAdd />
  },
  setting_total : {
      path : '/setting/total',
      name: "Total",
      exact : true,
      main : <Total />
  },
}


export default routes;
