import React from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";

import { Components, Lang, globalState } from "@adminv2/App";

const FilterBar = React.forwardRef((props, ref) => {
  const data = props.data || {};
  const fields = props.fields || [];
  const [filter, updateFilter] = React.useState(
    globalState.getState().filter || {}
  );
  const [filtering, setFiltering] = React.useState("");
  // Use to update state of this component from another hook.
  const update = (data = {}) => {
    let filts = { ...filter, ...data };
    setFilter(filts);
  };
  const setFilter = (data) => {
    updateFilter(data);
    globalState.dispatch({ type: "root", data: { filter: data } });
  };
  const resetFilter = () => {
    updateFilter({});
    globalState.dispatch({ type: "root", data: { filter: {} } });
  };
  const onFilter = async (data = {}) => {
    setFiltering(true);
    await props.onFilter(data);
    setFiltering(false);
  };
  // Use this from another hook.
  React.useImperativeHandle(ref, () => ({
    update: (data = {}) => {
      update(data);
    },
    setFilter: setFilter,
  }));
  // ----------------------------------------------------------

  const getConfigByCode = (code) => {
    const configs = Array.isArray(props.configs) ? props.configs : [];
    for (let i = 0; i < configs.length; i++) {
      if (configs[i].code == code) {
        return configs[i];
      }
    }
    return {};
  };

  return (
    <>
      <Row>
        {(fields.includes("name") || fields.includes("title")) && (
          <Col xs={12} md={4} lg={3} className="mb-3">
            <Form.Group>
              <Form.Label>
                {getConfigByCode("name") && getConfigByCode("name").title
                  ? getConfigByCode("name").title
                  : Lang.t("Name")}
              </Form.Label>
              <Form.Control
                value={filter.name || ""}
                size="sm"
                type="text"
                placeholder={
                  getConfigByCode("name") && getConfigByCode("name").placeholder
                    ? getConfigByCode("name").placeholder
                    : "Search name"
                }
                onChange={(event) =>
                  setFilter({ ...filter, name: event.target.value })
                }
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    onFilter(filter);
                  }
                }}
              />
            </Form.Group>
          </Col>
        )}
        {fields.includes("chude_id") && (
          <Col xs={12} md={4} lg={3} className="mb-3">
            <Form.Group>
              <Form.Label>Loại</Form.Label>
              <Form.Select
                size="sm"
                value={filter.chude_id}
                onChange={(event) =>
                  setFilter({ ...filter, chude_id: event.target.value })
                }
              >
                <option value=""> -- Tất cả --</option>

                {data.chudes?.map((chude, key) => (
                  <option value={chude.id}>{chude.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        )}
        {fields.includes("customer_id") && (
          <Col xs={12} md={4} lg={3} className="mb-3">
            <Components.CustomerPicker
              size="sm"
              value={filter.customer_id || 0}
              displayValue={filter.customer_name || ''}
              onChange={customer => {
                setFilter({ ...filter, customer_id: customer.id,customer_name: customer.firstname + ' ' + customer.lastname })
              }}
            />
          </Col>
        )}
        {fields.includes("product_category_id") && (
          <Col xs={12} md={4} lg={3} className="mb-3">
            <Components.ProductCategoryPicker
              countryId={filter.product_category_id}
              selectClassName={"form-select-sm"}
              name="product_category_id"
              value={filter.product_category_id}
              onChange={(category) =>
                setFilter({ ...filter, product_category_id: category.id })
              }
            />
          </Col>
        )}
        {fields.includes("news_category_id") && (
          <Col xs={12} md={4} lg={3} className="mb-3">
            <Components.NewsCategoryPicker
              countryId={filter.news_category_id}
              selectClassName={"form-select-sm"}
              name="news_category_id"
              value={filter.news_category_id}
              onChange={(category) =>
                setFilter({ ...filter, news_category_id: category.id })
              }
            />
          </Col>
        )}
        {fields.includes("fo_type") && (
          <Col xs={12} md={4} lg={3} className="mb-3">
            <Form.Group>
              <Form.Label>Loại</Form.Label>
              <Form.Select
                size="sm"
                value={filter.fo_type}
                onChange={(event) =>
                  setFilter({ ...filter, fo_type: event.target.value })
                }
              >
                <option value=""> -- Tất cả --</option>

                {data.types?.map((type, key) => (
                  <option value={type.code}>{type.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        )}
        {fields.includes("fo_category_id") && (
          <Col xs={12} md={4} lg={3} className="mb-3">
            <Components.FloorplanCategoryPicker
              countryId={filter.fo_category_id}
              selectClassName={"form-select-sm"}
              name="fo_category_id"
              value={filter.fo_category_id}
              onChange={(category) =>
                setFilter({ ...filter, fo_category_id: category.id })
              }
            />
          </Col>
        )}
        {fields.includes("rating") && (
          <Col xs={12} md={4} lg={3} className="mb-3">
            <Form.Group>
              <Form.Label>Xếp hạng</Form.Label>
              <Form.Select
                size="sm"
                value={filter.rating}
                onChange={(event) =>
                  setFilter({ ...filter, rating: event.target.value })
                }
              >
                <option value=""> -- Tất cả --</option>
                <option value="1">1 sao</option>
                <option value="2">2 sao</option>
                <option value="3">3 sao</option>
                <option value="4">4 sao</option>
                <option value="5">5 sao</option>
              </Form.Select>
            </Form.Group>
          </Col>
        )}
        {fields.includes("product_types") && (
          <Col xs={12} md={4} lg={3} className="mb-3">
            <Form.Group>
              <Form.Label>Loại</Form.Label>
              <Form.Select
                size="sm"
                value={filter.type}
                onChange={(event) =>
                  setFilter({ ...filter, type: event.target.value })
                }
              >
                <option value=""> -- Tất cả --</option>
                <option value="vat-pham">Vật phẩm</option>
                <option value="khoa-hoc">Khoá học</option>
              </Form.Select>
            </Form.Group>
          </Col>
        )}
        {fields.includes("status") && (
          <Col xs={12} md={4} lg={3} className="mb-3">
            <Form.Group>
              <Form.Label>{Lang.t("Status")}</Form.Label>
              <Form.Select
                size="sm"
                value={filter.status != "" ? filter.status : ""}
                onChange={(event) =>
                  setFilter({ ...filter, status: event.target.value })
                }
              >
                <option value=""> -- Trạng thái --</option>
                {data.product_statuses ? (
                  data.product_statuses?.map((item) => (
                    <option value={item.code}>{item.name}</option>
                  ))
                ) : (
                  <>
                    <option value={1}>Bật</option>
                    <option value={0}>Tắt</option>
                  </>
                )}
              </Form.Select>
            </Form.Group>
          </Col>
        )}
        {fields.includes("geo_zone_id") && (
          <Col xs={12} md={4} lg={3} className="mb-3">
            <Components.GeoZonePicker
              selectClassName={"form-select-sm"}
              name="geo_zone_id"
              value={filter.geo_zone_id}
              onChange={(value) => setFilter({ ...filter, geo_zone_id: value })}
            />
          </Col>
        )}
        {fields.includes("country_id") && (
          <Col xs={12} md={4} lg={3} className="mb-3">
            <Components.CountryPicker
              selectClassName={"form-select-sm"}
              name="country_id"
              value={filter.country_id}
              onChange={(value) => setFilter({ ...filter, country_id: value })}
            />
          </Col>
        )}
        {fields.includes("zone_id") && (
          <Col xs={12} md={4} lg={3} className="mb-3">
            <Components.ZonePicker
              countryId={filter.country_id}
              selectClassName={"form-select-sm"}
              name="zone_id"
              value={filter.zone_id}
              onChange={(value) => setFilter({ ...filter, zone_id: value })}
            />
          </Col>
        )}
        {fields.includes("sub_zone_id") && (
          <Col xs={12} md={4} lg={3} className="mb-3">
            <Components.SubZonePicker
              zoneId={filter.zone_id}
              selectClassName={"form-select-sm"}
              name="sub_zone_id"
              value={filter.sub_zone_id}
              onChange={(value) => setFilter({ ...filter, sub_zone_id: value })}
            />
          </Col>
        )}
        {fields.includes("ward_id") && (
          <Col xs={12} md={4} lg={3} className="mb-3">
            <Components.WardPicker
              subZoneId={filter.sub_zone_id}
              selectClassName={"form-select-sm"}
              name="ward_id"
              value={filter.ward_id}
              onChange={(value) => setFilter({ ...filter, ward_id: value })}
            />
          </Col>
        )}
        {props.children}
        {fields.length > 0 && (
          <Col xs={12} md={3} className="d-flex align-items-end mb-3">
            <ButtonGroup className="w-100">
              <Button
                variant="primary"
                size="sm"
                disabled={filtering}
                className="rounded-0 w-100"
                onClick={() => onFilter(filter)}
              >
                {filtering ? (
                  <span>
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </span>
                ) : (
                  <span>
                    <i className="fa fa-search"></i> {Lang.t("Filter")}
                  </span>
                )}
              </Button>
              {filter != null && Object.keys(filter).length > 0 && (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>{Lang.t("Refresh")}</Tooltip>}
                >
                  <Button
                    variant="danger"
                    size="sm"
                    disabled={filtering}
                    className="rounded-0"
                    onClick={() => {
                      resetFilter();
                      onFilter({});
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </Button>
                </OverlayTrigger>
              )}
            </ButtonGroup>
          </Col>
        )}
      </Row>
    </>
  );
});

export default FilterBar;
