
import React from 'react';
import { Image } from 'react-bootstrap';

import ReactLogo from "@adminv2/assets/img/technologies/react-logo-transparent.svg";

function OverlayLoading(props) {

  const { show } = props;
  if (!show) {
    return(<></>)
  }
  return (
    <div className={'modal-backdrop show d-flex justify-content-center align-items-center'}>
      <Image className="loader-element animate__animated animate__jackInTheBox" src={ReactLogo} height={40} />
    </div>
  );
};

export default OverlayLoading;
