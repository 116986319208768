import React from 'react';

const Lazada = React.lazy(() => import('@adminv2/views/salechannel/Lazada'))
const Shopee = React.lazy(() => import('@adminv2/views/salechannel/Shopee'))
const Tiki = React.lazy(() => import('@adminv2/views/salechannel/Tiki'))
const Website = React.lazy(() => import('@adminv2/views/salechannel/Website'))
const Zalo = React.lazy(() => import('@adminv2/views/salechannel/Zalo'))

const routes = {
  salechannel_Lazada : {
      path : '/setting/salechannel/lazada',
      name: "Lazada",
      exact : true,
      main : <Lazada />
  },
  salechannel_Shopee : {
      path : '/setting/salechannel/shopee',
      name: "Shopee",
      exact : true,
      main : <Shopee />
  },
  salechannel_Tiki : {
      path : '/setting/salechannel/tiki',
      name: "Tiki",
      exact : true,
      main : <Tiki />
  },
  salechannel_Website : {
      path : '/setting/salechannel/website',
      name: "Website",
      exact : true,
      main : <Website />
  },
  salechannel_Zalo : {
      path : '/setting/salechannel/zalo',
      name: "Zalo",
      exact : true,
      main : <Zalo />
  },
}


export default routes;
