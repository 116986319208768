
import React,{useEffect} from 'react';
import { Image,Form } from 'react-bootstrap';

import Dragula from 'react-dragula';
import "react-dragula/dist/dragula.css";

import Lang from '@adminv2/system/language'
import APIs from '@adminv2/apis'
import * as Components from '@adminv2/components/index'

function MultiFAQsPicker(props) {
  const [loading, setLoading] = React.useState(false);
  const [full_faqs, setFullFAQs] = React.useState([]);

  const getFAQs = async() => {
    setLoading(true)
    let response = await APIs.faqs.getList({
      filter: {
        ids: props.value
      }
    })
    if (response.data!=null && Array.isArray(response.data.results)) {
      let cates = []
      for (var i = 0; i < response.data.results.length; i++) {
        if (Array.isArray(props.value) && props.value.includes(response.data.results[i].id)) {
          cates.push(response.data.results[i])
        }
      }
      setFullFAQs(cates)
    }
    setLoading(false)
  }

  const dragulaDecorator = (componentBackingInstance) => {
    if (componentBackingInstance) {
      Dragula([componentBackingInstance], {});
    }
  };

  useEffect(() => {
    getFAQs()
  },[]);

  return (
    <>
      <Components.FAQsPicker
        onChange={faq => {
          let faqs = props.value || []
          let fc = full_faqs
          if (!faqs.includes(faq.id)) {
            faqs.push(faq.id)
            fc.push(faq)
          }
          props.onChange(faqs)
          setFullFAQs(fc)
        }}
      />
      <div className="well bg-light border mb-3" ref={dragulaDecorator}>
        {(Array.isArray(full_faqs) ? full_faqs : []).map((faq, key) => {
          return (
            <div className="well-item">
              <span className="handle ps-2 pe-2"><i className="fa fa-arrows-alt"></i></span>
              <a onClick={() => {
                  let faqs = props.value || []
                  let fc = full_faqs
                  if (faqs.includes(faq.id)) {
                    faqs.splice(faqs.indexOf(faq.id), 1)
                    fc.splice(fc.indexOf(faq), 1)
                  }
                  props.onChange(faqs)
                  setFullFAQs(fc)
                }}><i className="fa fa-minus-circle"></i></a>
              {faq.title}
            </div>
          )
        })}
      </div>
    </>
  );
};

export default MultiFAQsPicker;
