import globalState from '@adminv2/system/globalState'
import GLOBAL from "@adminv2/app.json";
import axios from "axios";
const Todos = {
  search : (key = '') => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/search?filter[name]=' + key
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  create : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  edit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  delete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  deletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },

  // Role
  getRoleList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/role/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getRoleForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/role/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  roleCreate : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/role/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  roleEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/role/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  roleDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/role/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  roleDeletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/role/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },

}
export default Todos
