
import React,{useEffect} from 'react';
import { Image,Form,Dropdown } from 'react-bootstrap';

import Lang from '@adminv2/system/language'
import APIs from '@adminv2/apis'

import * as Components from '@adminv2/components/index'

function UserPicker(props) {
  const [search, setSearch] = React.useState(props.valueDisplay || '');

  const [loading, setLoading] = React.useState(false);
  const [users, setUsers] = React.useState(props.users || []);
  const inputName = props.name || '';
  const errors = props.errors || {}
  const value = props.value || ''
  let inputSearch = React.useRef('')

  const getUsers = async(key = '') => {
    setLoading(true)
    let response = await APIs.user.search(key)
    if (response.data!=null && Array.isArray(response.data.results)) {
      setUsers(response.data.results)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (users.length == 0) {
      getUsers()
    }
  },[]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Form.Group className={props.className || ''}>
      {
        props.title &&
        <Form.Label>{props.title}</Form.Label>
      }
      <Form.Control
        ref={inputSearch}
        value={search || ''}
        onChange={(event) => {
          setSearch(event.target.value)
          getUsers(event.target.value)
        }}
        onClick={onClick}
      />
      <span className="position-absolute right-2 top-2"><i className="fa fa-angle-down"></i></span>
    </Form.Group>
  ));

  return (
    <Dropdown>
      <Dropdown.Toggle  as={CustomToggle}>
      </Dropdown.Toggle>

      <Dropdown.Menu  className="p-0 rounded-0">
        {
          loading &&
          <Components.LoadingIndicator/>
        }
        {(!loading && Array.isArray(users) ? users : []).map((user, key) => (
          <Dropdown.Item onClick={() => {
              setSearch(user.name)
              props.onChange(user)
              inputSearch.current.blur()
            }}>
            <div className="d-flex">
              <img src={user.image !=null && user.image != '' ? user.image : '/image/anonymous-icon.jpg'} width="40" className="me-2"/>
              <div>
                <div className="fw-bold">{user.name}</div>
                <div className="small">{user.email}</div>
              </div>
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>

  );
};

export default UserPicker;
