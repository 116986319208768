
import React,{useEffect} from 'react';
import { Image,Form } from 'react-bootstrap';

import Lang from '@adminv2/system/language'
import APIs from '@adminv2/apis'

const Todos = APIs.localisation;

function ZonePicker(props) {
  const [loading, setLoading] = React.useState(false);
  const [zones, setZones] = React.useState(props.zones || []);
  const inputName = props.name || '';
  const countryId = props.countryId || ''
  const errors = props.errors || {}
  const value = props.value || ''

  const getZones = async() => {
    setLoading(true)
    let response = await Todos.getZones(countryId)
    setZones(response)
    setLoading(false)
  }

  useEffect(() => {
    if (props.countryId) {
      getZones()
    }else{
      setZones([])
    }
  },[props.countryId]);

  return (
    <Form.Group className={props.className || ""}>
      <Form.Label>{Lang.t('Zone')}</Form.Label>
        <Form.Select name={inputName} value={value} disabled={!countryId || loading} className={ props.selectClassName + ' ' + (errors!=null && errors[inputName]!=null ? 'is-invalid' : '')} onChange={event => props.onChange(event.target.value)}>
          <option value=""> -- {Lang.t('Select Zone')} -- </option>
            {(Array.isArray(zones) ? zones : []).map((zone, key) => {
              return(
                <option value={zone.id}>{zone.name}</option>
              )
            })}
        </Form.Select>
        {
          errors[inputName] &&
          <div className="invalid-feedback">
            {errors[inputName]!=null ? errors[inputName][0] : ''}
          </div>
        }
    </Form.Group>
  );
};

export default ZonePicker;
