
import React,{useEffect} from 'react';
import { Image,Form,Dropdown } from 'react-bootstrap';
import { AsyncTypeahead,Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Lang from '@adminv2/system/language'
import APIs from '@adminv2/apis'

import * as Components from '@adminv2/components/index'

function AttributeValuePicker(props) {
  const [inputText, setInputText] = React.useState(props.displayValue || '');
  const [loading, setLoading] = React.useState(false);
  const [attribute_values, setProductAttributeValues] = React.useState(props.attribute_values || []);
  const errors = props.errors || {}
  const value = props.value || ''

  const getProductAttributeValues = async(key = '') => {
    props.onChangeText(key)
    if (props.attributeId) {
      setLoading(true)
      let response = await APIs.catalog.searchStatisticProductAttributeValue(props.attributeId, key)
      if (response.data!=null && Array.isArray(response.data)) {
        setProductAttributeValues(response.data)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    // if (attribute_values.length == 0) {
    //   getProductAttributeValues()
    // }
  },[]);

  return (
    <AsyncTypeahead
      filterBy={() => true}
      id="search-attribute-value-input-name"
      isLoading={loading}
      labelKey={option => `${option.name}`}
      minLength={0}
      onFocus={() => getProductAttributeValues('')}
      onSearch={getProductAttributeValues}
      options={attribute_values}
      defaultSelected={[{
        name: props.displayValue || ''
      }]}
      useCache={false}
      renderMenuItemChildren={(option, props) => (
        <div>
          {option.name}
        </div>
      )}
      onChange={options => {
        if (options[0]!=null) {
          props.onChange(options[0])
        }
      }}
    />
  );
};

export default AttributeValuePicker;
