import globalState from '@adminv2/system/globalState'
import Navigation from '@adminv2/system/navigation'
import routes from '@adminv2/routes'
import GLOBAL from "@adminv2/app.json";
import axios from "axios";
const Todos = {
  renderCategoryStructureSelectOptions : (category, level = 0) => {
    return(
      <>
        <option value={category.id}>
          {Array(level).fill(level).map((key) => {
            return '\u00A0\u00A0\u00A0'
          })}
          {category.name}
        </option>
        {(Array.isArray(category.children) ? category.children : []).map((children, key) => {
          return Todos.renderCategoryStructureSelectOptions(children, level + 1)
        })}
      </>
    )
  },
  search : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/floorplan-objects/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getList : (data) => {
    delete data.filter.type
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-object/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  
  getForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-object/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getInfo : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-object/' + id + '/info'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  create : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-object/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  edit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-object/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  delete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-object/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  deletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-object/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  objectAttributeCreate : (object_id,data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-object/' + object_id + '/attributes/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  categoryStructure : () => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/floorplan-category/structure'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getCategoryList : (data) => {
    delete data.filter.type
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-category/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  
  getCategoryForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-category/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  
  categoryCreate : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-category/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  categoryEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-category/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  categoryDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-category/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  categoryDeletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-category/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  // Attribute Group
  getAttributeGroupStructure : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/floorplan-attributes/structure'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getAttributeGroupList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-attribute-group/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getAttributeGroupForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-attribute-group/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  attributeGroupCreate : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-attribute-group/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getAttributeGroupInfo : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-attribute-group/' + id + '/info'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  attributeGroupEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-attribute-group/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  attributeGroupDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-attribute-group/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  attributeGroupDeletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-attribute-group/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  // Attribute
  searchAttribute : (data) => {
    delete data.filter.type
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/floorplan-attributes/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  // Attribute
  getAttributeList : (data) => {
    // delete data.filter.type
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-attribute/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getAttributeForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-attribute/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  attributeCreate : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-attribute/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  
  attributeEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-attribute/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  attributeDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-attribute/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  // Loai cong trinh
  getLoaiCongTrinhList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/loai-cong-trinh/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getLoaiCongTrinhForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/loai-cong-trinh/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getLoaiCongTrinhInfo : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/loai-cong-trinh/' + id + '/info'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  loaiCongTrinhCreate : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/loai-cong-trinh/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  loaiCongTrinhEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/loai-cong-trinh/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  loaiCongTrinhDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/loai-cong-trinh/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  loaiCongTrinhDeletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/loai-cong-trinh/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  // Vai tro luan giai
  getVaiTroList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-vai-tro/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getVaiTroForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-vai-tro/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getVaiTroInfo : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-vai-tro/' + id + '/info'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  vaiTroCreate : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-vai-tro/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  vaiTroEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-vai-tro/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  vaiTroDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-vai-tro/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  vaiTroDeletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-vai-tro/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  
  // Loai luan giai
  getLuanGiaiTypeList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-type/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getLuanGiaiTypeForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-type/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getLuanGiaiTypeInfo : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-type/' + id + '/info'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  luanGiaiTypeCreate : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-type/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  luanGiaiTypeEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-type/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  luanGiaiTypeDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-type/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  luanGiaiTypeDeletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-type/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  // Luan giai
  searchLuanGiai : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/floorplan-luan-giai/search'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getLuanGiaiList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getLuanGiaiForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getLuanGiaiInfo : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai/' + id + '/info'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  luanGiaiCreate : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  luanGiaiEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  luanGiaiDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  luanGiaiDeletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  exportLuanGiai: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/floorplan-luan-giai/export";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  importLuanGiai: (file) => {
    var formData = new FormData();
    formData.append('import', file);
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/floorplan-luan-giai/import";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url,formData)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  searchHoaGiai : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/floorplan-hoa-giai/search'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  // Hoa giai
  getHoaGiaiList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-hoa-giai/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getHoaGiaiForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-hoa-giai/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  
  hoaGiaiCreate : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-hoa-giai/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  hoaGiaiEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-hoa-giai/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  hoaGiaiDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-hoa-giai/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  hoaGiaiDeletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-hoa-giai/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getCombineCasesFromAttributes: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/all-combine-cases-from-attributes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getFullCombineCasesFromAttributes: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/full-combine-cases-from-attributes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getLuanGiaiDashboard: () => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/dashboard'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  // 
  getLuanGiaiCaseList : (luan_giai_id,data={}) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/' + luan_giai_id + '/case_list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getCaseList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getLuanGiaiCase : (data = {}) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/info'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getLuanGiaiCaseForm : (data = {}) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/form'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getLuanGiaiCaseMultipleForm : (data = {}) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/multiple-form'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  checkLuanGiaiCase : (data = {}) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/check'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  addLuanGiaiCase : (data = {}) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  editLuanGiaiCase : (id,data = {}) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  editLuanGiaiCases : (cases = []) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/updates'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{
        cases: cases
      }).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getLuanGiaiCaseInfo : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/' + id + '/info'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  deleteLuanGiaiCase: (case_id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/' + case_id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  deletesLuanGiaiCase: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  updateLuanGiaiCase : (id,data = {}) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  addLuanGiaiToLuanGiaiCase : (id,data = {}) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/' + id + '/luan-giai-add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  deleteLuanGiaiCaseLuanGiai: (case_id,luan_giai_id,dai_nguyen=0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/' + case_id + '/luangiai/' + luan_giai_id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{dai_nguyen: dai_nguyen}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getCaseOrders : (case_id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/' + case_id + '/orders'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  addHoaGiaiCase : (id,data = {}) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/' + id + '/hoa-giai-add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  deleteLuanGiaiCaseHoaGiai: (case_id,hoa_giai_id,dai_nguyen = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-luan-giai-case/' + case_id + '/hoagiai/' + hoa_giai_id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{dai_nguyen: dai_nguyen}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  // Luan giai
  getDiemTuList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/diem-tu/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getDiemTuForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/diem-tu/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getDiemTuInfo : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/diem-tu/' + id + '/info'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  diemTuCreate : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/diem-tu/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  diemTuEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/diem-tu/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  diemTuDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/diem-tu/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  diemTuDeletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/diem-tu/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  // Hinh the
  hinhTheSearch : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/floorplan-hinh-the/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getHinhTheList : (data) => {
    delete data.filter.type
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-hinh-the/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  
  getHinhTheForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-hinh-the/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getHinhTheInfo : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-hinh-the/' + id + '/info'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  hinhTheCreate : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-hinh-the/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  hinhTheEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-hinh-the/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  hinhTheDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-hinh-the/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  hinhTheDeletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-hinh-the/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  searchHinhTheAttribute : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/floorplan-hinh-the-attributes/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getHinhTheAttributeForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-hinh-the-attribute/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  hinhTheAttributeCreate : (hinh_the_id,data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-hinh-the/' + hinh_the_id + '/attributes/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  
  hinhTheAttributeEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-hinh-the-attribute/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  hinhTheAttributeDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-hinh-the-attribute/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  // -------------------
  getTextureList : (data) => {
    delete data.filter.type
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-texture/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  
  getTextureForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-texture/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  
  textureCreate : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-texture/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  textureEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-texture/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  textureDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-texture/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  textureDeletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/floorplan-texture/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
}
export default Todos
