import React from 'react';
import {routes} from '@adminv2/App'

function DailySales( props ) {
  const info = props.info
  return (
      <div {...props}>

      </div>
  );
}

export default DailySales;
