import GLOBAL from "@adminv2/app.json";
import globalState from "@adminv2/system/globalState";
import axios from "axios";
const Todos = {
  getGeozones: () => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/statistics/geozones";
      axios
        .get(url)
        .then((response) => {
          if (Array.isArray(response.data.geozones)) {
            resolve(response.data.geozones);
          } else {
            resolve([]);
          }
        })
        .catch(function (error) {
          resolve([]);
        });
    });
  },
  getCountries: () => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/statistics/countries";
      axios
        .get(url)
        .then((response) => {
          if (Array.isArray(response.data.countries)) {
            resolve(response.data.countries);
          } else {
            resolve([]);
          }
        })
        .catch(function (error) {
          resolve([]);
        });
    });
  },
  getZones: (country_id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/statistics/countries/" + country_id;
      axios
        .get(url)
        .then((response) => {
          if (Array.isArray(response.data.zones)) {
            resolve(response.data.zones);
          } else {
            resolve([]);
          }
        })
        .catch(function (error) {
          resolve([]);
        });
    });
  },
  getZoneInfo: (zone_id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/statistics/zones/" + zone_id;
      axios
        .get(url)
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            resolve({});
          }
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getSubZones: (zone_id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/statistics/zones/" + zone_id;
      axios
        .get(url)
        .then((response) => {
          if (Array.isArray(response.data.subzones)) {
            resolve(response.data.subzones);
          } else {
            resolve([]);
          }
        })
        .catch(function (error) {
          resolve([]);
        });
    });
  },
  getWards: (sub_zone_id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/statistics/subzones/" + sub_zone_id;
      axios
        .get(url)
        .then((response) => {
          if (Array.isArray(response.data.wards)) {
            resolve(response.data.wards);
          } else {
            resolve([]);
          }
        })
        .catch(function (error) {
          resolve([]);
        });
    });
  },
  getStreets: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/statistics/streets";
      axios
        .post(url, data)
        .then((response) => {
          if (Array.isArray(response.data.streets)) {
            resolve(response.data.streets);
          } else {
            resolve([]);
          }
        })
        .catch(function (error) {
          resolve([]);
        });
    });
  },
  // Admin API
  getCountryList: (data) => {
    // console.log(data)
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/country/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getCountryForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/country/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  countryCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/country/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  countryEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/country/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  countryDelete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/country/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  countryDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/country/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  //
  getGeoZoneList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/geozone/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getGeoZoneForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/geozone/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  geoZoneCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/geozone/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  geoZoneEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/geozone/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  geoZoneDelete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/geozone/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  geoZoneDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/geozone/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  getZoneList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/zone/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getZoneForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/zone/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  zoneCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/zone/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  zoneEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/zone/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  zoneDelete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/zone/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  zoneDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/zone/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  getSubZoneList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/subzone/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getSubZoneForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/subzone/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  subZoneCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/subzone/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  subZoneEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/subzone/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  subZoneDelete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/subzone/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  subZoneDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/subzone/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  getWardList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/ward/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getWardForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/ward/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  wardCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/ward/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  wardEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/ward/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  wardDelete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/ward/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  wardDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/ward/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  getStreetList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/street/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getStreetForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/street/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  streetCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/statistics/streets/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  streetEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/street/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  streetDelete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/street/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  streetDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/street/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getBankList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/bank/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getBankForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/bank/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  bankCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/bank/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  bankEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/bank/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  bankDelete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/bank/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  bankDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/bank/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  getCurrencyList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/currency/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getCurrencyForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/currency/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  currencyCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/currency/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  currencyEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/currency/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  currencyDelete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/currency/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  currencyDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/currency/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getLocationList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/location/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getLocationForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/location/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  locationCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/location/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  locationEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/location/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  locationDelete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/location/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  locationDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/location/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getAcademicLevelList: (data) => {
    // console.log(data)
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/academic-level/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getAcademicLevelForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/academic-level/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  academicLevelCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/academic-level/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  academicLevelEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/academic-level/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  academicLevelDelete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/academic-level/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  academicLevelDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/academic-level/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
};
export default Todos;
