import globalState from '@adminv2/system/globalState'
import GLOBAL from "@adminv2/app.json";
import axios from "axios";
const Todos = {
  getList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/information/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/information/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  create : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/information/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  edit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/information/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  delete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/information/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  deletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/information/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
}
export default Todos
