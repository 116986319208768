import React from 'react';

const Layout = React.lazy(() => import('@adminv2/views/layout/Layout'))
const Page = React.lazy(() => import('@adminv2/views/layout/page/Page'))
const PageEdit = React.lazy(() => import('@adminv2/views/layout/page/PageEdit'))
const PageAdd = React.lazy(() => import('@adminv2/views/layout/page/PageAdd'))
const Banner = React.lazy(() => import('@adminv2/views/layout/banner/Banner'))
const BannerEdit = React.lazy(() => import('@adminv2/views/layout/banner/BannerEdit'))
const BannerAdd = React.lazy(() => import('@adminv2/views/layout/banner/BannerAdd'))

const routes = {
  layout : {
      path : '/info/layout',
      name: "Layout",
      exact : true,
      main : <Layout />
  },
  page : {
      path : '/info/page',
      name: "Page",
      exact : true,
      main : <Page />
  },
  page_edit : {
      path : '/info/page/:id/edit',
      name: "Page Edit",
      exact : false,
      main : <PageEdit />
  },
  page_add : {
      path : '/info/page/add',
      name: "Page Add",
      exact : true,
      main : <PageAdd />
  },
  banner : {
      path : '/info/banner',
      name: "Banner",
      exact : true,
      main : <Banner />
  },
  banner_edit : {
      path : '/info/banner/:id/edit',
      name: "Banner Edit",
      exact : false,
      main : <BannerEdit />
  },
  banner_add : {
      path : '/info/banner/add',
      name: "Banner Add",
      exact : true,
      main : <BannerAdd />
  },
}


export default routes;
