import globalState from "@adminv2/system/globalState";
import GLOBAL from "@adminv2/app.json";
import axios from "axios";
const Todos = {
  // Nghề nghiệp
  getConfigOrderKyMonList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/chu-de-xem-ky-mon/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getConfigOrderKyMonForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/chu-de-xem-ky-mon/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  createConfigOrderKyMon: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/chu-de-xem-ky-mon/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  editConfigOrderKyMon: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/chu-de-xem-ky-mon/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  deleteConfigOrderKyMon: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/chu-de-xem-ky-mon/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  deletesConfigOrderKyMon: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/chu-de-xem-ky-mon/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
};
export default Todos;
