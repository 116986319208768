import React from "react";

const Product = React.lazy(() =>
  import("@adminv2/views/catalog/product/Product")
);
const ProductEdit = React.lazy(() =>
  import("@adminv2/views/catalog/product/ProductEdit")
);
const ProductAdd = React.lazy(() =>
  import("@adminv2/views/catalog/product/ProductAdd")
);
const Category = React.lazy(() =>
  import("@adminv2/views/catalog/category/Category")
);
const CategoryEdit = React.lazy(() =>
  import("@adminv2/views/catalog/category/CategoryEdit")
);
const CategoryAdd = React.lazy(() =>
  import("@adminv2/views/catalog/category/CategoryAdd")
);
const AttributeGroup = React.lazy(() =>
  import("@adminv2/views/catalog/attribute/AttributeGroup")
);
const AttributeGroupInfo = React.lazy(() =>
  import("@adminv2/views/catalog/attribute/AttributeGroupInfo")
);
const AttributeGroupEdit = React.lazy(() =>
  import("@adminv2/views/catalog/attribute/AttributeGroupEdit")
);
const AttributeGroupAdd = React.lazy(() =>
  import("@adminv2/views/catalog/attribute/AttributeGroupAdd")
);
const Attribute = React.lazy(() =>
  import("@adminv2/views/catalog/attribute/Attribute")
);
const Manufacturer = React.lazy(() =>
  import("@adminv2/views/catalog/manufacturer/Manufacturer")
);
const ManufacturerEdit = React.lazy(() =>
  import("@adminv2/views/catalog/manufacturer/ManufacturerEdit")
);
const ManufacturerAdd = React.lazy(() =>
  import("@adminv2/views/catalog/manufacturer/ManufacturerAdd")
);
const Option = React.lazy(() => import("@adminv2/views/catalog/Option"));
const Review = React.lazy(() => import("@adminv2/views/catalog/Review"));

const SubscriptionPlan = React.lazy(() =>
  import("@adminv2/views/catalog/subscription_plan/SubscriptionPlan")
);
const SubscriptionPlanEdit = React.lazy(() =>
  import("@adminv2/views/catalog/subscription_plan/SubscriptionPlanEdit")
);
const SubscriptionPlanAdd = React.lazy(() =>
  import("@adminv2/views/catalog/subscription_plan/SubscriptionPlanAdd")
);

const Batdongsan = React.lazy(() =>
  import("@adminv2/views/catalog/bat-dong-san/Batdongsan")
);
const BatdongsanEdit = React.lazy(() =>
  import("@adminv2/views/catalog/bat-dong-san/BatdongsanEdit")
);
const BatdongsanAdd = React.lazy(() =>
  import("@adminv2/views/catalog/bat-dong-san/BatdongsanAdd")
);
const BatdongsanProject = React.lazy(() =>
  import("@adminv2/views/catalog/bat-dong-san/BatdongsanProject")
);
const BatdongsanCategory = React.lazy(() =>
  import("@adminv2/views/catalog/bat-dong-san/BatdongsanCategory")
);
const BatdongsanCategoryEdit = React.lazy(() =>
  import("@adminv2/views/catalog/bat-dong-san/BatdongsanCategoryEdit")
);
const BatdongsanCategoryAdd = React.lazy(() =>
  import("@adminv2/views/catalog/bat-dong-san/BatdongsanCategoryAdd")
);
const BatdongsanProjectEdit = React.lazy(() =>
  import("@adminv2/views/catalog/bat-dong-san/BatdongsanProjectEdit")
);
const BatdongsanProjectAdd = React.lazy(() =>
  import("@adminv2/views/catalog/bat-dong-san/BatdongsanProjectAdd")
);

const Khoahoc = React.lazy(() =>
  import("@adminv2/views/catalog/khoa-hoc/Khoahoc")
);
const KhoahocEdit = React.lazy(() =>
  import("@adminv2/views/catalog/khoa-hoc/KhoahocEdit")
);
const KhoahocAdd = React.lazy(() =>
  import("@adminv2/views/catalog/khoa-hoc/KhoahocAdd")
);
const KhoahocReport = React.lazy(() =>
  import("@adminv2/views/catalog/khoa-hoc/KhoahocReport")
);
const KhoahocCategory = React.lazy(() =>
  import("@adminv2/views/catalog/khoa-hoc/KhoahocCategory")
);
const KhoahocCategoryEdit = React.lazy(() =>
  import("@adminv2/views/catalog/khoa-hoc/KhoahocCategoryEdit")
);
const KhoahocCategoryAdd = React.lazy(() =>
  import("@adminv2/views/catalog/khoa-hoc/KhoahocCategoryAdd")
);
const KhoahocMember = React.lazy(() =>
  import("@adminv2/views/catalog/khoa-hoc/KhoahocMember")
);

const KhoahocLive = React.lazy(() =>
  import("@adminv2/views/catalog/khoa-hoc/KhoahocLive")
);
const KhoahocLiveEdit = React.lazy(() =>
  import("@adminv2/views/catalog/khoa-hoc/KhoahocLiveEdit")
);
const KhoahocLiveAdd = React.lazy(() =>
  import("@adminv2/views/catalog/khoa-hoc/KhoahocLiveAdd")
);


const ProductReview = React.lazy(() => 
  import("@adminv2/views/catalog/product-review/ProductReview")
);
const ProductReviewEdit = React.lazy(() => 
  import("@adminv2/views/catalog/product-review/ProductReviewEdit")
);
const ProductReviewAdd = React.lazy(() => 
  import("@adminv2/views/catalog/product-review/ProductReviewAdd")
);

const routes = {
  product: {
    path: "/product",
    name: "Product",
    exact: true,
    main: <Product />,
  },
  product_edit: {
    path: "/product/:id/edit",
    name: "product Edit",
    exact: false,
    main: <ProductEdit />,
  },
  product_add: {
    path: "/product/add",
    name: "product Add",
    exact: true,
    main: <ProductAdd />,
  },

  product_category: {
    path: "/pc",
    name: "Product Category",
    exact: true,
    main: <Category />,
  },
  product_category_edit: {
    path: "/pc/:id/edit",
    name: "Product Category Edit",
    exact: false,
    main: <CategoryEdit />,
  },
  product_category_add: {
    path: "/pc/add",
    name: "Product Category Add",
    exact: true,
    main: <CategoryAdd />,
  },
  product_attribute_group: {
    path: "/attribute-group",
    name: "Attribute Group",
    exact: true,
    main: <AttributeGroup />,
  },
  product_attribute_group_info: {
    path: "/attribute-group/:id/info",
    name: "Attribute Group Info",
    exact: false,
    main: <AttributeGroupInfo />,
  },
  product_attribute_group_edit: {
    path: "/attribute-group/:id/edit",
    name: "Attribute Group Edit",
    exact: false,
    main: <AttributeGroupEdit />,
  },
  product_attribute_group_add: {
    path: "/attribute-group/add",
    name: "Attribute Group Add",
    exact: true,
    main: <AttributeGroupAdd />,
  },
  product_attribute: {
    path: "/attribute",
    name: "Attribute",
    exact: true,
    main: <Attribute />,
  },
  product_option: {
    path: "/option",
    name: "Option",
    exact: true,
    main: <Option />,
  },
  product_review: {
    path: "/review",
    name: "Review",
    exact: true,
    main: <Review />,
  },
  manufacturer: {
    path: "/manufacturer",
    name: "Manufacturer",
    exact: true,
    main: <Manufacturer />,
  },
  manufacturer_edit: {
    path: "/manufacturer/:id/edit",
    name: "Manufacturer Edit",
    exact: false,
    main: <ManufacturerEdit />,
  },
  manufacturer_add: {
    path: "/manufacturer/add",
    name: "Manufacturer Add",
    exact: true,
    main: <ManufacturerAdd />,
  },
  subscription_plan: {
    path: "/subscriptionplan",
    name: "Subscription Plan",
    exact: true,
    main: <SubscriptionPlan />,
  },
  subscription_plan_edit: {
    path: "/subscriptionplan/:id/edit",
    name: "Subscription Plan Edit",
    exact: false,
    main: <SubscriptionPlanEdit />,
  },
  subscription_plan_add: {
    path: "/subscriptionplan/add",
    name: "Subscription Plan Add",
    exact: true,
    main: <SubscriptionPlanAdd />,
  },
  batdongsan: {
    path: "/batdongsan",
    name: "Bất động sản",
    exact: true,
    main: <Batdongsan />,
  },
  batdongsan_edit: {
    path: "/batdongsan/:id/edit",
    name: "Sửa bất động sản",
    exact: false,
    main: <BatdongsanEdit />,
  },
  batdongsan_add: {
    path: "/batdongsan/add",
    name: "Thêm bất động sản",
    exact: true,
    main: <BatdongsanAdd />,
  },
  batdongsan_project: {
    path: "/batdongsan-project",
    name: "Dự án",
    exact: true,
    main: <BatdongsanProject />,
  },
  batdongsan_category: {
    path: "/batdongsan-pc",
    name: "Danh mục bất động sản",
    exact: true,
    main: <BatdongsanCategory />,
  },
  batdongsan_category_edit: {
    path: "/batdongsan-pc/:id/edit",
    name: "Sửa danh mục bất động sản",
    exact: false,
    main: <BatdongsanCategoryEdit />,
  },
  batdongsan_category_add: {
    path: "/batdongsan-pc/add",
    name: "Thêm danh mục bất động sản",
    exact: true,
    main: <BatdongsanCategoryAdd />,
  },
  batdongsan_project_edit: {
    path: "/batdongsan-project/:id/edit",
    name: "Sửa danh mục bất động sản",
    exact: false,
    main: <BatdongsanProjectEdit />,
  },
  batdongsan_project_add: {
    path: "/batdongsan-project/add",
    name: "Thêm danh mục bất động sản",
    exact: true,
    main: <BatdongsanProjectAdd />,
  },
  khoa_hoc: {
    path: "/khoa-hoc",
    name: "Khoá học",
    exact: true,
    main: <Khoahoc />,
  },
  khoa_hoc_edit: {
    path: "/khoa-hoc/:id/edit",
    name: "Khoá học Edit",
    exact: false,
    main: <KhoahocEdit />,
  },
  khoa_hoc_add: {
    path: "/khoa-hoc/add",
    name: "Khoá học Add",
    exact: true,
    main: <KhoahocAdd />,
  },
  khoa_hoc_member: {
    path: "/khoa-hoc/:id/members",
    name: "Khoá học Members",
    exact: true,
    main: <KhoahocMember />,
  },
  khoa_hoc_report: {
    path: "/khoa-hoc-report/add",
    name: "Report",
    exact: true,
    main: <KhoahocReport />,
  },
  khoa_hoc_category: {
    path: "/khoa-hoc-pc",
    name: "Danh mục khoá học",
    exact: true,
    main: <KhoahocCategory />,
  },
  khoa_hoc_category_edit: {
    path: "/khoa-hoc-pc/:id/edit",
    name: "Sửa danh mục khoá học",
    exact: false,
    main: <KhoahocCategoryEdit />,
  },
  khoa_hoc_category_add: {
    path: "/khoa-hoc-pc/add",
    name: "Thêm danh mục khoá học",
    exact: true,
    main: <KhoahocCategoryAdd />,
  },
  khoa_hoc_live: {
    path: "/khoa-hoc-live",
    name: "Khoá học Live",
    exact: true,
    main: <KhoahocLive />,
  },
  khoa_hoc_live_edit: {
    path: "/khoa-hoc-live/:id/edit",
    name: "Khoá học Live Edit",
    exact: false,
    main: <KhoahocLiveEdit />,
  },
  khoa_hoc_live_add: {
    path: "/khoa-hoc-live/add",
    name: "Khoá học Live Add",
    exact: true,
    main: <KhoahocLiveAdd />,
  },

  product_review : {
    path : '/product-review',
    name: "Product Review",
    exact : true,
    main : <ProductReview />
    },
    product_review_edit : {
        path : '/product-review/:id/edit',
        name: "Product Review Edit",
        exact : false,
        main : <ProductReviewEdit />
    },
    product_reivew_add : {
        path : '/product-review/add',
        name: "Product Review Add",
        exact : true,
        main : <ProductReviewAdd />
    },
};

export default routes;
