
import React,{useEffect} from 'react';
import { Image,Form } from 'react-bootstrap';

import Lang from '@adminv2/system/language'
import APIs from '@adminv2/apis'

const Todos = APIs.localisation;

function SubZonePicker(props) {
  const [loading, setLoading] = React.useState(false);
  const [subzones, setSubZones] = React.useState(props.subzones || []);
  const inputName = props.name || '';
  const zoneId = props.zoneId || ''
  const errors = props.errors || {}
  const value = props.value || ''

  const getSubZones = async() => {
    setLoading(true)
    let response = await Todos.getZoneInfo(zoneId)
    if (response.data && Array.isArray(response.data.subzones)) {
      setSubZones(response.data.subzones)
    }
    if ('onGetInfo' in props) {
      props.onGetInfo(response.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (props.zoneId) {
      getSubZones()
    }else{
      setSubZones([])
    }
  },[props.zoneId]);

  return (
    <Form.Group className={props.className || ""}>
      <Form.Label>{Lang.t('SubZone')}</Form.Label>
      <Form.Select name={inputName} value={value} disabled={!zoneId || loading} className={ props.selectClassName + ' ' + (errors!=null && errors[inputName]!=null ? 'is-invalid' : '')} onChange={event => props.onChange(event.target.value)}>
          <option value=""> -- {Lang.t('Select SubZone')} -- </option>
            {(Array.isArray(subzones) ? subzones : []).map((subzone, key) => {
              return(
                <option value={subzone.id}>{subzone.name}</option>
              )
            })}
        </Form.Select>
        {
          errors[inputName] &&
          <div className="invalid-feedback">
            {errors[inputName]!=null ? errors[inputName][0] : ''}
          </div>
        }
    </Form.Group>
  );
};

export default SubZonePicker;
