import GLOBAL from "@adminv2/app.json";
import globalState from "@adminv2/system/globalState";
import axios from "axios";
const Todos = {
  getItems: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/file-manager/items";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getFolderStructures: () => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/file-manager/folder-structure";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  uploadFiles: (data = {}) => {
    return new Promise((resolve) => {
      var bodyFormData = new FormData();
      for (var i = 0; i < Object.keys(data).length; i++) {
        let key = Object.keys(data)[i];
        if (key == "uploads") {
          for (var j = 0; j < data["uploads"].length; j++) {
            bodyFormData.append("upload[]", data["uploads"][j]);
          }
        } else {
          bodyFormData.append(key, data[key]);
        }
      }
      let url = GLOBAL.SERVER + "/api/file-manager/upload";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios({
        method: "post",
        url: url,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  deleteFiles: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/file-manager/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  createNewFolder: (data) => {
    return new Promise((resolve) => {
      let url =
        GLOBAL.SERVER +
        "/api/file-manager/newfolder?working_dir=" +
        data.working_dir +
        "&name=" +
        data.name;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  cropImage: (data = {}) => {
    return new Promise((resolve) => {
      console.log(data);
      let url =
        GLOBAL.SERVER +
        "/api/file-manager/cropimage" +
        "?" +
        new URLSearchParams(data).toString();
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  uploadFile: (file,disk='public') => {
    return new Promise((resolve) => {
      var formData = new FormData();
      formData.append("upload", file);
      formData.append('disk',disk)
      let url = GLOBAL.SERVER + "/api/uploads";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios({
        method: "post",
        url: url,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data; charset=utf-8;",
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
};
export default Todos;
