import React from 'react';
import {
  Table,Card
} from 'react-bootstrap';
import {
  routes,Lang,Navigation,Components
} from '@adminv2/App'

function NewestSubscriptions( props ) {
  const info = props.info
  return (
      <div {...props}>

        <Card>
          <Card.Header className="text-center p-2 bg-light">
            {Lang.t('Newest Subscriptions')}
          </Card.Header>
          <Card.Body className="fw-bold text-end h3 m-0 p-2">
            <Table responsive>
              <thead class="">
                <tr>
                  <th>{Lang.t('Name')}</th>
                  <th>{Lang.t('Email')}</th>
                  <th>{Lang.t('Telephone')}</th>
                  <th>{Lang.t('Address')}</th>
                  <th>{Lang.t('Comment')}</th>
                  <th>{Lang.t('created_at')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  (Array.isArray(info.subscriptions) ? info.subscriptions : []).length == 0&&
                  <tr>
                    <td colSpan="6">
                      <Components.NoneOfResults/>
                    </td>
                  </tr>
                }
                {(Array.isArray(info.subscriptions) ? info.subscriptions : []).map((subscription, key) => {
                  return (
                    <tr>
                      <td>{subscription.name}</td>
                      <td>{subscription.email}</td>
                      <td>{subscription.telephone}</td>
                      <td>{subscription.address}</td>
                      <td>{subscription.comment}</td>
                      <td>{subscription.created_at}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
  );
}

export default NewestSubscriptions;
