import React from 'react';
import {
  Table,Card
} from 'react-bootstrap';
import {routes,Lang,Navigation} from '@adminv2/App'
import moment from 'moment'

function NewestOrders( props ) {
  const info = props.info
  const order_statuss = info.order_statuss || {}
  return (
      <div {...props}>

        <Card>
          <Card.Header className="text-center p-2 bg-light">
            {Lang.t('Newest Orders')}
          </Card.Header>
          <Card.Body className="fw-bold text-end h3 m-0 p-2">
            <Table responsive>
              <thead class="">
                <tr>
                  <th>ID</th>
                  <th>Loại hồ sơ</th>
                  <th>Mã chuyên gia</th>
                  
                  <th>{Lang.t('Customer')}</th>
                  
                  <th>{Lang.t('Total')}</th>
                  <th>{Lang.t('Status')}</th>
                  <th>{Lang.t('Description')}</th>
                  <th>{Lang.t('created_at')}</th>
                </tr>
              </thead>
              <tbody>
                {(Array.isArray(info.orders) ? info.orders : []).map((order, key) => {
                  return (
                    <tr>
                      <td><a onClick={() => {
                          Navigation.navigate(routes.order_edit.path.replace(':id',order.id))
                        }}>{order.code}</a></td>
                      <td>
                        {
                          order.type &&
                          <a onClick={() => {
                            Navigation.navigate(routes.ho_so_cat.path.replace(':cat',order.type))
                          }}>{order.type_name}</a>
                        }
                      </td>
                      <td>
                        {
                          order.fengshuimaster_id &&
                          <a onClick={() => {
                            Navigation.navigate(routes.fengshuimaster_edit.path.replace(':id',order.fengshuimaster_id))
                          }}>CG{order.fengshuimaster_id}</a>
                        }
                      </td>
                      
                      <td>
                        {order.firstname} {order.lastname}
                      </td>
                      <td>{order.total_format}</td>
                      <td>
                        {
                          order.order_status_code && order_statuss && order_statuss[order.order_status_code]!=null
                          ?<span style={{color: order_statuss[order.order_status_code].color}}>{order_statuss[order.order_status_code].name}</span>
                          :<span className="text-warning">
                            {order_statuss['pending']!=null ? order_statuss['pending'].name : 'Pending'}
                          </span>
                        }
                      </td>
                      <td>{order.description}</td>
                      <td>
                        {moment(order.created_at).format('YYYY-MM-DD HH:mm:ss')}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
  );
}

export default NewestOrders;
