import React from 'react';
import {
  ButtonGroup, Button, Card,
  Modal,OverlayTrigger,Tooltip,
} from 'react-bootstrap'

import {
  routes, Lang, Navigation
} from '@adminv2/App'

import { 
  MapContainer, TileLayer, Marker,Popup 
} from 'react-leaflet'

import FengshuiMasterMapModal from '@adminv2/views/fengshuimaster/FengshuiMasterMapModal'
import FengshuiMasterOrders from '@adminv2/views/fengshuimaster/FengshuiMasterOrders'

function FengshuiMasterMap( props ) {
  const fengshuiMasterMapModalRef = React.useRef();
  const info = props.info
  const results = info.results || []

  const [show, setShow] = React.useState();

  return (
    <>
      <Card>
        <Card.Header className="p-2 pt-1 pb-1 small bg-light d-flex justify-content-between align-items-center">
          <span className="text-uppercase"><i className="fas fa-map-marker-alt"></i> {Lang.t('Fengshui Master')}</span>
          <div>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={(
                <Tooltip {...props}>
                  {Lang.t('Fullscreen')}
                </Tooltip>
              )}
            >
              <a
                className=""
                onClick={() => {
                  fengshuiMasterMapModalRef.current.open()
                }}
              ><i className="fa fa-expand"></i></a>
            </OverlayTrigger>
          </div>
        </Card.Header>
        <Card.Body className="fw-bold text-end h3 m-0 p-0">
          <MapContainer 
            center={[16.464606, 107.365354]} 
            zoom={4} 
            scrollWheelZoom={false} 
            style={{height: 250}}> 
            <TileLayer
              attribution=''
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {(Array.isArray(results) ? results : []).map((result, key) => {
              if (result.latitude && result.longitude) {
                return(
                  <Marker 
                    position={[result.latitude, result.longitude]}
                  >
                    <Popup>
                      <div>
                        <a 
                          className="fw-bold"
                          onClick={() => {
                            window.open(routes.fengshuimaster_edit.path.replace(':id', result.id),'_blank')
                          }}
                        >{result.name}</a>  
                      </div>
                      <div>{result.email}</div>
                      <div>{result.telephone}</div>
                      <div className="mt-2">
                        <a 
                          className="text-decoration-underline"
                          onClick={() => {
                            setShow(result.id)
                          }}
                        >Xem lịch sử hồ sơ</a>
                      </div>
                    </Popup>
                  </Marker>
                )
              }
            })}
          </MapContainer>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={() => {
        setShow('')
      }}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            show &&
            <>
              <FengshuiMasterOrders
                id={show}
              />
            </>
          }
        </Modal.Body>
      </Modal>
      <FengshuiMasterMapModal ref={fengshuiMasterMapModalRef}/>
    </>
  );
}

export default FengshuiMasterMap;
