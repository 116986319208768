import React from "react";
import { Navigate } from "react-router-dom";

const Order = React.lazy(() => import("@adminv2/views/sale/sale/Order"));
const OrderEdit = React.lazy(() =>
  import("@adminv2/views/sale/sale/OrderEdit")
);
const OrderAdd = React.lazy(() => import("@adminv2/views/sale/sale/OrderAdd"));

const HoSo = React.lazy(() => import("@adminv2/views/sale/sale/HoSo"));
const HoSoEdit = React.lazy(() => import("@adminv2/views/sale/sale/HoSoEdit"));
const HoSoAdd = React.lazy(() => import("@adminv2/views/sale/sale/HoSoAdd"));

const Shipping = React.lazy(() =>
  import("@adminv2/views/sale/shipping/Shipping")
);
const ShippingEdit = React.lazy(() =>
  import("@adminv2/views/sale/shipping/ShippingEdit")
);

const Batdongsan = React.lazy(() =>
  import("@adminv2/views/sale/sale/Batdongsan")
);
const BatdongsanEdit = React.lazy(() =>
  import("@adminv2/views/sale/sale/BatdongsanEdit")
);
const BatdongsanAdd = React.lazy(() =>
  import("@adminv2/views/sale/sale/BatdongsanAdd")
);

const SubscriptionPlan = React.lazy(() =>
  import("@adminv2/views/sale/sale/SubscriptionPlan")
);
const SubscriptionPlanEdit = React.lazy(() =>
  import("@adminv2/views/sale/sale/SubscriptionPlanEdit")
);
const SubscriptionPlanAdd = React.lazy(() =>
  import("@adminv2/views/sale/sale/SubscriptionPlanAdd")
);

const CoinPackage = React.lazy(() =>
  import("@adminv2/views/sale/sale/CoinPackage")
);
const CoinPackageEdit = React.lazy(() =>
  import("@adminv2/views/sale/sale/CoinPackageEdit")
);
const CoinPackageAdd = React.lazy(() =>
  import("@adminv2/views/sale/sale/CoinPackageAdd")
);

const KhoaHoc = React.lazy(() => import("@adminv2/views/sale/sale/KhoaHoc"));
const KhoaHocEdit = React.lazy(() =>
  import("@adminv2/views/sale/sale/KhoaHocEdit")
);
const KhoaHocAdd = React.lazy(() =>
  import("@adminv2/views/sale/sale/KhoaHocAdd")
);

const routes = {
  order: {
    path: "/order",
    name: "Order",
    exact: true,
    main: <Order />,
  },
  order_edit: {
    path: "/order/:id/edit",
    name: "Order Edit",
    exact: false,
    main: <OrderEdit />,
  },
  order_add: {
    path: "/order/add",
    name: "Order Add",
    exact: true,
    main: <OrderAdd />,
  },
  khoa_hoc_order: {
    path: "/khoa-hoc-order",
    name: "Khoa Hoc",
    exact: true,
    main: <KhoaHoc />,
  },
  khoa_hoc_order_edit: {
    path: "/khoa-hoc-order/:id/edit",
    name: "Khoa Hoc Edit",
    exact: false,
    main: <KhoaHocEdit />,
  },
  khoa_hoc_order_add: {
    path: "/khoa-hoc-order/add",
    name: "Khoa Hoc Add",
    exact: true,
    main: <KhoaHocAdd />,
  },
  batdongsan_order: {
    path: "/bat-dong-san-order",
    name: "Bat dong san",
    exact: true,
    main: <Batdongsan />,
  },
  batdongsan_order_edit: {
    path: "/bat-dong-san-order/:id/edit",
    name: "Bat dong san Edit",
    exact: false,
    main: <BatdongsanEdit />,
  },
  batdongsan_order_add: {
    path: "/bat-dong-san-order/add",
    name: "Bat dong san Add",
    exact: true,
    main: <BatdongsanAdd />,
  },
  //   ho_so : {
  //     path : '/ho-so',
  //     name: "Hồ sơ",
  //     exact : true,
  //     main : <HoSo />
  // },
  // ho_so_edit : {
  //     path : '/ho-so/:id/edit',
  //     name: "Hồ sơ Edit",
  //     exact : false,
  //     main : <HoSoEdit />
  // },
  // ho_so_add : {
  //     path : '/ho-so/add',
  //     name: "Hồ sơ Add",
  //     exact : true,
  //     main : <HoSoAdd />
  // },
  ho_so: {
    path: "/ho-so",
    name: "Hồ sơ",
    exact: true,
    main: <Navigate to="/ho-so/xem-phong-thuy" replace={true} />,
  },
  // ho_so_xem_phong_thuy: {
  //   path: "/ho-so/xem-phong-thuy",
  //   name: "Xem phong thuỷ",
  //   exact: true,
  //   main: <HoSo />,
  // },
  ho_so_cat: {
    path: "/ho-so/:cat",
    name: "Hồ sơ",
    exact: true,
    main: <HoSo />,
  },
  ho_so_cat_edit: {
    path: "/ho-so/:cat/:id/edit",
    name: "Hồ sơ Edit",
    exact: true,
    main: <HoSoEdit />,
  },
  ho_so_cat_add: {
    path: "/ho-so/:cat/add",
    name: "Hồ sơ Add",
    exact: true,
    main: <HoSoAdd />,
  },
  shipping: {
    path: "/shipping",
    name: "Shipping",
    exact: true,
    main: <Shipping />,
  },
  shipping_edit: {
    path: "/shipping/:id/edit",
    name: "Shipping Edit",
    exact: false,
    main: <ShippingEdit />,
  },
  subscription_plan_order: {
    path: "/subscription-plan-order",
    name: "SubscriptionPlan",
    exact: true,
    main: <SubscriptionPlan />,
  },
  subscription_plan_order_edit: {
    path: "/subscription-plan-order/:id/edit",
    name: "SubscriptionPlan Edit",
    exact: false,
    main: <SubscriptionPlanEdit />,
  },
  subscription_plan_order_add: {
    path: "/subscription-plan-order/add",
    name: "SubscriptionPlan Add",
    exact: true,
    main: <SubscriptionPlanAdd />,
  },

  coin_package_order: {
    path: "/coin-package-order",
    name: "CoinPackage",
    exact: true,
    main: <CoinPackage />,
  },
  coin_package_order_edit: {
    path: "/coin-package-order/:id/edit",
    name: "CoinPackage Edit",
    exact: false,
    main: <CoinPackageEdit />,
  },
  coin_package_order_add: {
    path: "/coin-package-order/add",
    name: "CoinPackage Add",
    exact: true,
    main: <CoinPackageAdd />,
  },
};

export default routes;
