import GLOBAL from "@adminv2/app.json";
import routes from "@adminv2/routes";
import globalState from "@adminv2/system/globalState";
import Navigation from "@adminv2/system/navigation";
import axios from "axios";
const Todos = {
  renderCategoryStructureSelectOptions: (category, level = 0) => {
    return (
      <>
        <option value={category.id}>
          {Array(level)
            .fill(level)
            .map((key) => {
              return "\u00A0\u00A0\u00A0";
            })}
          {category.name}
        </option>
        {(Array.isArray(category.children) ? category.children : []).map(
          (children, key) => {
            return Todos.renderCategoryStructureSelectOptions(
              children,
              level + 1
            );
          }
        )}
      </>
    );
  },
  searchStatisticProduct: (key = "", filter) => {
    filter.name = key;
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/product/search_statistic";
      axios
        .post(url, {
          filter: filter,
        })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  addMember: (product_id, ids) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${product_id}/members/add`;
      axios
        .post(url, {
          members: ids,
        })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  
  addFengshuiMasters: (product_id, ids) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${product_id}/fengshuimasters/add`
      axios.post(url, {
        fengshuimasters: ids
      }).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },

  deleteFengshuiMasters: (product_id, ids) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${product_id}/fengshuimasters/delete`
      axios.post(url, {
        fengshuimasters: ids
      }).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },

  deleteMember: (product_id, ids) => {
    return new Promise((resolve) => {
      let url =
        GLOBAL.SERVER + `/api/user/khoa-hoc/${product_id}/members/delete`;
      axios
        .post(url, {
          members: ids,
        })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  getReviews: (data) => {
    delete data.filter.type;
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product-review/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  productReviewEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product-review/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  productReviewDelete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product-review/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  productReviewDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product-review/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  

  getList: (data) => {
    delete data.filter.type;
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  notifyProduct: (productId, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/product/${productId}/notify`;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      //console.log(globalState.getBearerToken());
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },


  getForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  create: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  edit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  delete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  deletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  copy: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product/copy";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  // Khoa Hoc
  getKhoahocList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getKhoaHocForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getKhoaHocMembers: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc/" + id + "/members";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  exportKhoaHocMembers: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc/" + id + "/members/export";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  importKhoaHocMembers: (id, file) => {
    var formData = new FormData();
    formData.append('import', file);
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc/" + id + "/members/import";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url,formData)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  getKhoaHocFengshuimasters: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc/" + id + "/fengshuimasters";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },



  khoaHocCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  khoaHocEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  khoaHocDelete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  khoaHocDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  // KhoaHoc Live
  getKhoahocLiveList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc-live/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getKhoaHocLiveForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc-live/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getKhoaHocLiveMembers: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc-live/" + id + "/members";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  khoaHocLiveCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc-live/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  khoaHocLiveEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc-live/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  khoaHocLiveDelete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc-live/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  khoaHocLiveDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc-live/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  saveUserEvent: (product_id,data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${product_id}/login`;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data )
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  /* event */
  createEvent: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc-live/" + id + "/events/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },  

  getEvent: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc-live/" + id + "/events";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  

  getAllEvent: (productId,events) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${productId}/sync-events`;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { events: events })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  //add and update delete get Event
  updateEvent: (productId,data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${productId}/events/update`;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url,data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  addEvent: (productId,data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${productId}/events/add`;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url,data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  getEvents: (productId) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${productId}/events`;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  deleteEvent: (productId,eventId) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${productId}/events/${eventId}/delete`;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  //Section and Lesson Video
  createSection: (productId, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${productId}/sections/add`;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      console.log(globalState.getBearerToken());
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  getListSection: (productId) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${productId}/sections`;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getFormSection: (productId,sectionId) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${productId}/sections/form?id=${sectionId}`;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  editSection: (productId, sectionId, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${productId}/sections/${sectionId}/update`;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },


  deletesSection: (productId,selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${productId}/sections/deletes`;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },


  createLesson: (productId, sectionId, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${productId}/sections/${sectionId}/lessions/add`;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      console.log(globalState.getBearerToken());
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  getListLesson: (productId, sectionId) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${productId}/sections/${sectionId}/lessions`;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  getFormLesson: (productId,sectionId,lessionId) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${productId}/sections/${sectionId}/lessions/form?id=${lessionId}`;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  
  editLession: (productId, sectionId, lessionId, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${productId}/sections/${sectionId}/lessions/${lessionId}/update`;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  updatesLession: (productId, sectionId, lessions) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${productId}/sections/${sectionId}/lessions/updates`;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, lessions)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  deletesLesson: (productId,sectionId,selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${productId}/sections/${sectionId}/lessions/deletes`;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  // KHoa hoc combo
  createKhoaHocCombo: (productId, comboIds) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${productId}/sections/add`;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      console.log(globalState.getBearerToken());
      axios
        .post(url, comboIds)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },


  getForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },


  //Notify khoá học
  notify: (productId, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + `/api/user/khoa-hoc/${productId}/notify`;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      console.log(globalState.getBearerToken());
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },


  // Bat Dong San
  getKhoahocList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getKhoaHocForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getKhoaHocMembers: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc/" + id + "/members";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  khoaHocCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  khoaHocEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  
  khoaHocDelete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  khoaHocDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/khoa-hoc/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  // KhoaHoc Live
  getBatDongSanList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/bat-dong-san/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getBatDongSanForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/bat-dong-san/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getBatDongSanMembers: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/bat-dong-san/" + id + "/members";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  batDongSanCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/bat-dong-san/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  batDongSanEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/bat-dong-san/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  batDongSanDelete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/bat-dong-san/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  batDongSanDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/bat-dong-san/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  // Danh muc
  categoryStructure: (data = {}) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/product-category/structure";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getCategoryList: (data) => {
    // delete data.filter.type
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product-category/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  // getShopCategoryList : (data) => {
  //   return new Promise((resolve) => {
  //     data.filter = data.filter || {}
  //     data.filter.type = 'vat-pham'
  //     let url = GLOBAL.SERVER + '/api/user/product-category/list'
  //     axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
  //     axios.post(url,data).then(response => {
  //       resolve(response)
  //     }).catch(function (error) {
  //       resolve({})
  //     });
  //   })
  // },
  getBatdongsanCategoryList: (data) => {
    return new Promise((resolve) => {
      data.filter = data.filter || {};
      data.filter.type = "bat-dong-san";
      let url = GLOBAL.SERVER + "/api/user/product-category/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getKhoaHocCategoryList: (data) => {
    return new Promise((resolve) => {
      data.filter = data.filter || {};
      data.filter.type = "khoa-hoc";
      let url = GLOBAL.SERVER + "/api/user/product-category/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getBatdongsanProjectList: (dataFilter = {}) => {
    return new Promise((resolve) => {
      let data = { ...dataFilter };
      data["filter"] = { type: "bat-dong-san" };
      let url = GLOBAL.SERVER + "/api/user/bat-dong-san-du-an/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getCategoryForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product-category/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getKhoaHocCategoryForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product-category/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, {
          filter: {
            type: "khoa-hoc",
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getBatDongSanCategoryForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product-category/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, {
          filter: {
            type: "bat-dong-san",
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getBatDongSanProjectForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/bat-dong-san-du-an/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, {
          filter: {
            type: "bat-dong-san",
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  categoryCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product-category/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  categoryEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product-category/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  categoryDelete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product-category/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  categoryDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product-category/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  // Project
  projectCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/bat-dong-san-du-an/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  projectEdit: (id, data) => {
    return new Promise((resolve) => {
      let url =
        GLOBAL.SERVER + "/api/user/bat-dong-san-du-an/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  projectDelete: (id) => {
    return new Promise((resolve) => {
      let url =
        GLOBAL.SERVER + "/api/user/bat-dong-san-du-an/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  projectDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/bat-dong-san-du-an/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  // Manufacturer
  getManufacturerList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/manufacturer/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getManufacturerForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/manufacturer/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  manufacturerCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/manufacturer/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  manufacturerEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/manufacturer/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  manufacturerDelete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/manufacturer/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  manufacturerDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/manufacturer/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  // Attribute Group
  getAttributeGroupList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product-attribute-group/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getAttributeGroupForm: (id = 0) => {
    return new Promise((resolve) => {
      let url =
        GLOBAL.SERVER + "/api/user/product-attribute-group/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  attributeGroupCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product-attribute-group/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getAttributeGroupInfo: (id = 0) => {
    return new Promise((resolve) => {
      let url =
        GLOBAL.SERVER + "/api/user/product-attribute-group/" + id + "/info";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  attributeGroupEdit: (id, data) => {
    return new Promise((resolve) => {
      let url =
        GLOBAL.SERVER + "/api/user/product-attribute-group/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  attributeGroupDelete: (id) => {
    return new Promise((resolve) => {
      let url =
        GLOBAL.SERVER + "/api/user/product-attribute-group/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  attributeGroupDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product-attribute-group/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  searchStatisticProductAttribute: (key = "") => {
    return new Promise((resolve) => {
      let url =
        GLOBAL.SERVER +
        "/api/product-attribute/search_statistic?filter[name]=" +
        key;
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getAttributeForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product-attribute/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  attributeCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product-attribute/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  attributeEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product-attribute/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  attributeDelete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/product-attribute/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  searchStatisticProductAttributeValue: (attribute_id, key = "") => {
    return new Promise((resolve) => {
      let url =
        GLOBAL.SERVER +
        "/api/product-attribute/" +
        attribute_id +
        "/values?filter[name]=" +
        key;
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  labci: (category_id) => {
    return new Promise((resolve) => {
      let url =
        GLOBAL.SERVER + "/api/product-attribute-group/labci/" + category_id;
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  // Subscription Plan
  getSubscriptionPlanList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/subscription_plan/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getSubscriptionPlanForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/subscription_plan/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  subscriptionPlanCreate: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/subscription_plan/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  subscriptionPlanEdit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/subscription_plan/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  subscriptionPlanDelete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/subscription_plan/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  subscriptionPlanDeletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/subscription_plan/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  checkRouteForProductType: (info) => {
    if (info.type == "khoa-hoc") {
      Navigation.navigate(routes.khoa_hoc_edit.path.replace(":id", info.id));
    } else if (info.type == "bat-dong-san") {
      Navigation.navigate(routes.batdongsan_edit.path.replace(":id", info.id));
    } else {
      Navigation.navigate(routes.product_edit.path.replace(":id", info.id));
    }
  },
};
export default Todos;
