import React from 'react';

const Floorplan = React.lazy(() => import('@adminv2/views/floorplan/floorplan/Floorplan'))
const FloorplanInfo = React.lazy(() => import('@adminv2/views/floorplan/floorplan/FloorplanInfo'))
const FloorplanEdit = React.lazy(() => import('@adminv2/views/floorplan/floorplan/FloorplanEdit'))
const FloorplanAdd = React.lazy(() => import('@adminv2/views/floorplan/floorplan/FloorplanAdd'))

const Category = React.lazy(() => import('@adminv2/views/floorplan/category/Category'))
const CategoryEdit = React.lazy(() => import('@adminv2/views/floorplan/category/CategoryEdit'))
const CategoryAdd = React.lazy(() => import('@adminv2/views/floorplan/category/CategoryAdd'))

const Texture = React.lazy(() => import('@adminv2/views/floorplan/texture/Texture'))
const TextureEdit = React.lazy(() => import('@adminv2/views/floorplan/texture/TextureEdit'))
const TextureAdd = React.lazy(() => import('@adminv2/views/floorplan/texture/TextureAdd'))

const AttributeGroup = React.lazy(() => import('@adminv2/views/floorplan/attribute/AttributeGroup'))
const AttributeGroupEdit = React.lazy(() => import('@adminv2/views/floorplan/attribute/AttributeGroupEdit'))
const AttributeGroupAdd = React.lazy(() => import('@adminv2/views/floorplan/attribute/AttributeGroupAdd'))

const Attribute = React.lazy(() => import('@adminv2/views/floorplan/attribute/Attribute'))
const AttributeEdit = React.lazy(() => import('@adminv2/views/floorplan/attribute/AttributeEdit'))
const AttributeAdd = React.lazy(() => import('@adminv2/views/floorplan/attribute/AttributeAdd'))

const Luangiai = React.lazy(() => import('@adminv2/views/floorplan/luan-giai/Luangiai'))
const LuangiaiEdit = React.lazy(() => import('@adminv2/views/floorplan/luan-giai/LuangiaiEdit'))
const LuangiaiAdd = React.lazy(() => import('@adminv2/views/floorplan/luan-giai/LuangiaiAdd'))

const LuangiaiCase = React.lazy(() => import('@adminv2/views/floorplan/LuangiaiCase'))
const LuangiaiCaseAdd = React.lazy(() => import('@adminv2/views/floorplan/LuangiaiCaseAdd'))
const LuangiaiCaseMultipleAdd = React.lazy(() => import('@adminv2/views/floorplan/LuangiaiCaseMultipleAdd'))
const LuangiaiCaseEdit = React.lazy(() => import('@adminv2/views/floorplan/LuangiaiCaseEdit'))

const Hoagiai = React.lazy(() => import('@adminv2/views/floorplan/hoa-giai/Hoagiai'))
const HoagiaiEdit = React.lazy(() => import('@adminv2/views/floorplan/hoa-giai/HoagiaiEdit'))
const HoagiaiAdd = React.lazy(() => import('@adminv2/views/floorplan/hoa-giai/HoagiaiAdd'))

const HinhThe = React.lazy(() => import('@adminv2/views/floorplan/hinh-the/HinhThe'))
const HinhTheInfo = React.lazy(() => import('@adminv2/views/floorplan/hinh-the/HinhTheInfo'))
const HinhTheEdit = React.lazy(() => import('@adminv2/views/floorplan/hinh-the/HinhTheEdit'))
const HinhTheAdd = React.lazy(() => import('@adminv2/views/floorplan/hinh-the/HinhTheAdd'))

const LuanGiaiType = React.lazy(() => import('@adminv2/views/floorplan/luan-giai-type/LuanGiaiType'))
const LuanGiaiTypeEdit = React.lazy(() => import('@adminv2/views/floorplan/luan-giai-type/LuanGiaiTypeEdit'))
const LuanGiaiTypeAdd = React.lazy(() => import('@adminv2/views/floorplan/luan-giai-type/LuanGiaiTypeAdd'))

const LoaiCongTrinh = React.lazy(() => import('@adminv2/views/floorplan/loaicongtrinh/LoaiCongTrinh'))
const LoaiCongTrinhEdit = React.lazy(() => import('@adminv2/views/floorplan/loaicongtrinh/LoaiCongTrinhEdit'))
const LoaiCongTrinhAdd = React.lazy(() => import('@adminv2/views/floorplan/loaicongtrinh/LoaiCongTrinhAdd'))

const VaiTro = React.lazy(() => import('@adminv2/views/floorplan/vai-tro/VaiTro'))
const VaiTroEdit = React.lazy(() => import('@adminv2/views/floorplan/vai-tro/VaiTroEdit'))
const VaiTroAdd = React.lazy(() => import('@adminv2/views/floorplan/vai-tro/VaiTroAdd'))

const DiemTu = React.lazy(() => import('@adminv2/views/floorplan/diem-tu/DiemTu'))
const DiemTuEdit = React.lazy(() => import('@adminv2/views/floorplan/diem-tu/DiemTuEdit'))
const DiemTuAdd = React.lazy(() => import('@adminv2/views/floorplan/diem-tu/DiemTuAdd'))

const routes = {
  floorplan : {
      path : '/floorplan',
      name: "floorplan",
      exact : true,
      main : <Floorplan />
  },
  floorplan_info : {
    path : '/floorplan/:id/info',
    name: "floorplan Info",
    exact : false,
    main : <FloorplanInfo />
},
  floorplan_edit : {
      path : '/floorplan/:id/edit',
      name: "floorplan Edit",
      exact : false,
      main : <FloorplanEdit />
  },
  floorplan_add : {
      path : '/floorplan/add',
      name: "floorplan Add",
      exact : true,
      main : <FloorplanAdd />
  },
  floorplan_category : {
      path : '/foc',
      name: "floorplan Category",
      exact : true,
      main : <Category />
  },
  floorplan_category_edit : {
      path : '/foc/:id/edit',
      name: "floorplan Category Edit",
      exact : false,
      main : <CategoryEdit />
  },
  floorplan_category_add : {
      path : '/foc/add',
      name: "floorplan Category Add",
      exact : true,
      main : <CategoryAdd />
  },
//   -------
floorplan_texture : {
    path : '/fptexture',
    name: "floorplan texture",
    exact : true,
    main : <Texture />
},
floorplan_texture_edit : {
    path : '/fptexture/:id/edit',
    name: "floorplan texture Edit",
    exact : false,
    main : <TextureEdit />
},
floorplan_texture_add : {
    path : '/fptexture/add',
    name: "floorplan texture Add",
    exact : true,
    main : <TextureAdd />
},
  floorplan_attribute_group : {
    path : '/fl-attribute-group',
    name: "Attribute Group",
    exact : true,
    main : <AttributeGroup />
    },
    floorplan_attribute_group_edit : {
        path : '/fl-attribute-group/:id/edit',
        name: "Attribute Group Edit",
        exact : false,
        main : <AttributeGroupEdit />
    },
    floorplan_attribute_group_add : {
        path : '/fl-attribute-group/add',
        name: "Attribute Group Add",
        exact : true,
        main : <AttributeGroupAdd />
    },
  floorplan_attribute : {
    path : '/fl-attribute',
    name: "Attribute",
    exact : true,
    main : <Attribute />
},
floorplan_attribute_edit : {
    path : '/fl-attribute/:id/edit',
    name: "Attribute Edit",
    exact : false,
    main : <AttributeEdit />
},
floorplan_attribute_add : {
    path : '/fl-attribute/add',
    name: "Attribute Add",
    exact : true,
    main : <AttributeAdd />
},
    floorplan_luan_giai : {
        path : '/floorplan-luan-giai',
        name: "Luận giải",
        exact : true,
        main : <Luangiai />
    },
    floorplan_luan_giai_edit : {
        path : '/floorplan-luan-giai/:id/edit',
        name: "Sửa luận giải",
        exact : true,
        main : <LuangiaiEdit />
    },
    floorplan_luan_giai_add : {
        path : '/floorplan-luan-giai/add',
        name: "Thêm luận giải",
        exact : true,
        main : <LuangiaiAdd />
    },
    
    floorplan_luan_giai_case : {
        path : '/floorplan-case-luan-giai',
        name: "Trường hợp luận giải",
        exact : true,
        main : <LuangiaiCase />
    },

    floorplan_luan_giai_case_add : {
        path : '/floorplan-case-luan-giai-add',
        name: "Thêm Trường hợp luận giải",
        exact : true,
        main : <LuangiaiCaseAdd />
    },

    floorplan_luan_giai_case_multiple_add : {
        path : '/floorplan-case-luan-giai-multiple-add',
        name: "Thêm Trường hợp luận giải",
        exact : true,
        main : <LuangiaiCaseMultipleAdd />
    },

    floorplan_luan_giai_case_edit : {
        path : '/floorplan-case-luan-giai/:id/edit',
        name: "Sửa Trường hợp luận giải",
        exact : true,
        main : <LuangiaiCaseEdit />
    },

    floorplan_hoa_giai : {
        path : '/floorplan-hoa-giai',
        name: "Hoá giải",
        exact : true,
        main : <Hoagiai />
    },
    floorplan_hoa_giai_edit : {
        path : '/floorplan-hoa-giai/:id/edit',
        name: "Sửa hoá giải",
        exact : true,
        main : <HoagiaiEdit />
    },
    floorplan_hoa_giai_add : {
        path : '/floorplan-hoa-giai/add',
        name: "Thêm hoá giải",
        exact : true,
        main : <HoagiaiAdd />
    },
    floorplan_hinh_the : {
        path : '/floorplan-hinh-the',
        name: "Hinh The",
        exact : true,
        main : <HinhThe />
    },
    floorplan_hinh_the_info : {
      path : '/floorplan-hinh-the/:id/info',
      name: "Hinh The Info",
      exact : false,
      main : <HinhTheInfo />
  },
    floorplan_hinh_the_edit : {
        path : '/floorplan-hinh-the/:id/edit',
        name: "Hinh The Edit",
        exact : false,
        main : <HinhTheEdit />
    },
    floorplan_hinh_the_add : {
        path : '/floorplan-hinh-the/add',
        name: "Hinh The Add",
        exact : true,
        main : <HinhTheAdd />
    },
    // 
    floorplan_luan_giai_type : {
        path : '/floorplan-luan-giai-type',
        name: "Loại Luận giải",
        exact : true,
        main : <LuanGiaiType />
    },
    floorplan_luan_giai_type_edit : {
        path : '/floorplan-luan-giai-type/:id/edit',
        name: "Sửa Loại Luận giải",
        exact : true,
        main : <LuanGiaiTypeEdit />
    },
    floorplan_luan_giai_type_add : {
        path : '/floorplan-luan-giai-type/add',
        name: "Thêm Loại Luận giải",
        exact : true,
        main : <LuanGiaiTypeAdd />
    },
    // 
    loai_cong_trinh : {
        path : '/loai-cong-trinh',
        name: "Loại Công trình",
        exact : true,
        main : <LoaiCongTrinh />
    },
    loai_cong_trinh_edit : {
        path : '/loai-cong-trinh/:id/edit',
        name: "Sửa Loại Công trình",
        exact : true,
        main : <LoaiCongTrinhEdit />
    },
    loai_cong_trinh_add : {
        path : '/loai-cong-trinh/add',
        name: "Thêm Loại Công trình",
        exact : true,
        main : <LoaiCongTrinhAdd />
    },
    floorplan_vai_tro : {
        path : '/floorplan-vai-tro',
        name: "Nhóm Vai trò",
        exact : true,
        main : <VaiTro />
    },
    floorplan_vai_tro_edit : {
        path : '/floorplan-vai-tro/:id/edit',
        name: "Sửa Nhóm Vai trò",
        exact : true,
        main : <VaiTroEdit />
    },
    floorplan_vai_tro_add : {
        path : '/floorplan-vai-tro/add',
        name: "Thêm Vai trò",
        exact : true,
        main : <VaiTroAdd />
    },
    diem_tu : {
        path : '/diem-tu',
        name: "Điểm tử",
        exact : true,
        main : <DiemTu />
    },
    diem_tu_edit : {
        path : '/diem-tu/:id/edit',
        name: "Sửa Điểm tử",
        exact : true,
        main : <DiemTuEdit />
    },
    diem_tu_add : {
        path : '/diem-tu/add',
        name: "Thêm Điểm tử",
        exact : true,
        main : <DiemTuAdd />
    },
}


export default routes;
