import React from 'react';

const Login = React.lazy(() => import('@adminv2/views/auth/Login'))
const Register = React.lazy(() => import('@adminv2/views/auth/Register'))
const Logout = React.lazy(() => import('@adminv2/views/auth/Logout'))

const Profile = React.lazy(() => import('@adminv2/views/auth/Profile'))
const Messages = React.lazy(() => import('@adminv2/views/auth/Messages'))

const routes = {
  login : {
      path  : '/login',
      name  : "Login",
      auth  : false,
      exact : false,
      adminToolKit: false, // Hide admin menu, header, footer
      main  : <Login />
  },
  register : {
      path  : '/register',
      name  : "Register",
      auth  : false,
      exact : false,
      adminToolKit: false, // Hide admin menu, header, footer
      main  : <Register />
  },
  logout : {
      path  : '/logout',
      name  : "Logout",
      exact : false,
      main  : <Logout />
  },
  messages : {
      path : '/messages',
      name: "Messages",
      exact : false,
      main : <Messages />
  },
  profile : {
      path : '/profile',
      name: "Profile",
      exact : false,
      main : <Profile />
  },
}


export default routes;
