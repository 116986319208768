import React from 'react';
import {
  Form, Breadcrumb, ButtonGroup, Button, Toast, ToastContainer, Card,
  Tabs, Tab,Table,Modal,
  InputGroup,OverlayTrigger,Tooltip
} from 'react-bootstrap'

import Dragula from 'react-dragula';
import "react-dragula/dist/dragula.css";

import {
  Lang,Components,APIs,Notify
} from '@adminv2/App'

function FilesInputPicker(props) {
  const value = props.value || []
  const onChange = props.onPick
  const dragulaDecorator = (componentBackingInstance) => {
    if (componentBackingInstance) {
      Dragula([componentBackingInstance], {
        moves: function(el, source, handle, sibling) {
            return el.classList.contains('handle');
        },
        accepts: function (el, target, source, sibling) {
          return el.classList.contains('handle');
        }
      });
    }
  };
  return (
    <div className="d-flex flex-wrap" ref={dragulaDecorator}>
      {(Array.isArray(value) ? value : []).map((image,key) => (
        <div className="handle position-relative p-2 border d-flex justify-content-center align-items-center overflow-hidden mb-2 me-2 preview-container" style={{width: 100,height: 100}}>
          <img src={image.image} className="w-100"/>
          <div className="position-absolute top-1 right-1">
            <a className="text-danger" onClick={() => {
              let images = value || [];
              images.splice(images.indexOf(image), 1);
              onChange(images)
            }}><i className="fa fa-trash-alt"></i></a>
          </div>
        </div>
      ))}
      <Components.FileInputPicker
        title={props.title || ''}
        className="mb-2 me-2"
        type="image"
        multiple={true}
        placeholder={'/image/others/add-image.png'}
        width={100}
        height={100}
        onPick={image => {
          let picker_images = Array.isArray(image) ? image : [image]
          let images = value || [];
          for (var i = 0; i < picker_images.length; i++) {
            const hasImage = images.some(function(img) {
              return img.image === picker_images[i].url;
            })
            if (!hasImage) {
              images.push({image: picker_images[i].url})
            }
          }
          onChange(images)
        }}
      />
    </div>
  );
}

export default FilesInputPicker;
