import React from 'react';
import {
  ButtonGroup, Button, Card
} from 'react-bootstrap'

import {
  routes, Lang, Navigation
} from '@adminv2/App'

function NewsTotal( props ) {
  const info = props.info
  return (
    <Card>
      <Card.Header className="text-end p-2 small bg-light">
        {Lang.t('News Total')}
      </Card.Header>
      <Card.Body className="fw-bold text-end h3 m-0 p-2">
        {info.total}
      </Card.Body>
      <Card.Footer className="border-0 p-2 small d-flex justify-content-between align-items-center">
        <i className="fa fa-newspaper"></i>
        <a className="" onClick={() => {
            Navigation.navigate(routes.news.path)
          }}>{Lang.t('See more')}</a>
      </Card.Footer>
    </Card>
  );
}

export default NewsTotal;
