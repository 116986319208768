import React from 'react';

const Page403 = React.lazy(() => import('@adminv2/views/403'))
const Page404 = React.lazy(() => import('@adminv2/views/404'))
const Page405 = React.lazy(() => import('@adminv2/views/405'))
const Page500 = React.lazy(() => import('@adminv2/views/500'))

const routes = {
  Page403 : {
      path  : '*',
      name  : "/403",
      exact : true,
      adminToolKit: true, // Hide admin menu, header, footer
      main  : <Page403 />
  },
  Page404 : {
      path  : '*',
      name  : "/404",
      exact : true,
      adminToolKit: true, // Hide admin menu, header, footer
      main  : <Page404 />
  },
  Page405 : {
      path  : '/405',
      name  : "405",
      exact : true,
      adminToolKit: true, // Hide admin menu, header, footer
      main  : <Page405 />
  },
  Page500 : {
      path  : '/500',
      name  : "500",
      exact : true,
      adminToolKit: true, // Hide admin menu, header, footer
      main  : <Page500 />
  },
}


export default routes;
