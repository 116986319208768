import globalState from '@adminv2/system/globalState';
import Event from '@adminv2/system/event';
import Navigation from '@adminv2/system/navigation';
import routes from '@adminv2/routes';
import GLOBAL from '@adminv2/app.json';
import LaravelEcho from 'laravel-echo';
import axios from 'axios';
import Echo from 'laravel-echo';
window.io = require('socket.io-client');

const Todos = {
  isLogged: () => {
    if (localStorage.getItem('accessToken')) {
      Todos.destroyWebSocket();

      return true;
    }

    return false;
  },
  getBearerToken: () => {
    return localStorage.getItem('accessToken');
  },
  login: data => {
    return new Promise(resolve => {
      axios
        .post(GLOBAL.SERVER + '/api/auth/login', {
          email: data.email,
          password: data.password,
        })
        .then(response => {
          if (
            response.data.errors == null ||
            (Object.keys(response.data.errors).length == 0 && response.data.access_token)
          ) {
            localStorage.setItem('accessToken', response.data.access_token);
          }
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  initWebSocket: accessToken => {
    window.Echo = new LaravelEcho({
      broadcaster: 'socket.io',
      host: GLOBAL.REALTIMESERVER ? GLOBAL.REALTIMESERVER : window.location.hostname + ':' + window.MIX_PUSHER_APP_PORT,
      transports: ['websocket'],
      auth: {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      },
      enabledTransports: ['ws', 'wss'],
    });
    Todos.listenSystem();
  },
  destroyWebSocket: () => {},
  logout: data => {
    return new Promise(resolve => {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(GLOBAL.SERVER + '/api/auth/logout')
        .then(response => {
          resolve({});
        })
        .catch(function (error) {
          resolve({});
        });
      localStorage.removeItem('accessToken');
      Todos.unlistenSystem();
    });
  },
  syncAuthInfo: async () => {
    let json = await Todos.getProfile();
    if (json != null && json.data != null && json.data.id != null) {
      Todos.initWebSocket(globalState.getBearerToken());
      globalState.dispatch({type: 'setAuthInfo', data: json.data});
      const SettingAPIs = require('@adminv2/apis/setting').default;
      let resp = await SettingAPIs.getInfo();
      if (resp.data != null && resp.data.info) {
        globalState.dispatch({
          type: 'root',
          data: {
            system: resp.data.info,
            languages: resp.data.languages,
            log_channels: resp.data.log_channels,
          },
        });
      } else {
        Navigation.navigate(routes.Page404.path);
      }
    }
    return;
  },
  info: (key = '') => {
    if (key != null && key.length > 0) {
      return globalState.getState().auth_info[key] || '';
    }
    return globalState.getState().auth_info || {};
  },
  getEmail: () => {
    return globalState.getState().auth_info?.email;
  },
  getProfile: () => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/auth/info';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  editProfile: data => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/auth/edit';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  changePassword: data => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/auth/password/change';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  hasRole: role => {
    if (
      Array.isArray(globalState.getState().auth_info.roles) &&
      globalState.getState().auth_info.roles.includes(role)
    ) {
      return true;
    }
    return false;
  },
  hasPermission: permission_slug => {
    if (Todos.hasRole('supremacy')) {
      return true;
    }
    if (
      Array.isArray(globalState.getState().auth_info.permissions) &&
      globalState.getState().auth_info.permissions.includes(permission_slug)
    ) {
      return true;
    }
    return false;
  },
  hasPermissions: permissions => {
    if (Todos.hasRole('supremacy')) {
      return true;
    }
    let checked = true;
    for (var i = 0; i < permissions.length; i++) {
      if (
        !Array.isArray(globalState.getState().auth_info.permissions) ||
        !globalState.getState().auth_info.permissions.includes(permissions[i])
      ) {
        checked = false;
      }
    }
    return checked;
  },
  checkHasPermissionIn: permissions => {
    if (Todos.hasRole('supremacy')) {
      return true;
    }
    for (var i = 0; i < permissions.length; i++) {
      if (
        Array.isArray(globalState.getState().auth_info.permissions) &&
        globalState.getState().auth_info.permissions.includes(permissions[i])
      ) {
        return true;
      }
    }
    return false;
  },
  isShowAdminMenu: slug => {
    if (
      !Array.isArray(globalState.getState().system.admin_menus) ||
      globalState.getState().system.admin_menus.length == 0
    ) {
      return true;
    }
    if (
      Array.isArray(globalState.getState().system.admin_menus) &&
      globalState.getState().system.admin_menus.includes(slug)
    ) {
      return true;
    }
    return false;
  },
  isShowAdminMenus: slugs => {
    if (
      !Array.isArray(globalState.getState().system.admin_menus) ||
      globalState.getState().system.admin_menus.length == 0
    ) {
      return true;
    }
    let checked = true;
    for (var i = 0; i < slugs.length; i++) {
      if (
        Array.isArray(globalState.getState().system.admin_menus) &&
        globalState.getState().system.admin_menus.length > 0 &&
        !globalState.getState().system.admin_menus.includes(slugs[i])
      ) {
        checked = false;
      }
    }
    return checked;
  },
  checkShowAdminMenu: slug => {
    return Todos.hasPermission('access-' + slug) || Todos.isShowAdminMenu(slug);
  },
  checkShowAdminMenus: slugs => {
    let permissions = [];
    for (var i = 0; i < slugs.length; i++) {
      permissions.push('access-' + slugs[i]);
    }
    return Todos.checkHasPermissionIn(permissions) || Todos.isShowAdminMenus(slugs);
  },
  hasInShowAdminMenus: slugs => {
    for (var i = 0; i < slugs.length; i++) {
      if (
        Array.isArray(globalState.getState().system.admin_menus) &&
        globalState.getState().system.admin_menus.includes(slugs[i])
      ) {
        return true;
      }
    }
    return false;
  },
  checkAtleastShowAdminMenus: slugs => {
    let permissions = [];
    for (var i = 0; i < slugs.length; i++) {
      permissions.push('access-' + slugs[i]);
    }
    return Todos.checkHasPermissionIn(permissions) || Todos.hasInShowAdminMenus(slugs);
  },
  getSaleChannels: () => {
    return Array.isArray(globalState.getState().system.salechannels) ? globalState.getState().system.salechannels : [];
  },
  hasSaleChannel: code => {
    const salechannels = Todos.getSaleChannels();
    for (var i = 0; i < salechannels.length; i++) {
      if (salechannels[i].code == code) {
        return true;
      }
    }
    return false;
  },
  getMarketingChannels: () => {
    return Array.isArray(globalState.getState().system.marketingchannels)
      ? globalState.getState().system.marketingchannels
      : [];
  },
  hasMarketingChannel: code => {
    const marketingchannels = Todos.getMarketingChannels();
    for (var i = 0; i < marketingchannels.length; i++) {
      if (marketingchannels[i].code == code) {
        return true;
      }
    }
    return false;
  },
  listenSystem: () => {
    window.Echo.channel(`system`)
      .listen('.new-marketing-subscribe', e => {
        Event.trigger('new-marketing-subscribe', e);
      })
      .listen('.new-order', e => {
        Event.trigger('new-order', e);
      });
  },
  unlistenSystem: () => {
    window.Echo.leave(`system`);
  },
};
export default Todos;
