import React from 'react';
import {Button} from 'react-bootstrap'

function ButtonComponent(props) {
  return (
    <Button {...props}>
      {
        props.loading
        ?<span><span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          Loading...</span>
        :props.children
      }
    </Button>
  );
}

export default ButtonComponent;
