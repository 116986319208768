import {useState} from 'react';
// import SimpleBar from 'simplebar-react';
import {
  faBell,
  faCogs,
  faCoins,
  faCubes,
  faFileAlt,
  faHome,
  faImage,
  faInfoCircle,
  faMoneyBill,
  faShop,
  faSignOutAlt,
  faTachometer,
  faTimes,
  faUser,
  faVideo,
  faYinYang,
  faQuestionCircle,
  faTree,
  faUserShield,
  faComment,
  faLanguage
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Accordion, Badge, Button, Image, Nav, Navbar} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';

import {APIs, Lang, routes} from '@adminv2/App';
// import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
// import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
const ReactHero = '';
const ProfilePicture = '';

function SideBar(props = {}) {
  const {pathname} = window.location;
  const [show, setShow] = useState(false);
  const showClass = show ? 'show' : '';
  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = props => {
    let {eventKey, eventKeys, title, icon, children = null} = props;
    let defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : '';
    if (Array.isArray(eventKeys)) {
      for (var i = 0; i < eventKeys.length; i++) {
        if (pathname.indexOf(eventKeys[i]) !== -1) {
          defaultKey = eventKeys[i];
          eventKey = eventKeys[i];
          break;
        }
      }
    }
    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{' '}
              </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = props => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = 'secondary',
      badgeColor = 'primary',
    } = props;
    const classNames = badgeText ? 'd-flex justify-content-start align-items-center justify-content-between' : '';
    const navItemClassName = link === pathname || pathname.includes(link) ? 'active' : '';
    const linkProps = external ? {href: link} : {as: Link, to: link};

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{' '}
              </span>
            ) : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={''}>
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <div className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-2 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h6>Hi, {APIs.info('name')}</h6>
                  <Button as={Link} variant="secondary" size="xs" to={routes.logout.path} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> {Lang.t('Sign Out')}
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              {APIs.checkAtleastShowAdminMenus(['dashboard']) && (
                <NavItem title={Lang.t('Dashboard')} link={routes.dashboard.path} icon={faTachometer} />
              )}

              {/*
                APIs.checkAtleastShowAdminMenus(['product','product-category','product-attribute','product-option','manufacturer']) &&
                <CollapsableNavItem eventKeys={['product','pc','attribute','option','review','manufacturer']} title={Lang.t('Catalog')} icon={faInfoCircle}>
                  {
                    APIs.checkShowAdminMenu('product-category') &&
                    <NavItem title={Lang.t('Category')} link={routes.product_category.path} />
                  }
                  {
                    APIs.checkShowAdminMenu('product') &&
                    <NavItem title={Lang.t('Product')} link={routes.product.path} />
                  }
                </CollapsableNavItem>
              */}
              {APIs.checkAtleastShowAdminMenus(['product']) && (
                <CollapsableNavItem eventKeys={['product', 'pc', 'order', 'review']} title="Shop" icon={faShop}>
                  {APIs.checkAtleastShowAdminMenus(['product-category']) && (
                    <NavItem title="Danh mục Shop" link={routes.product_category.path} />
                  )}
                  {APIs.checkAtleastShowAdminMenus(['product']) && (
                    <NavItem title={'Sản phẩm'} link={routes.product.path} />
                  )}
                  <NavItem title="Đơn hàng" link={routes.order.path} />
                  
                </CollapsableNavItem>
              )}
              {APIs.checkAtleastShowAdminMenus(['product']) && (
                <NavItem title="Đánh giá" link={routes.product_review.path}  icon={faComment}/>
              )}
              {APIs.checkAtleastShowAdminMenus(['product']) && (
                <CollapsableNavItem
                  eventKeys={['batdongsan', 'batdongsan-nc', 'bat-dong-san-order']}
                  title="Bất động sản"
                  icon={faHome}>
                  {APIs.checkAtleastShowAdminMenus(['product-category']) && (
                    <NavItem title="Danh mục bất động sản" link={routes.batdongsan_category.path} />
                  )}
                  {APIs.checkAtleastShowAdminMenus(['bat-dong-san']) && (
                    <NavItem title={'Bất động sản'} link={routes.batdongsan.path} />
                  )}
                  {APIs.checkAtleastShowAdminMenus(['bat-dong-san']) && (
                    <NavItem title={'Dự án'} link={routes.batdongsan_project.path} />
                  )}
                  <NavItem title="Đơn hàng" link={routes.batdongsan_order.path} />
                </CollapsableNavItem>
              )}

              {APIs.checkAtleastShowAdminMenus([
                'khoa-hoc',
                'khoa-hoc-live',
                'khoa-hoc-nc',
                'khoa-hoc-news',
                'khoa-hoc-report',
                'khoa-hoc-order',
              ]) && (
                <CollapsableNavItem
                  eventKeys={[
                    'khoa-hoc',
                    'khoa-hoc-live',
                    'khoa-hoc-nc',
                    'khoa-hoc-news',
                    'khoa-hoc-report',
                    'khoa-hoc-order',
                  ]}
                  title="Đào tạo nghề"
                  icon={faVideo}>
                  {APIs.checkAtleastShowAdminMenus(['product-category']) && (
                    <NavItem title="Danh mục khoá học" link={routes.khoa_hoc_category.path} />
                  )}
                  {APIs.checkAtleastShowAdminMenus(['khoa-hoc']) && (
                    <NavItem title={'Khoá học'} link={routes.khoa_hoc.path} />
                  )}
                  {/* {APIs.checkAtleastShowAdminMenus(["khoa-hoc-live"]) && (
                    <NavItem
                      title={"Khoá học live"}
                      link={routes.khoa_hoc_live.path}
                    />
                  )} */}

                  <NavItem title="Đơn hàng" link={routes.khoa_hoc_order.path} />

                  {APIs.checkAtleastShowAdminMenus(['news']) && (
                    <NavItem title={'Danh mục Bản tin nghề'} link={routes.khoa_hoc_news_category.path} />
                  )}
                  {APIs.checkAtleastShowAdminMenus(['news']) && (
                    <NavItem title={'Bản tin nghề'} link={routes.khoa_hoc_news.path} />
                  )}
                </CollapsableNavItem>
              )}

              {APIs.checkAtleastShowAdminMenus(['tap-tuc-nhang-den']) && (
                <CollapsableNavItem
                  eventKeys={['tap-tuc-nhang-den-nc', 'tap-tuc-nhang-den-news']}
                  title="Tập tục nhang đèn"
                  icon={faVideo}>
                  <NavItem title={'Danh mục'} link={routes.tap_tuc_nhang_den_news_category.path} />
                  <NavItem title={'Tập tục nhang đèn'} link={routes.tap_tuc_nhang_den_news.path} />
                </CollapsableNavItem>
              )}

              {APIs.checkAtleastShowAdminMenus(['order']) && (
                <CollapsableNavItem eventKeys={['ho-so']} title="Hồ sơ" icon={faFileAlt}>
                  <NavItem title="Xem phong thuỷ" link={routes.ho_so_cat.path.replace(':cat', 'xem-phong-thuy')} />
                  <NavItem title="Bất động sản" link={routes.ho_so_cat.path.replace(':cat', 'tu-van-bat-dong-san')} />
                  <NavItem title="Xem ngày" link={routes.ho_so_cat.path.replace(':cat', 'xem-ngay')} />
                  <NavItem title="Kỳ môn" link={routes.ho_so_cat.path.replace(':cat', 'tu-van-ky-mon')} />
                  <NavItem title="Tử vi" link={routes.ho_so_cat.path.replace(':cat', 'tu-van-tu-vi')} />
                  <NavItem title="Bát tự" link={routes.ho_so_cat.path.replace(':cat', 'tu-van-bat-tu')} />
                  <NavItem title="Tướng số" link={routes.ho_so_cat.path.replace(':cat', 'tu-van-tuong-so')} />
                  <NavItem title="Kinh dịch" link={routes.ho_so_cat.path.replace(':cat', 'tu-van-kinh-dich')} />
                  <NavItem title="Chỉ tay" link={routes.ho_so_cat.path.replace(':cat', 'tu-van-chi-tay')} />
                  <NavItem title="Ngoại cảm" link={routes.ho_so_cat.path.replace(':cat', 'tu-van-ngoai-cam')} />
                  <NavItem title="Cảm Xạ" link={routes.ho_so_cat.path.replace(':cat', 'tu-van-cam-xa')} />
                  <NavItem title="Thiền" link={routes.ho_so_cat.path.replace(':cat', 'tu-van-thien')} />
                  <NavItem title="Cúng" link={routes.ho_so_cat.path.replace(':cat', 'tu-van-cung')} />
                  <NavItem title="Đặt tên" link={routes.ho_so_cat.path.replace(':cat', 'tu-van-dat-ten')} />
                  <NavItem title="Thiết kế" link={routes.ho_so_cat.path.replace(':cat', 'tu-van-thiet-ke')} />
                </CollapsableNavItem>
              )}

              {APIs.checkAtleastShowAdminMenus(['config-order']) && (
                <CollapsableNavItem
                  eventKeys={['ky-mon', 'vai-ve', 'nghe-nghiep']}
                  title={Lang.t('Config order')}
                  icon={faTree}>
                  <NavItem title={'Chủ đề xem kỳ môn'} link={routes.config_ky_mon.path} />
                  <NavItem title={'Vai vế'} link={routes.family_tree_vaive.path} />
                  <NavItem title={'Nghề nghiệp'} link={routes.family_tree_nghenghiep.path} />
                  <NavItem title={'Trình độ học vấn'} link={routes.academic_level.path} />
                </CollapsableNavItem>
              )}

              {APIs.checkAtleastShowAdminMenus(['family-tree']) && (
                <CollapsableNavItem
                  eventKeys={['family-tree', 'family-tree-nha-tho-ho','family-tree-mo-to']}
                  title={Lang.t('Family Tree')}
                  icon={faTree}>
                  
                  <NavItem title={Lang.t('Nhà thờ họ')} link={routes.nha_tho_ho.path} />
                  <NavItem title={Lang.t('Mộ tổ')} link={routes.mo_to.path} />
                  <NavItem title={Lang.t('Family Tree')} link={routes.family_tree.path} />
                </CollapsableNavItem>
              )}
              

              {APIs.checkAtleastShowAdminMenus(['marketing-notification']) && (
                <NavItem
                  title={Lang.t('Marketing Notification')}
                  link={routes.marketing_notification.path}
                  icon={faBell}
                />
              )}

              {APIs.checkAtleastShowAdminMenus(['marketing-customer-care-schedule']) && (
                <NavItem
                  title={Lang.t('Customer Care')}
                  link={routes.customer_care_schedule.path}
                  icon={faUserShield}
                />
              )}

              {/*
                APIs.checkAtleastShowAdminMenus(['news']) &&
                <CollapsableNavItem eventKeys={['news','nc']} title={Lang.t('News')} icon={faNewspaper}>
                  {
                    APIs.checkShowAdminMenu('news-category') &&
                    <NavItem title={Lang.t('Category')} link={routes.news_category.path} />
                  }
                  {
                    APIs.checkShowAdminMenu('news') &&
                    <NavItem title={Lang.t('News')} link={routes.news.path} />
                  }
                </CollapsableNavItem>
              */}

              {APIs.checkAtleastShowAdminMenus(['customer']) && (
                <CollapsableNavItem
                  eventKeys={['customer', 'customergroup', 'custom-field']}
                  title={Lang.t('Customer')}
                  icon={faUser}>
                  <NavItem title={Lang.t('Customer')} link={routes.customer.path} />
                  {APIs.checkAtleastShowAdminMenus(['customer-group']) && (
                    <NavItem title={Lang.t('Customer Group')} link={routes.customer_group.path} />
                  )}
                  {APIs.checkAtleastShowAdminMenus(['anemometer']) && (
                    <NavItem title={Lang.t('Anemometer')} link={routes.anemometer.path} />
                  )}
                  {APIs.checkAtleastShowAdminMenus(['tranfer-device-order']) && (
                    <NavItem
                      title="Yêu cầu chuyển giao thiết bị"
                      link={routes.ho_so_cat.path.replace(':cat', 'tranfer-device-order')}
                    />
                  )}
                  {APIs.checkAtleastShowAdminMenus(['customer-subscription']) && (
                    <NavItem title={Lang.t('Subscription Histories')} link={routes.customer_subscription.path} />
                  )}
                </CollapsableNavItem>
              )}

              {APIs.checkAtleastShowAdminMenus(['fengshuimaster']) && (
                <CollapsableNavItem
                  eventKeys={['fengshuimaster', 'fengshuimaster-group']}
                  title={Lang.t('Fengshui Master')}
                  icon={faYinYang}>
                  <NavItem title={Lang.t('Fengshui Master')} link={routes.fengshuimaster.path} />
                  <NavItem title={Lang.t('FengshuiMaster Group')} link={routes.fengshuimaster_group.path} />
                  <NavItem title={Lang.t('FengshuiMaster Status')} link={routes.fengshuimaster_status.path} />
                </CollapsableNavItem>
              )}

              {(APIs.hasRole('supremacy') || APIs.checkAtleastShowAdminMenus(['user'])) && (
                <>
                  <CollapsableNavItem eventKeys={['user', 'role']} title={Lang.t('User')} icon={faUser}>
                    {APIs.checkAtleastShowAdminMenus(['user']) && (
                      <NavItem title={Lang.t('User')} link={routes.user.path} />
                    )}
                    {APIs.hasRole('supremacy') && <NavItem title={Lang.t('Role')} link={routes.role.path} />}
                  </CollapsableNavItem>
                </>
              )}

              {APIs.checkAtleastShowAdminMenus(['information']) && (
                <NavItem title={Lang.t('Information')} link={routes.information.path} icon={faInfoCircle} />
              )}
              {APIs.checkAtleastShowAdminMenus(['banner']) && (
                <NavItem eventKey="banner" title={Lang.t('Banner')} link={routes.banner.path} icon={faImage} />
              )}
              {APIs.checkAtleastShowAdminMenus(['subscription-plan']) && (
                <CollapsableNavItem
                  eventKeys={['subscription-plan', 'order']}
                  title={'Quản lý gói Premium'}
                  icon={faMoneyBill}>
                  <NavItem title={'Gói Premium'} link={routes.subscription_plan.path} />
                  <NavItem title={'Đơn hàng'} link={routes.subscription_plan_order.path} />
                </CollapsableNavItem>
              )}
              {APIs.checkAtleastShowAdminMenus(['coin-package']) && (
                <CollapsableNavItem eventKeys={['coin-package', 'order']} title={'Quản lý đồng Thầy AI'} icon={faCoins}>
                  <NavItem title={'Gói đồng Thầy AI'} link={routes.coin_package.path} />
                  <NavItem title={'Đơn hàng'} link={routes.coin_package_order.path} />
                </CollapsableNavItem>
              )}
              {APIs.checkAtleastShowAdminMenus(['faqs', 'faqs-category']) && (
                <CollapsableNavItem eventKeys={['faqs', 'fc']} title={Lang.t('FAQs')} icon={faQuestionCircle}>
                  {APIs.checkShowAdminMenu('faqs-category') && (
                    <NavItem title={Lang.t('Category')} link={routes.FAQ_category.path} />
                  )}
                  {APIs.checkShowAdminMenu('faqs') && <NavItem title={Lang.t('FAQs')} link={routes.FAQs.path} />}
                </CollapsableNavItem>
              )}

              {APIs.checkAtleastShowAdminMenus(['setting']) && (
                <NavItem eventKey="setting" title={Lang.t('Setting')} link={routes.setting.path} icon={faCogs} />
              )}

              {APIs.checkAtleastShowAdminMenus(['translation']) && (
                <NavItem title={Lang.t('Translation')} link={routes.translation.path} icon={faLanguage} />
              )}

              {APIs.checkAtleastShowAdminMenus(['floorplan', 'floorplan-category']) && (
                <>
                  <CollapsableNavItem
                    eventKeys={['floorplan', 'foc']}
                    title={Lang.t('Floorplan Object')}
                    icon={faCubes}>
                    {APIs.checkAtleastShowAdminMenus(['floorplan-category']) && (
                      <NavItem title={Lang.t('Category')} link={routes.floorplan_category.path} />
                    )}
                    {APIs.checkAtleastShowAdminMenus(['floorplan']) && (
                      <NavItem title={Lang.t('Object')} link={routes.floorplan.path} />
                    )}

                    {APIs.checkAtleastShowAdminMenus(['floorplan-texture']) && (
                      <NavItem title={Lang.t('Texture')} link={routes.floorplan_texture.path} />
                    )}

                    {/* <NavItem title={Lang.t('Hình thế')} link={routes.floorplan_hinh_the.path} /> */}
                    {/* <NavItem title={Lang.t('Attribute')} link={routes.floorplan_attribute.path} /> */}
                  </CollapsableNavItem>
                </>
              )}
              {APIs.checkAtleastShowAdminMenus([
                'floorplan-luan-giai',
                'floorplan-hoa-giai',
                'floorplan-luan-giai-case',
                'floorplan-luan-giai-type',
                'loai-cong-trinh',
              ]) && (
                <>
                  <CollapsableNavItem
                    eventKeys={[
                      'floorplan-luan-giai',
                      'floorplan-hoa-giai',
                      'floorplan-case-luan-giai',
                      'floorplan-luan-giai-type',
                      'loai-cong-trinh',
                      'floorplan-vai-tro',
                      'diem-tu',
                    ]}
                    title={'Luận giải'}
                    icon={faCubes}>
                    {APIs.checkAtleastShowAdminMenus(['floorplan-luan-giai-case']) && (
                      <NavItem title={'Trường hợp Luận giải'} link={routes.floorplan_luan_giai_case.path} />
                    )}
                    {APIs.checkAtleastShowAdminMenus(['floorplan-luan-giai']) && (
                      <NavItem title={'Thư viện Luận giải'} link={routes.floorplan_luan_giai.path} />
                    )}
                    {APIs.checkAtleastShowAdminMenus(['floorplan-hoa-giai']) && (
                      <NavItem title={'Thư viện hoá giải'} link={routes.floorplan_hoa_giai.path} />
                    )}

                    {APIs.checkAtleastShowAdminMenus(['floorplan-luan-giai-type']) && (
                      <NavItem title={'Loại Luận giải'} link={routes.floorplan_luan_giai_type.path} />
                    )}

                    {APIs.checkAtleastShowAdminMenus(['loai-cong-trinh']) && (
                      <NavItem title={'Loại Công trình'} link={routes.loai_cong_trinh.path} />
                    )}
                    {APIs.checkAtleastShowAdminMenus(['floorplan-vai-tro']) && (
                      <NavItem title={'Vai trò luận giải'} link={routes.floorplan_vai_tro.path} />
                    )}
                    {APIs.checkAtleastShowAdminMenus(['diem-tu']) && (
                      <NavItem title={'Điểm tử'} link={routes.diem_tu.path} />
                    )}
                  </CollapsableNavItem>
                </>
              )}
            </Nav>
          </div>
        </div>
      </CSSTransition>
    </>
  );
}

export default SideBar;
