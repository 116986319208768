import {Button, Image} from 'react-bootstrap';
import {ErrorBoundary} from 'react-error-boundary';
import TelegramLog from './TelegramBotCuaPhuc';

import ErrorImage from '@adminv2/assets/image/ErrorBoundary.png';

function ErrorFallback({error, resetErrorBoundary}) {
  return (
    <div style={{textAlign: 'center'}}>
      <div
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Image src={ErrorImage} width={300} preview={false} />
        <h2>Đã xảy ra lỗi, vui lòng thử lại</h2>
        <Button
          onClick={() => {
            resetErrorBoundary();
          }}>
          Trở lại!
        </Button>
      </div>
    </div>
  );
}

export default function MyErrorBoundary({children}) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error, info) => {
        console.log(error);
        TelegramLog('[Admin] - ' + error.stack.toString());
      }}>
      {children}
    </ErrorBoundary>
  );
}
