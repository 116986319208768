import React, {useEffect} from 'react';
import {Image, Form} from 'react-bootstrap';

import Dragula from 'react-dragula';
import 'react-dragula/dist/dragula.css';

import Lang from '@adminv2/system/language';
import APIs from '@adminv2/apis';
import * as Components from '@adminv2/components/index';

function MultiCategoryPicker(props) {
  const [loading, setLoading] = React.useState(false);
  const [full_categories, setFullCategories] = React.useState([]);
  const filter = props.filter || {};
  const getCategories = async () => {
    setLoading(true);
    let response = await APIs.catalog.getCategoryList({
      filter: {
        ...filter,
        ids: props.value,
      },
    });
    if (response.data != null && Array.isArray(response.data.results)) {
      let cates = [];
      for (var i = 0; i < response.data.results.length; i++) {
        if (props.value.includes(response.data.results[i].id)) {
          cates.push(response.data.results[i]);
        }
      }
      setFullCategories(cates);
    }
    setLoading(false);
  };

  const dragulaDecorator = componentBackingInstance => {
    if (componentBackingInstance) {
      Dragula([componentBackingInstance], {});
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <Components.ProductCategoryPicker
        label={props.label}
        filter={filter}
        onChange={category => {
          let categories = props.value || [];
          let fc = full_categories;
          if (!categories.includes(category.id)) {
            categories.push(category.id);
            fc.push(category);
          }
          props.onChange(categories);
          setFullCategories(fc);
        }}
      />
      <div className="well bg-light border mb-3" ref={dragulaDecorator}>
        {(Array.isArray(full_categories) ? full_categories : []).map((category, key) => {
          return (
            <div className="well-item" key={key}>
              <span className="handle ps-2 pe-2">
                <i className="fa fa-arrows-alt"></i>
              </span>
              <a
                onClick={() => {
                  let categories = props.value || [];
                  let fc = full_categories;
                  if (categories.includes(category.id)) {
                    categories.splice(categories.indexOf(category.id), 1);
                    fc.splice(fc.indexOf(category), 1);
                  }
                  props.onChange(categories);
                  setFullCategories(fc);
                }}>
                <i className="fa fa-minus-circle"></i>
              </a>
              {category.name}
            </div>
          );
        })}
      </div>
    </>
  );
}

export default MultiCategoryPicker;
