import React from 'react';

const Translation = React.lazy(() => import('@adminv2/views/translation/Translation'))

const routes = {
  translation : {
      path : '/translation',
      name: "Translation",
      exact : true,
      main : <Translation />
  },
}


export default routes;
