import { useState } from "react";
import { Form, Image } from "react-bootstrap";

import "react-dragula/dist/dragula.css";

import { APIs } from "@adminv2/App";
import ImageViewer from "react-simple-image-viewer";
import { useDropzone } from "react-dropzone";

function FilesInputPicker(props) {
  const value = props.value || [];
  const onChange = props.onPick;
  const [dataAttachFile, setDataAttachFile] = useState({});
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    acceptedFiles: {
      "image/*": [],
    },
    onDrop: async (acceptedFiles) => {
      let arrPromise = [];
      let arrURLImg = [];
      acceptedFiles.map((item) => {
        setDataAttachFile({
          loading: true,
          attach_files: acceptedFiles,
        });
        arrPromise.push(
          new Promise((resolve) => {
            resolve(APIs.FileManager.uploadFile(item));
          })
        );
      });
      await Promise.all(arrPromise).then((value) => {
        value.map((item) => {
          arrURLImg.push({ image: item.data.url, ...item.data });
        });
      });
      onChange([...value, ...arrURLImg]);
      setDataAttachFile({
        loading: false,
        attach_files: [],
      });
    },
  });
  return (
    <div className="d-flex flex-wrap">
      {(Array.isArray(value) ? value : []).map((image, key) => (
        <div
          key={key}
          className="position-relative p-2 border overflow-hidden mb-2 me-2 preview-container"
          style={{ width: "31%", maxWidth: 150 }}
          onClick={() => setIsViewerOpen(true)}
        >
          <div className="handle d-flex justify-content-center align-items-center mb-2 me-2 w-100 ratio ratio-1x1">
            <img alt="" src={image.image} className="w-100" />
            <div className="position-absolute top-1 right-1">
              <a
                className="text-danger"
                onClick={() => {
                  let images = value || [];
                  images.splice(images.indexOf(image), 1);
                  onChange(images);
                }}
              >
                <i className="fa fa-trash-alt"></i>
              </a>
            </div>
          </div>
          {props.enableNameInput == true && (
            <Form.Group className="">
              <Form.Control
                type="text"
                size="sm"
                placeholder="Tên ảnh"
                value={image.name || ""}
                onChange={(e) => {
                  let images = value || [];
                  for (let i = 0; i < images.length; i++) {
                    if (images[i].image == image.image) {
                      images[i].name = e.target.value;
                      break;
                    }
                  }
                  onChange(images);
                }}
              />
            </Form.Group>
          )}
        </div>
      ))}
      {dataAttachFile.loading &&
        dataAttachFile.attach_files?.map((item, key) => (
          <div
            key={key}
            className="position-relative p-2 border overflow-hidden mb-2 me-2 preview-container"
            style={{ width: "31%", maxWidth: 150 }}
          >
            <div className="handle d-flex justify-content-center align-items-center mb-2 me-2 w-100 ratio ratio-1x1">
              <img src={"/images/file-placeholder.jpg"} className="w-100" />
              <div className="position-absolute left-0 right-0 top-3">
                <span>
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </span>
              </div>
            </div>
          </div>
        ))}
      <div
        {...getRootProps({ className: "dropzone" })}
        className="p-2 border overflow-hidden mb-2 me-2 preview-container"
        style={{ width: "31%", maxWidth: 150 }}
      >
        <input {...getInputProps()} />
        <Image src={"/image/others/add-image.png"} style={{ width: "100%" }} />
      </div>
      {isViewerOpen && (
        <ImageViewer
          src={value.map((item) => item.image)}
          // currentIndex={ currentImage }
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => setIsViewerOpen(false)}
        />
      )}
    </div>
  );
}

export default FilesInputPicker;
