import React,{useState,useEffect} from 'react'
import {
  Table,ButtonGroup, Button,Image,
  Card,OverlayTrigger,Tooltip,
  Row,Col,Form,Modal
} from 'react-bootstrap'

import {
  Components, APIs, routes,
  globalState, View, Lang, Navigation,
  Notify
} from '@adminv2/App'

import moment from 'moment'

function CustomerOrders( props ){
  const data = props.data || {}
  const [loading, setLoading] = React.useState()
  const [results, setResults] = React.useState(data.orders || [])

  const getData = async() => {
    setLoading(true)
    const response = await APIs.customer.getOrders(props.id)
    setLoading(false)
    if (response.data && Array.isArray(response.data)) {
      setResults(response.data)
    }
  }

  React.useEffect(() => {
    getData()
  },[])

  return (
    <>
      <Form.Label>Đơn hàng đã thực hiện</Form.Label>
      <Table bordered striped responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Loại hồ sơ</th>
            <th>{Lang.t('Total')}</th>
            <th>{Lang.t('Status')}</th>
            <th>{Lang.t('created_at')}</th>
          </tr>
        </thead>
        <tbody>
          {
            loading &&
            <tr>
              <td colSpan={5}><Components.LoadingIndicator/></td>
            </tr>
          }
          {
            !loading && results.length == 0 &&
            <tr>
              <td colSpan={5}>Chưa có đơn hàng</td>
            </tr>
          }
          {results.map((order, key) => {
            return(
              <tr>
                <td><a onClick={() => {
                    window.open(routes.order_edit.path.replace(':id',order.id),'_blank')
                  }}>{order.code}</a></td>
                <td>
                  {
                    order.type &&
                    <a onClick={() => {
                      window.open(routes.ho_so_cat.path.replace(':cat',order.type),'_blank')
                    }}>{order.type_name}</a>
                  }
                </td>
                <td>{order.total_format}</td>
                <td>
                  <span style={{color: order.order_status_color}}>{order.order_status_name}</span>
                </td>
                <td>
                  {moment(order.created_at).format('HH:mm DD-MM-YYYY')}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export default CustomerOrders
