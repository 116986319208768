import globalState from "@adminv2/system/globalState";
import GLOBAL from "@adminv2/app.json";
import axios from "axios";
const Todos = {
  getList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/familytree/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/familytree/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  create: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/familytree/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getInfo: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/familytree/" + id + "/info";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getFamilyTree: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/familytree/" + id + "/family-tree";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  edit: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/familytree/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  delete: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/familytree/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  deletes: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/familytree/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getMemberForm: (familytree_id, id = 0) => {
    return new Promise((resolve) => {
      let url =
        GLOBAL.SERVER +
        "/api/user/familytree/" +
        familytree_id +
        "/members/form?id=" +
        id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  memberCreate: (familytree_id, data) => {
    return new Promise((resolve) => {
      let url =
        GLOBAL.SERVER +
        "/api/user/familytree/" +
        familytree_id +
        "/members/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getMemberInfo: (familytree_id, id) => {
    return new Promise((resolve) => {
      let url =
        GLOBAL.SERVER +
        "/api/user/familytree/" +
        familytree_id +
        "/members/" +
        id +
        "/info";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  memberEdit: (familytree_id, id, data) => {
    return new Promise((resolve) => {
      let url =
        GLOBAL.SERVER +
        "/api/user/familytree/" +
        familytree_id +
        "/members/" +
        id +
        "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  memberDelete: (familytree_id, id) => {
    return new Promise((resolve) => {
      let url =
        GLOBAL.SERVER +
        "/api/user/familytree/" +
        familytree_id +
        "/members/" +
        id +
        "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  editorCreate: (familytree_id, customer_id) => {
    return new Promise((resolve) => {
      let url =
        GLOBAL.SERVER +
        "/api/user/familytree/" +
        familytree_id +
        "/editors/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, {
          customer_id: customer_id,
        })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  editorDelete: (familytree_id, customer_id) => {
    return new Promise((resolve) => {
      let url =
        GLOBAL.SERVER +
        "/api/user/familytree/" +
        familytree_id +
        "/editors/" +
        customer_id +
        "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  // Vai vế
  getVaiveList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/familytree-vai-ve/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getVaiveForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/familytree-vai-ve/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  createVaive: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/familytree-vai-ve/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  editVaive: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/familytree-vai-ve/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  deleteVaive: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/familytree-vai-ve/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  deletesVaive: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/familytree-vai-ve/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  // Nghề nghiệp
  getNgheNghiepList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/nghe-nghiep/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getNgheNghiepForm: (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/nghe-nghiep/form?id=" + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  createNgheNghiep: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/nghe-nghiep/add";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  editNgheNghiep: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/nghe-nghiep/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  deleteNgheNghiep: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/nghe-nghiep/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  deletesNgheNghiep: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/nghe-nghiep/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  // Mo to
  getMotoSumary: () => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/mo-to/sumary";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getMotoList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/mo-to/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getMotoInfo: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/mo-to/" + id + "/info";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  editMoto: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/mo-to/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  deleteMoto: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/mo-to/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  deletesMoto: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/mo-to/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  // Nha tho ho.
  getNhaThoHoSumary: () => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/nha-tho-ho/sumary";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getNhaThoHoList: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/nha-tho-ho/list";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getNhaThoHoInfo: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/nha-tho-ho/" + id + "/info";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  editNhaThoHo: (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/nha-tho-ho/" + id + "/update";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  deleteNhaThoHo: (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/nha-tho-ho/" + id + "/delete";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  deletesNhaThoHo: (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + "/api/user/nha-tho-ho/deletes";
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, { selected: selected })
        .then((response) => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
};
export default Todos;
