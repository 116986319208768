
import React, { useEffect,useState } from "react";
import {
  Button
} from 'react-bootstrap'

function GoogleLoginButton (props = {}) {
    const scriptId = "google-client-script"
    const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false)

    const prepareGSI = () => {
      // document.getElementById(scriptId)?.remove()
      // if (document.getElementById(scriptId)) return
      const script = document.createElement("script")
      script.src = "https://accounts.google.com/gsi/client"
      script.onload = () => {
        requestAccessToken()
      }
      script.async = true
      script.id = scriptId
      document.querySelector("body")?.appendChild(script)
    }

    const requestAccessToken = () => {
      // console.log(window.google.accounts.oauth2)
      const client = window.google.accounts.oauth2.initTokenClient({
        client_id: props.clientId,
        scope: props.scope || '',
        callback: async(res) => {
          if (res.access_token){
            props.onSuccess(res)
          }else{
            props.onError(res)
          }
        },
        ux_mode: props.uxMode || 'popup'
      })
      client.requestAccessToken()
    }

    return (
      <Button variant="primary" onClick={() => {
          if (window.google) {
            requestAccessToken()
          }else{
            prepareGSI()
          }
        }}>{props.title || 'Login Google'}</Button>
    );
}

export default GoogleLoginButton;
