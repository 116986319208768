import React from 'react';
import {Pagination,Nav} from 'react-bootstrap'
import {globalState} from '@adminv2/App'

import '@adminv2/scss/components/common/SortItem.scss'

function SortItem(props) {
  const [sort, updateSort] = React.useState(globalState.getState().sort || '');
  const [order, updateOrder] = React.useState(globalState.getState().order || '');
  const onSort = () => {
    let afterSort = props.sort
    let afterOrder = order == 'desc' ? 'asc' : 'desc'
    updateSort(afterSort)
    updateOrder(afterOrder)
    globalState.dispatch({type: 'root', data: {sort: afterSort,order: afterOrder}})
    props.onSort()
  }

  return (
      <>
        <a className="sort-item" onClick={onSort}>
          {props.children}
          {
            globalState.getState().sort == props.sort &&
            <>
              {
                globalState.getState().order == 'desc'
                ?<i className="fa fa-caret-down ms-2"></i>
                :<i className="fa fa-caret-up ms-2"></i>
              }
            </>
          }
        </a>
      </>
  );
}

export default SortItem;
