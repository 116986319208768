
import React,{useEffect} from 'react';
import { Image,Form } from 'react-bootstrap';

import Lang from '@adminv2/system/language'
import APIs from '@adminv2/apis'

const Todos = APIs.layout;

function BannerPicker(props) {
  const [loading, setLoading] = React.useState(false);
  const [banners, setBanners] = React.useState(props.banners || []);
  const inputName = props.name || '';
  const errors = props.errors || {}
  const value = props.value || ''

  const getBanners = async() => {
    setLoading(true)
    let response = await Todos.getBannerList({
      limit: 1000
    })
    if (response.data!=null && Array.isArray(response.data.results)) {
      setBanners(response.data.results)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (banners.length == 0) {
      getBanners()
    }
  },[]);

  return (
    <Form.Group className={props.className || ""}>
        <Form.Select name={inputName} disabled={loading} value={value} className={ props.selectClassName + ' ' + (errors!=null && errors[inputName]!=null ? 'is-invalid' : '')} onChange={event => props.onChange(event.target.value)}>
          <option value=""> -- {Lang.t('Select Banner')} -- </option>
            {(Array.isArray(banners) ? banners : []).map((banner, key) => {
              return(
                <option value={banner.id}>{banner.name}</option>
              )
            })}
        </Form.Select>
        {
          errors[inputName] &&
          <div className="invalid-feedback">
            {errors[inputName]!=null ? errors[inputName][0] : ''}
          </div>
        }
    </Form.Group>
  );
};

export default BannerPicker;
