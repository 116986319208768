import globalState from '@adminv2/system/globalState'
import GLOBAL from "@adminv2/app.json";
import axios from "axios";
const Todos = {
  getInfo : () => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/setting/info'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getGeneralForm : () => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/setting/form'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  editGeneralSetting : (data={}) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/setting/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getExtensions : (extensionTypes = []) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/setting/extensions?types=' + extensionTypes.join(",")
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  installExtension : (extensionType, extensionCode) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/setting/extensions/install'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{
        type: extensionType,
        code: extensionCode
      }).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  uninstallExtension : (extensionType, extensionCode) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/setting/extensions/uninstall'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{
        type: extensionType,
        code: extensionCode
      }).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  editExtension : (extensionType, extensionCode, data = {}) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/setting/extensions/edit'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{
        type: extensionType,
        code: extensionCode,
        ...data
      }).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getExtensionForm : (extension_id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/setting/extensions/' + extension_id + '/form'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  extensionUpdate : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/setting/extensions/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  extensionUpdates : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/setting/extensions/updates'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getModuleForm : (extension_code, module_id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/setting/extensions/' + extension_code + '/module/form?id=' + module_id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  moduleAdd : (extension_code, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/setting/extensions/' + extension_code + '/module/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  moduleEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/setting/extensions/module/' + id + '/edit'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  moduleUpdate : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/setting/extensions/module/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  moduleDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/setting/extensions/module/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getStoreList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/store/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getStoreForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/store/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  storeCreate : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/store/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  storeEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/store/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  storeDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/store/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  storeDeletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/store/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getStoreBankList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/store-bank/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getStoreBankForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/store-bank/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  storeBankCreate : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/store-bank/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  storeBankEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/store-bank/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  storeBankDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/store-bank/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  storeBankDeletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/store-bank/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  // ---
  getThemeList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/store-theme/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getThemeForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/store-theme/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  themeCreate : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/store-theme/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  themeEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/store-theme/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  themeDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/store-theme/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  themeDeletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/store-theme/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
}
export default Todos
