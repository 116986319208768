import React,{useState,useCallback,useMemo} from 'react';
import {
  Image,Modal,ButtonToolbar,ButtonGroup,Button,Tabs,Tab,
  Row, Col,Form,Breadcrumb,Accordion,Dropdown,InputGroup,
  Table,Pagination,OverlayTrigger,Tooltip
} from 'react-bootstrap'

import Dropzone,{useDropzone} from 'react-dropzone'

import {Lang, APIs, Components} from '@adminv2/App'

import ReactCrop from 'react-image-crop'
import 'react-image-crop/src/ReactCrop.scss'

import '@adminv2/scss/components/common/FileInputPicker.scss'


function FileInputPicker(props) {
  const [display, setDisplay] = useState('grid');
  const [working_dir, setWorkingDir] = useState('/');
  const [page, setPage] = useState(1);

  const [data, setData] = useState({});
  const [items, setItems] = useState([]);
  const [breadcrums, setBreadcrums] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selected, setSelected] = useState([]);
  const [picked, setPicked] = useState([]);
  const [loading, setLoading] = useState(false);

  const [folderStructureLoading, setFolderStructureLoading] = useState(false);
  const [folderStructures, setFolderStructures] = useState([]);

  const [showNewFolderForm, setShowNewFolderForm] = useState(false);

  const [editImage, setEditImage] = useState({});
  

  let inputUploadRef = React.useRef()
  const accept = props.accept || ''
  const value = props.value
  const onChange = props.onPick
  let placeholder = require('@adminv2/assets/image/image-placeholder.jpg').default;
  if (props.placeholder) {
    placeholder = props.placeholder
  }
  if (props.type == 'file') {
    placeholder = require('@adminv2/assets/image/file-placeholder.jpg').default;
  }

  const openPickFiles = async() => {
    setEditImage({})
    setShow(true)
    if (!Array.isArray(folderStructures) || folderStructures.length == 0) {
      getFolderStructures()
    }
    getItems({
      working_dir: working_dir,
      page: page,
      accept: accept
    })
  }

  const getFolderStructures = async() => {
    setFolderStructureLoading(true)
    let response = await APIs.FileManager.getFolderStructures()
    // console.log(response.data)
    if (response.data!=null) {
      setFolderStructures(response.data)
    }
    setFolderStructureLoading(false)
  }

  const renderFolderStructure = (structure) => {
    if (Array.isArray(structure.children)) {
      return(
        <>
          <li className={"folder-structure-item " + ( working_dir == structure.url ? 'active' : '')}><a onClick={() => {goToFolder(structure)}}><i className="fa fa-folder"></i> {structure.name}</a></li>
          <ul className="folder-structure-item">
            {structure.children.map((child, key) => {
              if(!child){return (<></>)}
              return renderFolderStructure(child)
            })}
          </ul>
        </>
      )
    }else{
      return(
        <li className={"folder-structure-item" + ( working_dir == structure.url ? 'active' : '')}><a onClick={() => {goToFolder(structure)}}><i className="fa fa-folder"></i> {structure.name}</a></li>
      )
    }
  }

  const getItems = async(data) => {
    setLoading(true)
    let response = await APIs.FileManager.getItems(data)
    if (Array.isArray(response.data.results)) {
      setData(response.data)
      setItems(response.data.results)
      setBreadcrums(response.data.breadcrums)
    }
    setLoading(false)
  }

  const onSelect = (event,select) => {
    let files = []
    if (event.shiftKey) {
      // Select multi
      files = selected.slice()
      if (!selected.includes(select)) {
        files.push(select)
      }else{
        files = files.filter(item => item !== select)
      }
    } else {
      // Select one
      if (!selected.includes(select)) {
        files = [select]
      }
    }
    setSelected(files)
    if (!picked.includes(select) && select.is_file) {
      setPicked([select])
    }else{
      setPicked([])
    }
  }

  const goToFolder = (item) => {
    if (!item.is_file) {
      setSelected([])
      setPage(1)
      setWorkingDir(item.url)
      getItems({
        working_dir: item.url,
        page: 1,
        accept: accept
      })
    }
  }

  const uploadFiles = async(files) => {
    setLoading(true)
    let response = await APIs.FileManager.uploadFiles({
      working_dir: working_dir,
      uploads: files
    })
    setLoading(false)
    getItems({
      working_dir: working_dir,
      page: 1,
      accept: accept
    })
  }

  const deleteSelectedFile = async() => {
    if (window.confirm("You are about to permanently delete this item from your site.\nThis action cannot be undone.\nCancel to stop, 'OK' to delete.") == true) {
      let items = []
      let hasFolder = false
      for (var i = 0; i < selected.length; i++) {
        items.push(selected[i].name)
        if (!hasFolder && !selected[i].is_file) {
          hasFolder = true
        }
      }
      setLoading(true)
      let response = await APIs.FileManager.deleteFiles({
        working_dir: working_dir,
        items: items
      })
      setLoading(false)
      setSelected([])
      if (hasFolder) {
        getFolderStructures()
      }
      getItems({
        working_dir: working_dir,
        page: page,
        accept: accept
      })
    }

  }

  const onClickAtPage = async(pg = 1) => {
    setPage(pg)
    getItems({
      working_dir: working_dir,
      page: pg,
      accept: accept
    })
  }

  const createNewFolder = async(data) => {
    setLoading(true)
    let response = await APIs.FileManager.createNewFolder({
      working_dir: working_dir,
      name: data.name
    })
    setLoading(false)
    if (response.errors==null || Object.keys(response.errors).length == 0) {
      setShowNewFolderForm(false)
      getFolderStructures()
      getItems({
        working_dir: working_dir,
        page: page,
        accept: accept
      })
    }
  }
  const checkAvailablePickingFile = Array.isArray(picked) && picked.length > 0 && ( props.type == 'image' ? picked[0].is_image : true )
  const route = editImage.url!=null ? 'edit' : 'list'
  const modalTitle = route == 'edit' ? Lang.t('Edit Image') : (props.title ? props.title : Lang.t('Avatar'))
   
  const total_page = data.total_page / data.per_page > 1 ? ( data.total_page / data.per_page - Math.round(data.total_page / data.per_page) <=0 ? Math.round(data.total_page / data.per_page) : Math.round(data.total_page / data.per_page) + 1 ) : 1

  return (
    <div className="d-flex">
      <div {...props} className="file-picker position-relative">
        {
          props.type == 'image' &&
          <div type="button" onClick={openPickFiles} className="position-relative bg-white p-1 border d-flex justify-content-center align-items-center overflow-hidden preview-container" style={{width: props.width ?? 120,height: props.height ?? 120}}>
            <Image src={value ? value : placeholder} style={Styles.imageFileInputPicker}/>
          </div>
        }
        {
          props.type == 'file' &&
          <InputGroup>
            <Form.Control
              value={value}
              onChange={event => {
                // console.log(event.target.value);
                props.onPick({
                  url: event.target.value
                })
              }}
            />
          <InputGroup.Text className="btn btn-success d-flex align-items-center" onClick={() => {
              openPickFiles()
            }}><i className="fas fa-file-video"></i></InputGroup.Text>
          </InputGroup>
        }
        {
          value &&
          <div className="position-absolute top-1 right-1">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={(
                <Tooltip {...props}>
                    {Lang.t('Clear')}
                </Tooltip>
              )}
            >
              <a className="text-danger" onClick={() => {
                props.onPick({
                  url: ''
                })
              }}><i className="fa fa-trash-alt"></i></a>
            </OverlayTrigger>
          </div>
        }
      </div>
      <Modal show={show} onHide={handleClose} animation={false} dialogClassName="fm-core-ui modal-90w modal-90h overflow-hidden rounded-0">
        <Modal.Header closeButton>
          <Modal.Title>
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="rounded-0">
          {
            route == 'edit'
            ?<EditImageScreen
              data={editImage}
              workingDir={working_dir}
              onSuccess={() => {
                openPickFiles()
              }}
            />
            :<Row className="h-100">
              <Col xs={12} sm={3} className="h-100 d-none d-sm-block tree-structure">
                <div className="media-sidebar p-2">
                  {
                    folderStructureLoading &&
                    <Components.LoadingIndicator/>
                  }
                  {
                    !folderStructureLoading &&
                    <div>
                      {renderFolderStructure(folderStructures)}
                    </div>
                  }
                </div>
              </Col>
              <Col xs={12} sm={6} className="h-100 position-relative">
                {
                  (Array.isArray(breadcrums) ? breadcrums : []).length > 0 &&
                  <Breadcrumb className="rounded-0">
                    {breadcrums.map((breadcrum, key) => {
                      if (key == (Array.isArray(breadcrums) ? breadcrums : []).length - 1) {
                        <Breadcrumb.Item active>{breadcrum.name}</Breadcrumb.Item>
                      }
                      return (
                        <Breadcrumb.Item href="#" onClick={() => {
                            goToFolder(breadcrum)
                          }}
                        >{breadcrum.name}</Breadcrumb.Item>
                      )
                    })}
                  </Breadcrumb>
                }
                <div className="position-relative h-100">
                  {
                    loading &&
                    <Components.LoadingIndicator/>
                  }
                  {
                    !loading &&
                    <>
                      <div className="">
                        <ButtonToolbar>
                          <ButtonGroup className="me-4">
                            <Button variant="outline-default" className={"rounded-0 border-end " + (display == 'grid' ? 'active' : '')} size="sm" onClick={() => {
                                setDisplay('grid')
                            }}><i className="fa fa-th-large"></i></Button>
                          <Button variant="outline-default" className={"rounded-0 border-end " + (display == 'list' ? 'active' : '')} size="sm" onClick={() => {
                                setDisplay('list')
                            }}><i className="fa fa-list"></i></Button>
                          </ButtonGroup>

                          <ButtonGroup className="me-2">
                            <Button variant="outline-default rounded-0 border-end" size="sm" onClick={() => {
                                setShowNewFolderForm(true)
                            }}><i className="fa fa-folder"></i> {Lang.t('New folder')}</Button>
                            <Button variant="outline-default rounded-0" size="sm" onClick={() => {
                                inputUploadRef.current.click()
                              }}><i className="fa fa-upload"></i> {Lang.t('Upload')}</Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                        <Form.Group className="d-none">
                          <Form.Control
                            ref={inputUploadRef}
                            type="file"
                            multiple
                            onChange={event => {
                              uploadFiles(event.target.files)
                              // console.log(event.target.files)
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className="attachments-wrapper">
                        {
                          display == 'list'
                          ?<Table hover>
                            <thead>
                              <tr>
                                <th>{Lang.t('Name')}</th>
                                <th>{Lang.t('Type')}</th>
                                <th>{Lang.t('Size')}</th>
                                <th>{Lang.t('Last modified')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {items.map((item, key) => {
                                return (
                                  <tr
                                    className={( selected.includes(item) ? 'selected' : '' )}
                                    onClick={(e) => {
                                        onSelect(e,item)
                                    }}
                                    onDoubleClick={() => {
                                      goToFolder(item)
                                    }}
                                  >
                                    <td>
                                      <div className="d-flex">
                                        <div className="me-2"><img src={item.thumb_url} width="35"/></div>
                                        <div className="attachment-name w-100">{item.name}</div>
                                      </div>
                                    </td>
                                    <td></td>
                                    <td>{item.size}</td>
                                    <td className="small">{(new Date(item.time * 1000)).toLocaleDateString("en-US")}</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                          :<Row className="p-0 mt-2 g-0">
                            {items.map((item, key) => {
                              return (
                                <Col xs={6} sm={6} md={4} lg={3}>
                                  <div className={"attachment " + ( selected.includes(item) ? 'selected' : '' )}
                                    onClick={(e) => {
                                        onSelect(e,item)
                                    }}
                                    onDoubleClick={() => {
                                      goToFolder(item)
                                    }}
                                  >
                                    <div className="attachment-preview">
                                      <div className="thumbnail attachment-preview--img" style={{backgroundImage: "url('" + item.thumb_url + "')"}}></div>
                                      <div className="attachment-preview--caption text-center">
                                        {item.name}
                                      </div>
                                    </div>
                                    <div className="check"><i className="fa fa-check"></i></div>
                                  </div>
                                </Col>
                              )
                            })}
                          </Row>
                        }
                      </div>
                      <Pagination className="mb-2 justify-content-center">
                        <Pagination.First onClick={() => onClickAtPage(1)}/>
                        <Pagination.Prev onClick={() => onClickAtPage(page - 1)}/>
                        <Pagination.Item onClick={() => onClickAtPage(1)} disabled={page == 1}>{1}</Pagination.Item>
                        {
                          page > 3 &&
                          <Pagination.Ellipsis disabled/>
                        }

                        {[-1,0,1].map((x,key) => {
                          if (page + x >= 2 && page + x <= total_page - 1) {
                            return (
                              <Pagination.Item
                                onClick={() => onClickAtPage(page + x)}
                                disabled={page == page + x}
                              >{page + x}</Pagination.Item>
                            )
                          }
                        })}

                        {
                          page < total_page - 2 &&
                          <Pagination.Ellipsis disabled/>
                        }
                        <Pagination.Item disabled={page == total_page} onClick={() => onClickAtPage(total_page)}>{total_page}</Pagination.Item>
                        <Pagination.Next onClick={() => onClickAtPage(page + 1)} />
                        <Pagination.Last onClick={() => onClickAtPage(total_page)} />
                      </Pagination>
                    </>
                  }
                  <NewFolderForm
                    visible={showNewFolderForm}
                    onClose={() => {setShowNewFolderForm(false)}}
                    onSubmit={async(data) => await createNewFolder(data)}
                  />
                </div>
              </Col>
              <Col xs={12} sm={3} className="h-100 d-none d-sm-block">
                <div className="media-sidebar">
                  {
                    selected &&
                    <div className="w-100 h-100">
                      {
                        (Array.isArray(selected) ? selected : []).length > 1 &&
                        <div className="position-relative w-100 h-100 d-flex justify-content-center align-items-center">
                          <div className="w-100">
                            <div className="d-flex justify-content-center w-100">
                              <div className="position-relative" style={{width: '60%'}}>
                                {selected.map((item, key) => {
                                  let image = item.is_image ? item.url : ( item.is_file ? '/vendor/file-manager/img/file.png' : '/vendor/file-manager/img/folder.png');
                                  return(
                                    <img src={image} className="position-absolute w-100" style={{transform: 'rotate(' + (key * 5) + 'deg)'}}/>
                                  )
                                })}
                              </div>
                            </div>
                            <div className="attachment-preview--caption">
                              <div className="text-center mt-3">{selected.length} items</div>
                            </div>
                          </div>
                        </div>
                      }
                      {
                        (Array.isArray(selected) ? selected : []).length == 1 &&
                        <div className="w-100 h-100 attachment-preview--img" style={{backgroundImage: "url('" + (selected[0].is_image ? selected[0].url : selected[0].thumb_url) + "')"}}>
                          <div className="attachment-preview--caption">
                            <div className="fw-bold">Name: {selected[0].name}</div>
                            <div className="mt-2 mb-2">
                              <a className="text-danger" onClick={() => {
                                  deleteSelectedFile()
                              }}><i className="fa fa-trash-alt"></i> {Lang.t('Delete permanently')}</a>
                            </div>
                            {
                              selected[0].is_image &&
                              <div className="mt-2 mb-2">
                                <a className="text-primary" onClick={() => {
                                    setEditImage(selected[0])
                                }}><i className="fa fa-pen-alt"></i> {Lang.t('Edit image')}</a>
                              </div>
                            }
                            {
                              selected[0].is_file &&
                              <div><a onClick={() => {}}>File URL: {selected[0].url}</a></div>
                            }
                            <div className="small"><i className="fa fa-clock"></i> {(new Date(selected[0].time * 1000)).toLocaleDateString("en-US")}</div>
                          </div>
                        </div>
                      }
                    </div>
                  }
                </div>
              </Col>
            </Row>
          }
        </Modal.Body>
        <Modal.Footer>
          {
            route == 'edit'
            ?<>
              <Button variant="primary" className="me-3" onClick={() => {
                  setEditImage({})
                }}>
                {Lang.t('Back')}
              </Button>
            </>
            :<>
              {
                (Array.isArray(selected) ? selected : []).length == 1 && !selected[0].is_file &&
                <Button variant="primary" className="me-3" onClick={() => {
                    goToFolder(selected[0])
                  }}>
                  {Lang.t('Go to folder')}
                </Button>
              }
              <Button variant="primary" onClick={() => {
                  // console.log(selected)
                  if ('onPick' in props) {
                    if (!props.multiple) {
                      props.onPick(selected[0])
                    }else{
                      props.onPick(selected)
                    }
                  }
                  handleClose()
                }} disabled={!checkAvailablePickingFile}>
                Đặt {props.title ? props.title : Lang.t('Avatar')}
              </Button>
            </>
          }
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const Styles={
  imageFileInputPicker: {
    width: '100%'
  },
  vaueFileInputPicker: {
    fontSize: 10
  }
}

function EditImageScreen(props){
  const [aspectRatioX, setAspectRatioX] = useState('')
  const [aspectRatioY, setAspectRatioY] = useState('')
  const [loading, setLoading] = useState(false)
  const [crop, setCrop] = useState({})
  const url = props.data!=null ? props.data.url : ''

  const cropImage = async() => {
    setLoading(true)
    let response = await APIs.FileManager.cropImage({
      working_dir: props.workingDir,
      type: 'image',
      img: props.data.name,
      dataX: Math.round(crop.x),
      dataY: Math.round(crop.y),
      dataWidth: Math.round(crop.width),
      dataHeight: Math.round(crop.height)
    })
    setLoading(false)
    if (response.errors==null || Object.keys(response.errors).length == 0) {
        props.onSuccess()
    }
  }
  const aspectCrop = aspectRatioX > 0 && aspectRatioY > 0 ? aspectRatioX / aspectRatioY : 0
  // console.log(aspectCrop)
  return (
    <Row className="h-100">
      <Col xs={12} sm={8} className="h-100">
        <div className="d-flex flex-column w-100 h-100">
          <div className="d-flex flex-wrap">
            <Button onClick={async() => {

            }} size="sm" variant="outline-success" disabled={loading} className="me-3">
                <i className="fa fa-crop"></i> {Lang.t('Crop')}
            </Button>
            <Button onClick={async() => {

            }} size="sm" variant="outline-success" disabled={loading} className="me-3">
                {Lang.t('Rotate left')}
            </Button>
            <Button onClick={async() => {

            }} size="sm" variant="outline-success" disabled={loading} className="me-3">
                {Lang.t('Rotate right')}
            </Button>
          </div>
          <ReactCrop crop={crop} aspect={aspectCrop} onChange={c => setCrop(c)} className="mt-2 mb-2" >
            <img src={url} className="h-100"/>
          </ReactCrop>
          <div>
            <Button onClick={async() => {
                cropImage()
            }} size="sm" variant="success" disabled={loading} className="me-3">
                {Lang.t('Save')}
            </Button>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={4} className="h-100 position-relative">
        <div className="text-uppercase fw-bold">{Lang.t('Image Crop')}</div>
        <div>{Lang.t('Aspect ratio')}:</div>
        <div className="d-flex justify-content-start align-items-center mb-3">
          <Form.Control
            size="sm"
            type="number"
            value={aspectRatioX || ''}
            onChange={event => {
              setAspectRatioX(event.target.value)
              if (event.target.value > 0 && aspectRatioY > 0) {
                const aspect = event.target.value > 0 && aspectRatioY > 0 ? aspectRatioY / event.target.value : 0
                setCrop({...crop,...{
                  height: crop.width * aspect
                }})
              }
            }}
            style={{width: 80}}
          />
          <div className="ms-2 me-2">:</div>
          <Form.Control
            size="sm"
            type="number"
            value={aspectRatioY || ''}
            onChange={event => {
              setAspectRatioY(event.target.value)
              if (event.target.value > 0 && aspectRatioX > 0) {
                const aspect = event.target.value > 0 && aspectRatioX > 0 ? event.target.value / aspectRatioX : 0
                setCrop({...crop,...{
                  height: crop.width * aspect
                }})
              }
            }}
            style={{width: 80}}
          />
        </div>
        <div className="d-flex justify-content-start align-items-center mb-3">
          <Form.Control
            size="sm"
            type="number"
            value={Math.round(crop.width || 0)}
            style={{width: 80}}
          />
          <div className="ms-2 me-2">:</div>
          <Form.Control
            size="sm"
            type="number"
            value={Math.round(crop.height || 0)}
            style={{width: 80}}
          />
        </div>
      </Col>
    </Row>
  )
}

function NewFolderForm(props) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  if (!props.visible) {
    return <div></div>
  }
  return (
    <div className="position-absolute bg-white p-3" style={{top: 0,left: 0,right: 0,bottom: 0,zIndex: 1000}}>
      <div className="h3">{Lang.t('Create new folder')}</div>
      <InputGroup className="mb-3">
        <Form.Control
          placeholder={Lang.t("New folder name")}
          onChange={(event) => setName(event.target.value)}
          readOnly={loading}
        />
      <Button onClick={async() => {
            setLoading(true)
            await props.onSubmit({name: name})
            setLoading(false)
        }} variant="success" disabled={loading}>
          <i className="fa fa-plus-circle"></i> {Lang.t('Create')}
        </Button>
        <Button onClick={() => {
            props.onClose()
        }} variant="danger" disabled={loading}>
          <i className="fa fa-times"></i> {Lang.t('Close')}
        </Button>
      </InputGroup>
    </div>
  );
}

export default FileInputPicker;
