import Button from './common/Button';

import GoogleLoginButton from './common/GoogleLoginButton';

import CheckPrivateRoute from './CheckPrivateRoute';
import BackgroundLoading from './common/BackgroundLoading';
import Content from './common/Content';
import MyEditor from './common/Editor';
import FilterBar from './common/FilterBar';
import Footer from './common/Footer';
import Header from './common/Header';
import Languages from './common/Languages';
import LoadingIndicator from './common/LoadingIndicator';
import NoneOfResults from './common/NoneOfResults';
import OverlayLoading from './common/OverlayLoading';
import Pagination from './common/Pagination';
import ScrollToTop from './common/ScrollToTop';
import SideBar from './common/SideBar';
import SortItem from './common/SortItem';

import FileInputPicker from './common/FileInputPicker';
import FileManagerPicker from './common/FileManagerPicker';
import FilesInputPicker from './common/FilesInputPicker';
import FilesManagerPicker from './common/FilesManagerPicker';

import ProductAttributePicker from './catalog/AttributePicker';
import ProductAttributeValuePicker from './catalog/AttributeValuePicker';
import ProductCategoryPicker from './catalog/CategoryPicker';
import MultiProductCategoryPicker from './catalog/MultiCategoryPicker';
import MultiProductPicker from './catalog/MultiProductPicker';
import ProductPicker from './catalog/ProductPicker';
import FloorplanCategoryPicker from './floorplan/CategoryPicker';

import NewsCategoryPicker from './news/CategoryPicker';
import MultiNewsCategoryPicker from './news/MultiCategoryPicker';
import MultiNewsPicker from './news/MultiNewsPicker';
import NewsPicker from './news/NewsPicker';

import FAQsCategoryPicker from './faqs/CategoryPicker';
import FAQsPicker from './faqs/FAQsPicker';
import MultiFAQsCategoryPicker from './faqs/MultiCategoryPicker';
import MultiFAQsPicker from './faqs/MultiFAQsPicker';

import CustomerPicker from './customer/CustomerPicker';
import FengshuiMasterPicker from './fengshuimaster/FengshuiMasterPicker';
import UserPicker from './user/UserPicker';

import CountryPicker from './localisation/CountryPicker';
import GeoZonePicker from './localisation/GeoZonePicker';
import StreetPicker from './localisation/StreetPicker';
import SubZonePicker from './localisation/SubZonePicker';
import WardPicker from './localisation/WardPicker';
import ZonePicker from './localisation/ZonePicker';

import BannerPicker from './layout/BannerPicker';

import Dashboard_Catalog_ProductTotal from './dashboard/catalog/ProductTotal';

import Dashboard_Customer_CustomerMap from './dashboard/customer/CustomerMap';
import Dashboard_Customer_CustomerTotal from './dashboard/customer/CustomerTotal';

import Dashboard_FengshuiMaster_FengshuiMasterMap from './dashboard/fengshuimaster/FengshuiMasterMap';
import Dashboard_FengshuiMaster_FengshuiMasterTotal from './dashboard/fengshuimaster/FengshuiMasterTotal';

import Dashboard_Marketing_NewestSubscriptions from './dashboard/marketing/NewestSubscriptions';

import Dashboard_News_NewsTotal from './dashboard/news/NewsTotal';

import Dashboard_Others_RealtimeServerInfo from './dashboard/others/RealtimeServerInfo';
import Dashboard_Others_ServerInfo from './dashboard/others/ServerInfo';

import Dashboard_Sale_DailySales from './dashboard/sale/DailySales';
import Dashboard_Sale_NewestOrders from './dashboard/sale/NewestOrders';
import Dashboard_Sale_OrderTotal from './dashboard/sale/OrderTotal';
import Dashboard_Sale_Revenue from './dashboard/sale/Revenue';

import Dashboard_Setting_StoreTotal from './dashboard/setting/StoreTotal';

const render = (code, data = {}) => {
  switch (code) {
    case '':
      break;
    default:
      return <></>;
  }
};

const renderDashBoard = (code, data = {}) => {
  switch (code) {
    case 'catalog-ProductTotal':
      return <Dashboard_Catalog_ProductTotal info={data} />;
      break;
    case 'customer-CustomerTotal':
      return <Dashboard_Customer_CustomerTotal info={data} />;
      break;
    case 'customer-CustomerMap':
      return <Dashboard_Customer_CustomerMap info={data} />;
      break;
    case 'fengshuimaster-FengshuiMasterTotal':
      return <Dashboard_FengshuiMaster_FengshuiMasterTotal info={data} />;
      break;
    case 'fengshuimaster-FengshuiMasterMap':
      return <Dashboard_FengshuiMaster_FengshuiMasterMap info={data} />;
      break;
    case 'marketing-NewestSubscriptions':
      return <Dashboard_Marketing_NewestSubscriptions info={data} />;
      break;
    case 'news-NewsTotal':
      return <Dashboard_News_NewsTotal info={data} />;
      break;
    case 'others-RealtimeServerInfo':
      return <Dashboard_Others_RealtimeServerInfo info={data} />;
      break;
    case 'others-ServerInfo':
      return <Dashboard_Others_ServerInfo info={data} />;
      break;
    case 'sale-DailySales':
      return <Dashboard_Sale_DailySales info={data} />;
      break;
    case 'sale-NewestOrders':
      return <Dashboard_Sale_NewestOrders info={data} />;
      break;
    case 'sale-OrderTotal':
      return <Dashboard_Sale_OrderTotal info={data} />;
      break;
    case 'sale-Revenue':
      return <Dashboard_Sale_Revenue info={data} />;
      break;
    case 'setting-StoreTotal':
      return <Dashboard_Setting_StoreTotal info={data} />;
      break;
    default:
      return <></>;
  }
};

export {
  BackgroundLoading,
  BannerPicker,
  Button,
  CheckPrivateRoute,
  Content,
  CountryPicker,
  CustomerPicker,
  MyEditor as Editor,
  FAQsCategoryPicker,
  FAQsPicker,
  FengshuiMasterPicker,
  FileInputPicker,
  FileManagerPicker,
  FilesInputPicker,
  FilesManagerPicker,
  FilterBar,
  FloorplanCategoryPicker,
  Footer,
  GeoZonePicker,
  GoogleLoginButton,
  Header,
  Languages,
  LoadingIndicator,
  MultiFAQsCategoryPicker,
  MultiFAQsPicker,
  MultiNewsCategoryPicker,
  MultiNewsPicker,
  MultiProductCategoryPicker,
  MultiProductPicker,
  NewsCategoryPicker,
  NewsPicker,
  NoneOfResults,
  OverlayLoading,
  Pagination,
  ProductAttributePicker,
  ProductAttributeValuePicker,
  ProductCategoryPicker,
  ProductPicker,
  ScrollToTop,
  SideBar,
  SortItem,
  StreetPicker,
  SubZonePicker,
  UserPicker,
  WardPicker,
  ZonePicker,
  render,
  renderDashBoard,
};
