import React from "react";
// import ReactDOM from 'react-dom'
import { createRoot } from "react-dom/client";
import App from "./App";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";

if (typeof Storage !== "undefined") {
  const root = createRoot(document.getElementById("root"));
  root.render(
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  );
} else {
  alert("Trình duyệt của bạn đã cũ và không được hộ trợ. Mong bạn nâng cấp");
}
