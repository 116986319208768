
import React,{useEffect} from 'react';
import { Image,Form,Dropdown } from 'react-bootstrap';
import { AsyncTypeahead,Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Lang from '@adminv2/system/language'
import APIs from '@adminv2/apis'

import * as Components from '@adminv2/components/index'

function FAQsPicker(props) {
  const [loading, setLoading] = React.useState(false);
  const [faqs, setFAQs] = React.useState(props.faqs || []);
  const errors = props.errors || {}
  const value = props.value || ''

  const getResults = async(key = '') => {
    setLoading(true)
    let response = await APIs.faqs.searchStatisticFAQs(key)
    if (response.data!=null && Array.isArray(response.data.results)) {
      setFAQs(response.data.results)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (faqs.length == 0) {
      getResults()
    }
  },[]);

  return (
    <AsyncTypeahead
      filterBy={() => true}
      id="search-faqs-input-name"
      isLoading={loading}
      labelKey="title"
      minLength={0}
      onSearch={getResults}
      options={faqs}
      placeholder="Search some FAQs"
      renderMenuItemChildren={(option, props) => (
        <div>{option.title}</div>
      )}
      onChange={options => {
        if (options[0]!=null) {
          props.onChange(options[0])
        }
      }}
    />
  );
};

export default FAQsPicker;
