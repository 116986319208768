import globalState from '@adminv2/system/globalState';
import GLOBAL from '@adminv2/app.json';
import axios from 'axios';
const Todos = {
  search: (key = '') => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster/search?filter[name]=' + key;
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getList: data => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster/list';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getFengshuiMasterOfKhoaHocList: data => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster/list';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getForm: (id = 0) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster/form?id=' + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  create: data => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster/add';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  edit: (id, data) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster/' + id + '/update';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  delete: id => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster/' + id + '/delete';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  deletes: selected => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster/deletes';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, {selected: selected})
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getOrders: (id, data = {}) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster/' + id + '/orders';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getReviews: (id, data = {}) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster/' + id + '/reviews';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getFengshuimasterGroupList: data => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster-group/list';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getFengshuiMasterGroupForm: (id = 0) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster-group/form?id=' + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  fengshuimasterGroupCreate: data => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster-group/add';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  fengshuimasterGroupEdit: (id, data) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster-group/' + id + '/update';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  fengshuimasterGroupDelete: id => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster-group/' + id + '/delete';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  fengshuimasterGroupDeletes: selected => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster-group/deletes';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, {selected: selected})
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },

  getFengshuimasterStatusList: data => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster-status/list';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  getFengshuiMasterStatusForm: (id = 0) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster-status/form?id=' + id;
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  fengshuimasterStatusCreate: data => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster-status/add';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  fengshuimasterStatusEdit: (id, data) => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster-status/' + id + '/update';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, data)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  fengshuimasterStatusDelete: id => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster-status/' + id + '/delete';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .get(url)
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
  fengshuimasterStatusDeletes: selected => {
    return new Promise(resolve => {
      let url = GLOBAL.SERVER + '/api/user/fengshuimaster-status/deletes';
      axios.defaults.headers.common = {
        Authorization: `Bearer ${globalState.getBearerToken()}`,
      };
      axios
        .post(url, {selected: selected})
        .then(response => {
          resolve(response);
        })
        .catch(function (error) {
          resolve({});
        });
    });
  },
};
export default Todos;
