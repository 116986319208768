
import React,{useEffect} from 'react';
import { Image,Form } from 'react-bootstrap';

import Lang from '@adminv2/system/language'
import APIs from '@adminv2/apis'

const Todos = APIs.localisation;

function CountryPicker(props) {
  const [loading, setLoading] = React.useState(false);
  const [countries, setCountries] = React.useState(props.countries || []);
  const inputName = props.name || '';
  const errors = props.errors || {}
  const value = props.value || ''
  
  const getCountries = async() => {
    setLoading(true)
    let response = await Todos.getCountries()
    setCountries(response)
    setLoading(false)
  }

  useEffect(() => {
    if (countries.length == 0) {
      getCountries()
    }
  },[]);

  return (
    <Form.Group className={props.className || ""}>
      <Form.Label>{Lang.t('Country')}</Form.Label>
        <Form.Select name={inputName} disabled={loading} value={value} className={ props.selectClassName + ' ' + (errors!=null && errors[inputName]!=null ? 'is-invalid' : '')} onChange={event => props.onChange(event.target.value)}>
          <option value=""> -- {Lang.t('Select Country')} -- </option>
            {(Array.isArray(countries) ? countries : []).map((country, key) => {
              return(
                <option value={country.id}>{country.name}</option>
              )
            })}
        </Form.Select>
        {
          errors[inputName] &&
          <div className="invalid-feedback">
            {errors[inputName]!=null ? errors[inputName][0] : ''}
          </div>
        }
    </Form.Group>
  );
};

export default CountryPicker;
