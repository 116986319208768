import globalState from '@adminv2/system/globalState'
import GLOBAL from "@adminv2/app.json";
import axios from "axios";
const Todos = {
  getCouponList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/coupon/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getCouponForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/coupon/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  couponCreate : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/coupon/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  couponEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/coupon/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  couponDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/coupon/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  couponDeletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/coupon/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getNotificationList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/notification/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getNotificationForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/notification/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  notificationCreate : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/notification/add'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  notificationEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/notification/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  notificationNotify : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/notification/' + id + '/notify'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  notificationDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/notification/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  notificationDeletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/notification/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  notificationCountObjects: (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/notification/count-object'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getSubscribeList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/subscribe/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  subscribeDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/subscribe/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  subscribeDeletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/subscribe/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  // 
  getCustomerCareScheduleList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/customer-care-schedule/list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  getCustomerCareScheduleForm : (id = 0) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/customer-care-schedule/form?id=' + id
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  customerCareScheduleEdit : (id, data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/customer-care-schedule/' + id + '/update'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  customerCareScheduleDelete : (id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/customer-care-schedule/' + id + '/delete'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  customerCareScheduleDeletes : (selected) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/customer-care-schedule/deletes'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{selected: selected}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  // 
  gshopLogin : (googleAccessToken) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/gshop/login'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url, {accessToken: googleAccessToken}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  gshopSettingInfo : () => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/gshop/setting'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  gshopSettingForbidden : () => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/gshop/setting/forbidden'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  gshopSettingPolicy : () => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/gshop/setting/policy'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  gshopGetProductList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/gshop/products'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  gshopGetProductReports : () => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/gshop/products/reports'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  gshopGetProductSync : (product_id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/gshop/products/' + product_id + '/sync'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  gshopGetProductUnSync : (product_id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/gshop/products/' + product_id + '/unsync'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  gshopGetCampaignList : (data) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/gshop/campaigns'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,data).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  gshopGetMerchantList : () => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/gshop/get_merchant_list'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  gshopCreateMerchantAccount : () => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/gshop/create_merchant'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  gshopConnectMerchantAccount : (merchant_id) => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/gshop/connect_merchant'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.post(url,{merchant_id: merchant_id}).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
  gshopDisconnectGoogleAccount : () => {
    return new Promise((resolve) => {
      let url = GLOBAL.SERVER + '/api/user/gshop/disconnect_account'
      axios.defaults.headers.common = {'Authorization': `Bearer ${globalState.getBearerToken()}`}
      axios.get(url).then(response => {
        resolve(response)
      }).catch(function (error) {
        resolve({})
      });
    })
  },
}
export default Todos
