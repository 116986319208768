import React from 'react';

const User = React.lazy(() => import('@adminv2/views/user/user/User'))
const UserEdit = React.lazy(() => import('@adminv2/views/user/user/UserEdit'))
const UserAdd = React.lazy(() => import('@adminv2/views/user/user/UserAdd'))

const Role = React.lazy(() => import('@adminv2/views/user/role/Role'))
const UserRoleEdit = React.lazy(() => import('@adminv2/views/user/role/RoleEdit'))
const UserRoleAdd = React.lazy(() => import('@adminv2/views/user/role/RoleAdd'))

const routes = {
  user : {
      path : '/user',
      name: "User",
      exact : true,
      main : <User />
  },
  user_edit : {
      path : '/user/:id/edit',
      name: "User Edit",
      exact : false,
      main : <UserEdit />
  },
  user_add : {
      path : '/user/add',
      name: "User Add",
      exact : true,
      main : <UserAdd />
  },
  role : {
      path : '/role',
      name: "Role",
      exact : true,
      main : <Role />
  },
  user_role_edit : {
      path : '/role/:id/edit',
      name: "User Role Edit",
      exact : false,
      main : <UserRoleEdit />
  },
  user_role_add : {
      path : '/role/add',
      name: "User Role Add",
      exact : true,
      main : <UserRoleAdd />
  },
}


export default routes;
