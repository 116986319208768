import React from 'react';
import {BrowserRouter} from 'react-router-dom';

import System from '@adminv2/system/system';
import {Content} from '@adminv2/components/index';
import * as Components from '@adminv2/components/index';
import Helper from '@adminv2/helper';
import routes from '@adminv2/routes';
import APIs from '@adminv2/apis';
import globalState from '@adminv2/system/globalState';
import Notify from '@adminv2/system/messages';
import View from '@adminv2/system/view';
import Lang from '@adminv2/system/language';
import Navigation, {navigate} from '@adminv2/system/navigation';
import Event from '@adminv2/system/event';

import './scss/adminv2.scss';

import {AppProvider} from '@adminv2/provider';
import MyErrorBoundary from './system/ErrorBoundary';

function App(props) {
  return (
    <AppProvider>
      <BrowserRouter>
        <MyErrorBoundary>
          <React.Suspense fallback={<Components.BackgroundLoading show={true} />}>
            <Components.ScrollToTop>
              <Content />
            </Components.ScrollToTop>
          </React.Suspense>
        </MyErrorBoundary>
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;

export {APIs, routes, Components, globalState, Notify, View, Lang, Navigation, Event, System, Helper};
