import React from 'react';

const Inventory = React.lazy(() => import('@adminv2/views/stock/Inventory'))

const routes = {
  inventory : {
      path : '/inventory',
      name: "Inventory",
      exact : true,
      main : <Inventory />
  },
}


export default routes;
