import React from 'react';

import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

import {APIs, globalState} from '@adminv2/App';

function MyEditor(props) {
  const value = props.value;
  const uploadUrl = APIs.SERVER + '/api/uploads?directory=' + props.tag;
  return (
    <>
      <CKEditor
        editor={Editor}
        data={value || ''}
        onChange={(event, editor) => {
          const data = editor.getData();
          props.onChange(data);
        }}
        config={{
          simpleUpload: {
            uploadUrl: uploadUrl,
            // withCredentials: true,
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
              "Access-Control-Allow-Headers": "Content-Type, Authorization",
              Authorization: 'Bearer ' + globalState.getBearerToken(),
            },
          },
          // Preview video.
          mediaEmbed: {
            previewsInData: true,
          },
        }}
      />
    </>
  );
}

export default MyEditor;
