
import React,{useEffect} from 'react';
import { Image,Form } from 'react-bootstrap';

import Lang from '@adminv2/system/language'
import APIs from '@adminv2/apis'

const Todos = APIs.localisation;

function WardPicker(props) {
  const [loading, setLoading] = React.useState(false);
  const [wards, setWards] = React.useState(props.wards || []);
  const inputName = props.name || '';
  const subZoneId = props.subZoneId || ''
  const errors = props.errors || {}
  const value = props.value || ''

  const getWards = async() => {
    setLoading(true)
    let response = await Todos.getWards(subZoneId)
    setWards(response)
    setLoading(false)
  }

  useEffect(() => {
    if (props.subZoneId) {
      getWards()
    }else{
      setWards([])
    }
  },[props.subZoneId]);

  return (
    <Form.Group className={props.className || ""}>
      <Form.Label>{Lang.t('Ward')}</Form.Label>
        <Form.Select name={inputName} value={value} disabled={!subZoneId || loading} className={ props.selectClassName + ' ' + (errors!=null && errors[inputName]!=null ? 'is-invalid' : '')} onChange={event => props.onChange(event.target.value)}>
          <option value=""> -- {Lang.t('Select Ward')} -- </option>
            {(Array.isArray(wards) ? wards : []).map((ward, key) => {
              return(
                <option value={ward.id}>{ward.name}</option>
              )
            })}
        </Form.Select>
      {
        errors[inputName] &&
        <div className="invalid-feedback">
          {errors[inputName]!=null ? errors[inputName][0] : ''}
        </div>
      }
    </Form.Group>
  );
};

export default WardPicker;
