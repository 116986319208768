
import React,{useEffect} from 'react';
import { Image,Form,Dropdown } from 'react-bootstrap';
import { AsyncTypeahead,Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Lang from '@adminv2/system/language'
import APIs from '@adminv2/apis'

import * as Components from '@adminv2/components/index'

function AttributePicker(props) {
  const [loading, setLoading] = React.useState(false);
  const [attributes, setProductAttributes] = React.useState(props.attributes || []);
  const errors = props.errors || {}
  const value = props.value || ''
  const displayValue = props.displayValue || ''

  const getProductAttributes = async(key = '') => {
    setLoading(true)
    let response = await APIs.catalog.searchStatisticProductAttribute(key)
    if (response.data!=null && Array.isArray(response.data.results)) {
      setProductAttributes(response.data.results)
    }
    setLoading(false)
  }

  useEffect(() => {
    // if (attributes.length == 0) {
    //   getProductAttributes()
    // }
  },[]);



  return (
    <AsyncTypeahead
      filterBy={() => true}
      id="search-attribute-input-name"
      isLoading={loading}
      labelKey={option => `${option.name}`}
      minLength={0}
      onFocus={() => getProductAttributes(displayValue)}
      onSearch={getProductAttributes}
      options={attributes}
      defaultSelected={[{
        name: displayValue
      }]}
      useCache={false}
      renderMenuItemChildren={(option, props) => (
        <div>
          <div className="d-flex justify-content-between">
            <div className="fw-bold">{option.name}</div>
            <div>
              {
                option.attribute_group &&
                <span className="small"><i className="fa fa-object-group"></i> {option.attribute_group}</span>
              }
            </div>
          </div>
          <div className="small">{option.category}</div>
        </div>
      )}
      onChange={options => {
        if (options[0]!=null) {
          props.onChange(options[0])
        }
      }}
    />
  );
};

const Styles = {

}

export default AttributePicker;
